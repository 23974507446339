import React, { useCallback } from 'react';
import ObjectEditorField from './ObjectEditorField';

const PATTERN = { type: "object" };


function ObjectField({ id: idx, fieldProps: prop, value: objectValue, onChange: objectOnChange, scope, object }) {

    const onChange = useCallback((prop, value) => {
        const newValue = {
            ...objectValue,
            [prop.property]: value
        };
        objectOnChange(newValue);
    }, [prop, objectOnChange, objectValue]);

    return (<>
        {Object.values(prop.properties).map((item, idx) => (
            <ObjectEditorField key={idx} id={idx}
                fieldProps={item}
                scope={scope}
                object={object}
                value={objectValue ? objectValue[item.property] : null}
                onChange={value => onChange(item, value)}
            />
        ))}
    </>)
}

ObjectField.pattern = PATTERN;

export default ObjectField;