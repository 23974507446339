import React from "react";
import FormApi from "../../../api/FormApi";
import ObjectWorkflowEditor from "../../../components/_workflowEditor/ObjectWorkflowEditor";
import useResourceLoader from "../../../util/useResourceLoader";


function WorkflowObjectSidepanel({
    dispatchAction,
    objectOnChange,
    selectedObject,
    setPathForSelectedObject,
    state,
}){

    const [forms, loadingForms, errorLoadingForms] = useResourceLoader(
        () => FormApi.getForms({ ignoreCache: true }),
    [], []);

    return selectedObject ? (
        <ObjectWorkflowEditor
            onChange={objectOnChange}
            state={state}
            forms={forms}
            setPath={setPathForSelectedObject}
            object={selectedObject}
            onDelete={dispatchAction.removeStep}
            onDuplicate={dispatchAction.duplicateStep}
        />
    ) : (
        <div className="container text-center" style={{ paddingTop: '10px' }}>
            select the object to work
        </div>
    );
}


export default WorkflowObjectSidepanel;