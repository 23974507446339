import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import {
  Table,
  Button,
  Row,
  Col,
  Badge,
  Card,
  CardHeader,
  CardFooter,
  NavbarText,
  NavLink
} from 'reactstrap';
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import InternalLayout from '../../components/layouts/InternalLayout';

import useResourceLoader from '../../util/useResourceLoader';
import RequisitionsApi from '../../api/RequisitionsApi';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';
import useTitle from '../../util/useTitle';
import { DATE_FORMAT, DD_SENT_BACK_MAP, TIME_FORMAT, SLACOLOR } from '../../constants';
import Pagination from '../../components/Pagination';
import useSearchParams from '../../util/useSearchParams';
import { toDrDateTime, getBusinessDatesCount } from "../../util/dates";
import RequisitionSearchBar from "../../components/Search/RequisitionSearchBar";
import SortButton from "../../components/SortButton";
import { AuthContext } from '../../context/AuthContext';
import LookupApi from '../../api/LookupApi';
import AppContext from '../../context/AppContext';
import CatalogRequisitionDropdown from '../../blc/CatalogRequisitionDropdown';
import useAccessCheck from '../../util/useAccessCheck';
import SelectWorkflowBeforeCreate from '../../blc/SelectWorkflowBeforeCreate';
import WorkflowApi from '../../api/WorkflowApi';

export function RequisitionDraftInbox({ history }) {
  useTitle("Borradores");

  const auth = useContext(AuthContext);

  const { entityType: slug } = useParams();

  const [entityType, loadingEntityType, errorLoadingEntityType] = useResourceLoader(async () => {
    const workflowInfo = await WorkflowApi.getWorkflowInfoForSlug({ slug });
    if (workflowInfo) {
      return workflowInfo.objectSchemaName;
    }
  }, [slug]);

  const [slaSetting] = useResourceLoader(() => LookupApi.getSLASetting(), []);

  const defaultView = useMemo(() => { }, []);

  const [
    [offset, setOffset],
    [size, setSize],
    [sortBy, setSortBy],
    [openFilter, setOpenFilter],
    [searchParams, setSearchParams]
  ] = useSearchParams({
    offset: 0,
    size: 10,
    sortBy: '-date',
    openFilter: false
  });

  const filters = useMemo(() => ({
    ...searchParams
  }), [searchParams]);

  const setFilters = useCallback((filters) => {
    setSearchParams({ ...searchParams, ...filters });
  }, [searchParams, setSearchParams]);

  useEffect(() => setFilters({ ...(filters || {}), ...(defaultView || {}) }), [defaultView]);

  const [paging, loadingRequisitions, errorLoadingRequisitions] = useResourceLoader(async () => {
    const requisitions = await RequisitionsApi.getRequisitionDrafts({ offset, size, sortBy, ...filters });
    return Array.isArray(requisitions) ? {
      from: 0,
      to: requisitions.length,
      count: requisitions.length,
      items: requisitions
    } : requisitions;
  }, [size, offset, sortBy]);

  const {
    from = 0,
    to = 0,
    count = 0,
    items: requisitions = [],
  } = paging || {};

  const showFilter = useMemo(() => (openFilter === "true"), [openFilter]);

  const toggleFilter = (e) => {
    e.preventDefault();
    setOpenFilter(!showFilter);
  }

  const onSearch = (update) => {
    setFilters({ ...filters, ...update });
  }

  const onClearSearch = useCallback(() => setSearchParams({ offset, size, sortBy, ...defaultView }), [setSearchParams, offset, size, sortBy, defaultView]);

  const showOptionsFilters = {
    number: true,
    visibility: true,
    adquisitionMethods: true,
    after: true,
    before: true,
    stage: false
  };

  const unitOrgWorkflows = useMemo(() => auth?.user?.user?.organizationalUnit?.workflows, [auth]);

  return (<InternalLayout>
    <AppContext.SetSidebarTab value="drafts" />
    <AppContext.SetNavbarContent value={<>
      <NewRequisitionNav slug={slug} history={history} auth={auth} unitOrgWorkflows={unitOrgWorkflows} />
    </>} />
    <Card className={`inbox-card primary ${showFilter ? '' : 'no-bottom-gap'}`}>
      <CardHeader>
        <Row>
          <Col xs="10">
            <div className="title">Borradores</div>
          </Col>
          <Col sm="2"><div className="float-right">
            <Button onClick={toggleFilter} type="button" outline color="secondary" active={showFilter}>
              <i className="glyphicon glyphicon-filter" />
            </Button>
          </div></Col>
        </Row>
      </CardHeader>
      {showFilter ? (<RequisitionSearchBar
        filters={filters}
        onSearch={onSearch}
        statusState="draft"
        showOptionsFilters={showOptionsFilters}
        onClearSearch={onClearSearch}
      />) : null}
    </Card>
    <Card className={`inbox-card ${showFilter ? '' : 'square-top'}`}>
      <Table>
        <thead><tr>
          <SortButton tag="th" sortKey="date" sortBy={sortBy} setSortBy={setSortBy}>Fecha</SortButton>
          <SortButton tag="th" sortKey="id" sortBy={sortBy} setSortBy={setSortBy}>Número de Requisición</SortButton>
          <SortButton tag="th" sortKey="title" sortBy={sortBy} setSortBy={setSortBy}>Título</SortButton>
          <SortButton tag="th" sortKey="owner" sortBy={sortBy} setSortBy={setSortBy}>Peticionario</SortButton>
          <SortButton tag="th" sortKey="agency" sortBy={sortBy} setSortBy={setSortBy}>Agencia</SortButton>
          <SortButton tag="th" sortKey="method" sortBy={sortBy} setSortBy={setSortBy}>Método de Adquisición</SortButton>
          <SortButton tag="th" sortKey="timeLapse" sortBy={sortBy} setSortBy={setSortBy}>Días Abierto</SortButton>
          <th colSpan="2" className="text-center">Acciones</th>
        </tr></thead>
        <tbody>
          {loadingRequisitions ? <tr><td colSpan="8"><Loader>Cargando Requisiciones</Loader></td></tr> : (<>
            {errorLoadingRequisitions ? <tr><td colSpan="8"><Notification color="danger">{JSON.stringify(errorLoadingRequisitions)}</Notification></td></tr> : null}
            {(requisitions && requisitions.length) ? requisitions.map((draftObj, idx) => (
              <RequisitionRow key={idx} draftObj={draftObj} slaSetting={slaSetting} />
            )) : (
              <tr><td colSpan="10"><Notification color="warning">
                La lista de requisiciones esta vacia.
              </Notification></td></tr>
            )}
          </>)}
        </tbody>
      </Table>
      <CardFooter>
        <div className="float-right">
          <Pagination offset={from} count={count} size={size} setSize={setSize} setOffset={setOffset} />
        </div>
      </CardFooter>
    </Card>
  </InternalLayout>);
}


function RequisitionRow({
  slaSetting,
  draftObj: {
    id,
    lastUpdated,
    status,
    metadata = {},
    ddSendBack,
    organizationalUnit,
    owner,
    completed
  }
}) {
  const { agency, purchaseMethod } = metadata || {};
  const history = useHistory();
  const canSeeDetails = true;
  const sentBackTxt = (DD_SENT_BACK_MAP[ddSendBack] || {}).title;
  const currTime = new Date();
  const createdTime = new Date(toDrDateTime(lastUpdated).format("YYYY/MM/DD h:mm A"));
  const lapsedTime = getBusinessDatesCount(createdTime, currTime)
  return (<tr>
    <td>
      <div>{toDrDateTime(lastUpdated).format(TIME_FORMAT)}</div>
      <div>{toDrDateTime(lastUpdated).format(DATE_FORMAT)}</div>
    </td>
    <td>---</td>
    <td>---</td>
    <td>{owner?.name}</td>
    <td>{organizationalUnit.name || '---'}</td>
    <td>{purchaseMethod || '---'}</td>
    <td style={{ textAlign: "center" }}>
      {completed ?
        <Badge color="default" style={{ fontSize: "100%" }} pill>
          <i className="fa fa-check" />
        </Badge>
        :
        <Badge color={slaSetting ? SLACOLOR(lapsedTime, slaSetting) : undefined} style={{ fontSize: "100%" }} pill>
          {lapsedTime}
        </Badge>
      }
    </td>
    <td>
      <Button color="primary" data-cy={`work-requisition-${id}`} disabled={!canSeeDetails || completed} onClick={() => history.push(`/workflow/requisitions/new?draft=${id}`)}>Trabajar</Button>
    </td>
  </tr>);
}


function NewRequisitionNav(props) {
  const hasAccess = useAccessCheck("Peticionario", "canWorkAs");
  const hasTicketAccess = useAccessCheck("Ticket Issuer", "canWorkAs");
  const { history } = props;
  return (
    <>
      {hasAccess ?
        <>
          <NavbarText className="text-title">Nueva Requisición: </NavbarText>
          <NavbarText>
            <CatalogRequisitionDropdown SelectWorkflowBeforeCreate={SelectWorkflowBeforeCreate} unitOrgWorkflows={props.unitOrgWorkflows} />
          </NavbarText>
          <NavbarText>
            <SelectWorkflowBeforeCreate slug={props.slug} customButton={(onClick) => (
              <Button color="primary" className="btn btn-primary" data-cy="button-create-requisition-regular" onClick={onClick} >Requisición Regular</Button>
            )} />
          </NavbarText>
        </> : null}
      {
        hasTicketAccess ?
          <NavbarText className="ml-auto" style={{ paddingRight: 20 }}>
            <Button color="primary" className="btn btn-primary" data-cy="button-create-ticket" onClick={() => history.push(`/workflow/tickets/new`)} >Crear Ticket</Button>
          </NavbarText> : null
      }
    </>
  )
}


export default RequisitionDraftInbox;
