import React from 'react';
import {
    FormGroup,
    Input,
    Label,
} from "reactstrap";


const PATTERN = {type: "boolean" };


function BooleanField({id: idx, fieldProps: prop, value, onChange}) {
    return (<FormGroup check>
        <Input
            id={`${idx}`}
            type="checkbox"
            name={prop.property}
            checked={value || false}
            onChange={({target: {checked}}) => onChange(!!checked)}
        />
        <Label check for={`${idx}`} >{prop.label} {prop.toChild}</Label>
    </FormGroup>)
}


BooleanField.pattern = PATTERN;


export default BooleanField;