import React, { useEffect, useContext } from 'react';
import { Redirect, useParams } from 'react-router';
import { AuthContext } from '../../../context/AuthContext';
import OrganizationalUnitApi from "../../../api/OrganizationalUnitApi";
import FormComponent from '../../../components/FormComponent';
import InternalLayout from '../../../components/layouts/InternalLayout';
import { FORM_UPDATE_SUBMIT_BUTTON } from '../../../constants';


const moduleExcludes = ["Modulo 5 Padawan", "Presupuesto Antes OE", "DSP"];
export const EditOrganizationalUnitForm = FormComponent({
    beforeRenderHook({ setFormDefinition, initialFormObject }) {
        const auth = useContext(AuthContext);

        useEffect(() => {

            if (initialFormObject && initialFormObject.workflow) {
                setFormDefinition(
                    {
                        title: "Agencia",
                        type: "object",
                        properties: {
                            name: { title: "Nombre", type: "string" },
                            type: {
                                "type": "string",
                                "title": "Tipo",
                                "enum": ["Agency", "Bureau", "WorkDivision"],
                                "enumNames": ["Agencia", "Entidad Peticionaria", "División de Trabajo"]
                            },
                            code: { title: "Código de Negociado", type: "string" },
                            address1: { title: "Dirección Línea 1", type: "string" },
                            address2: { title: "Dirección Línea 2", type: ["string", "null"] },
                            postalCode: { title: "Código Postal", type: "string" },
                            parentId: {
                                "title": "Unidad Organizacional Padre",
                                "type": "number",
                                "lookup": {
                                    "resource": "Unidades Organizacionales",
                                    "api": "organizationalunits",
                                    "params": {
                                        "workable": 1
                                    },
                                    "options": {
                                        "useCache": true
                                    },
                                    "parentId": "parentId",
                                    "collapsable": true,
                                    "collapseLevel": 2,
                                    "id": "id",
                                    "label": "name"
                                }
                            },
                            city: { title: "Ciudad", type: "string" },
                            state: { title: "Estado", type: "string" },
                            numericCode: { title: "Código de Agencia", type: "number" },
                            dependencyFigure: { title: "Cifra de Dependencia", type: "number" },
                            isWarehouse: {
                                "type": "boolean",
                                "title": "¿Es un almacén?"
                            },
                            worksWithEmergencies: {
                                "type": "boolean",
                                "title": "¿Trabaja con Emergencias?"
                            },
                            logoId: {
                                "type": ["number", "null"],
                                "title": "Logo",
                                "ui:ratio": 4.42 / 1,
                                "ui:size": [750.4, 170]
                            },
                            previewCertificationFounds: {
                                "type": "string",
                                ":classNames": "h-margin",
                                "title": "Vista previa de Certificación de Fondos"
                            },
                            peopleSoftId: { title: "Business Unit (PeopleSoft)", type: "string" },
                            workflows: {
                                title: "Requisition Workflows",
                                type: "array",
                                items: {
                                    type: "object",
                                    properties: {
                                        workflowId: {
                                            title: "Workflow",
                                            type: "number",
                                            lookup: {
                                                api: 'workflows',
                                                resource: "Workflows",
                                                id: 'id',
                                                jnx: { "expr": "data[slug[$contains('requisitions')]]", "scope": ".." },
                                                label: 'name'
                                            }
                                        }
                                    }
                                }
                            },
                            federalFundsProgram: {
                                title: "Programas de Fondos Federales",
                                type: "array",
                                items: {
                                    type: "object",
                                    properties: {
                                        name: {
                                            title: "Programa",
                                            type: "string"
                                        }
                                    }
                                }
                            },
                            workflowParams: {
                                title: "Manejo de Módulos",
                                properties: (
                                    auth?.user?.user?.accessProfile?.permissions?.includes('ConfigureOrganizationalUnits') ?
                                        (() => {
                                            const filteredObj = {};
                                            Object.entries(initialFormObject.workflow.paramSchema).forEach(([key, value]) => {
                                                if (!moduleExcludes.includes(value.title)) {
                                                    filteredObj[key] = value;
                                                }
                                            });
                                            return filteredObj;
                                        })() :
                                        initialFormObject.workflow.paramSchema
                                )
                            },
                        },
                    },
                )
            }
        }, [initialFormObject]);
    },
    displayName: 'EditOrganizationalUnit',
    withoutNavigation: true,
    schema: {},
    uiSchema: {
        "ui:field": "HideObjectField",
        "classNames": "smallTwoRows",
        "workflowParams": { "classNames": "from-col-1 to-col-3" },
        "parentId": {
            ":classNames": "grid-3-columns",
            "ui:field": "LookupFormField"
        },
        "isWarehouse": {
            "ui:field": "BoolYesNo"
        },
        "worksWithEmergencies": {
            "ui:field": "BoolYesNo"
        },
        "logoId": {
            "ui:field": "ImageCrop"
        },
        "previewCertificationFounds": {
            "ui:field": "Button",
            "ui:showIf": {
                "expr": "$isTruthy(logoId)"
            },
            "ui:onClick": {
                "expr": [
                    "(",
                    "$logoId:= logoId;",
                    "$openModal('PdfPreviewModal',true, 'GenerateCertificacionDeFondos', logoId)",
                    ")"
                ]
            },
        },
        "peopleSoftId": {
            "classNames": "from-col-1 to-col-3",
            "ui:showIf": { expr: 'workflowParams.isPeopleSoft and $formContext.auth.user.user.accessProfile.name = "systemadmin" or $formContext.auth.user.user.accessProfile.name = "agencymanager"', "scope": ".." },
        },
        "federalFundsProgram": {
            "classNames": "from-col-1 to-col-3",
            "ui:showIf": "type = 'Agency'",
            items: {
                "ui:addable": true,
                "ui:orderable": false,
                "ui:removable": true,
            }
        },
        "workflows": {
            "classNames": "from-col-1 to-col-3",
            "ui:showIf": { expr: '$formContext.auth.user.user.accessProfile.name = "systemadmin"', "scope": ".." },
            items: {
                "ui:orderable": false,
                "workflowId": {
                    "ui:field": "LookupFormField"
                }
            }
        }
    },
    validate({ name }, errors) {
        return errors;
    },
    parseProps: () => ({
        params: useParams()
    }),
    loadData: ({ params: { id } }) => OrganizationalUnitApi.getOrganizationUnit({ id }),
    async onSubmit({ formData }, { props: { params: { id } } }) {
        await OrganizationalUnitApi.editgetOrganizationUnit({ id, ...formData });
    },
    submitButtons: FORM_UPDATE_SUBMIT_BUTTON,
    renderFormSubmitted: () => (
        <Redirect to="../system-settings/organizationalunits" />
    )
});

export default function () {
    return <InternalLayout><EditOrganizationalUnitForm /></InternalLayout>;
};