import React, { useMemo, useState } from "react";
import {
    FormGroup,
    Button
} from "reactstrap";
import { t, Trans } from "@lingui/macro"
import { useJnx } from '../../util/jnx';
import { useSideChannelSubscription } from '../../util/useSideChannel';
import useResourceLoader from '../../util/useResourceLoader';
import Notification from "../Notification";
import Loader from "../Loader";
import FileApi from "../../api/FileApi";
import StaticFileApi from "../../api/StaticFileApi";
import FileInput from "../FileInput";
import getPathFromId from "../../util/getPathFromId";


function FileField(props) {
    const {
        formData,
        formContext,
        name,
        disabled,
        readonly: propReadonly,
        schema: {
            title,
            buttonText,
            emptyText,
            fileUrlField = "url",
            fileNameField = "name",
            resource = t`File`,
            accept = "*",
            type,  // has to be number (the file id)
            "const": constValue,
            staticFile,
            pdfRef: pdfRefExpr
        },
        idSchema: { '$id': fieldId },
        uiSchema: {
            'ui:titleAsLink': titleAsLink,
            'ui:hideOnEmpty': hideOnEmpty,
            'ui:readonly': uiReadonly,
            'ui:hideAttr': uiHideAttr,
            'ui:viewable': uiViewable = true
        },
        // title,
        required,
        onChange: propOnChange
    } = props;

    const {
        sideChannel
    } = formContext;
    

    const path = useMemo(() => getPathFromId(fieldId), [fieldId]);
    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}];

    const pdfRefJnx = useJnx(pdfRefExpr);
    const pdfRef = useMemo(() => (
        pdfRefJnx && pdfRefJnx.eval(rootFormData || {}, '', {
            root: rootFormData,
            formObject,
            formContext,
        })
    ), [formData, rootFormData, formContext]);

    const labelId = `${fieldId}-label`;
    const postArgs = ((formContext || {}).fileField || {}).postArgs;

    const useFileId = type === "number" || (Array.isArray(type) && type.includes("number"));
    const useStaticFile = !!staticFile;
    const value = formData || constValue || staticFile || pdfRef;

    const readonly = uiReadonly || !!constValue || useStaticFile || propReadonly;

    const [file, loadingFile, errorLoadingFile] = useResourceLoader(() => {
        if (useFileId) return value ? FileApi.getFile({ fileId: value }) : {};
        if (useStaticFile) return StaticFileApi.getFile({ key: staticFile });
        if (!!value && typeof value === "object") {
            if (value.url && value.name) return value;
            return { url: value[fileUrlField], name: value[fileNameField] };
        } else {
            return { url: value, name: (value || '').split('/').pop().split('?')[0] };
        }
    }, [useFileId, value]);

    const [uploadingFile, setUploadingFile] = useState();
    const [errorUploadingFile, setErrorUploadingFile] = useState();
    const error = (
        errorLoadingFile ||
        errorUploadingFile ||
        ((!loadingFile && (
            !file || (readonly && !file.url)
        )) ? { message: emptyText || t`No ${resource} to show`, alert: 'secondary' } : null)
    );

    async function onChange(file) {
        if (file) {
            const extension = file.name.indexOf('.') >= 0 ? `.${file.name.split('.').pop()}` : '';

            try {
                setUploadingFile(true);
                const result = await FileApi.uploadFile({ ...(postArgs || {}), file, filename: `${name}${extension}` });
                propOnChange(useFileId ? result.id : result.url);
            } catch (e) {
                setErrorUploadingFile(e);
            }

            setUploadingFile(false);
        }
    }

    async function onDelete() {
        propOnChange(null);
    }

    if (hideOnEmpty && readonly && !value) {
        return null;
    }

    if (uiHideAttr && readonly) {
        return null;
    }

    return (
        <FormGroup className="file-field" disabled={readonly}>
            {titleAsLink && title && readonly && file && file.url ? (
                <Button tag="a" href={file.url} target="_blank">{title}</Button>
            ) : (<>
                {(title && title !== " ") ? (<label className="control-label" id={fieldId}  htmlFor={labelId}>
                    {title}{required ? <span className="required">*</span> : null}
                </label>) : null}
                {uploadingFile ? (<div>
                    <Loader><Trans>Uploading {resource}</Trans></Loader>
                </div>) : (loadingFile ? (<div>
                    <Loader><Trans>Loading {resource}</Trans></Loader>
                </div>) : (<>
                    {error ? (<Notification error={error} />) : null}
                    <FileInput
                        file={file}
                        viewable={uiViewable}
                        disabled={disabled}
                        viewButtonText={buttonText}
                        onFileSelected={onChange}
                        onDelete={onDelete}
                        resource={resource}
                        accept={accept}
                        readonly={readonly}
                        required={required}
                    />
                </>))}
            </>)}
        </FormGroup>
    );
}


export default FileField;