import { Trans } from '@lingui/react';
import _ from "lodash";
import React, { useContext, useState } from 'react';
import {
    Table,
    Button,
    Col,
    Row,
} from 'reactstrap';

import { useHistory } from 'react-router';
import { AuthContext } from '../../context/AuthContext';
import useResourceLoader from '../../util/useResourceLoader';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';
import useTitle from '../../util/useTitle';
import UserApi from "../../api/UserApi";
import SortButton from "../../components/SortButton";
import useSearchParams from '../../util/useSearchParams';
import Pagination from '../../components/Pagination';
import SearchBar from '../../components/Search/SearchBar';
import { EditUserForm } from './EditUser';

const searchFormDefinition = (({ schema, uiSchema }) => ({
    schema: {
        type: "object",
        properties: {
            firstName: { title: "Nombre", type: "string" },
            lastName: { title: "Apellido", type: "string" },
            email: { title: "Email", type: "string" },
            profile: {
                title: "Tipo de Usuario",
                type: "number",
                lookup: { api: 'profiles', resource: "Tipos de Usuario", id: 'id', label: 'displayName' }
            },
            orgUnit: {
                title: "Unidad Organizacional",
                type: "number",
                "lookup": {
                    "resource": "Unidades Organizacionales",
                    "api": "organizationalunits?workable=1",
                    "params": {
                        "all": true
                    },
                    "parentId": "parentId",
                    "collapsable": true,
                    "collapseLevel": 2,
                    "id": "id",
                    "label": "name",
                    "sortBy": "name"
                }
            },
            hasCompletedProfile: {
                title: "Registrado",
                type: "number",
                enum: [1, 0],
                enumNames: ["Si", "No"]
            },
            active: {
                title: "Activo",
                type: "number",
                enum: [1, 0],
                enumNames: ["Si", "No"]
            },
            role: {
                title: "Rol",
                type: "number",
                lookup: { api: 'agencyRoles', resource: "Roles", id: 'id', label: 'name' }
            },
            roleCan: {
                title: "Rol Puede",
                type: "number",
                enum: [1, 2, 3],
                enumNames: ["Asignar", "Ser Asignado", "Trabajar"]
            }
        },
    },
    uiSchema: {
        "classNames": "smallThreeRows",
        "branchId": { "ui:field": "LookupFormField" },
        "orgUnit": { "ui:field": "LookupFormField" },
        "profile": { "ui:field": "LookupFormField" },
        "role": { "ui:field": "LookupFormField" },
    },
}))(EditUserForm.formDefinition);


export function UserTable() {
    const history = useHistory()
    useTitle("Usuarios");
    const auth = useContext(AuthContext);
    const { jediId } = auth?.user?.user || {};
    const { ManageUsersInUnit, ConfigureUsersInUnit } = auth?.user?.permissions || {};

    const [showFilter, setShowFilter] = useState();

    const [
        [offset, setOffset],
        [size, setSize],
        [sortBy, setSortBy],
    ] = useSearchParams({
        offset: 0,
        size: 10,
        sortBy: 'id'
    });

    const [filters, setFilters] = useState({});

    const [paging, loadingUsers, error] = useResourceLoader(async () => {
        const users = await UserApi.searchUsers({ offset, size, sortBy, ...filters });
        return Array.isArray(users) ? {
            from: 0,
            to: users.length,
            count: users.length,
            items: users
        } : users;
    }, [size, offset, sortBy, filters]);

    const {
        from = 0,
        to = 0,
        count = 0,
        items: users = [],
    } = paging || {};

    function onSearch(update) {
        setFilters({ ...filters, ...update });
        setShowFilter();
    }

    function onClearSearch() { setFilters({}); }


    function select(user) {
        if (user) history.push(`/users/${user.id}`);
    }

    if (jediId && !ConfigureUsersInUnit) {
        return null;
    }

    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]);
        const csvContent = [
            headers.join(','),
            ...data.map((row) => headers.map((header) => JSON.stringify(row[header])).join(','))
        ];
        return csvContent.join('\n');
    };

    const generateAndDownloadReport = () => {
        UserApi.generateReport()
            .then(response => {
                const csv = convertToCSV(response);
                if (csv) {
                    const csvData = `\uFEFF${csv}}`;
                    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'UsersReport.csv';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
            })
            .catch(error => {
                console.error('Error al generar el reporte:', error);
            });
    };


    return (<>
        {loadingUsers ? <Loader fullscreen /> : null}
        {error ? <Notification color="danger">{JSON.stringify(error)}</Notification> : null}
        <div>
            <Row>
                <Col xs="11" sm="6" className="order-sm-2">
                    {ManageUsersInUnit ? (
                        <>
                            <Button color="primary" onClick={() => history.push("/users/create")} >Registrar Usuario</Button>
                            <Button color="primary" style={{ marginLeft: 2 }} onClick={generateAndDownloadReport} >Generar Reporte</Button>
                        </>
                    ) : null}
                </Col>
                <Col xs="1" className="order-sm-3">
                    <Button onClick={() => setShowFilter(!showFilter)} type="button" color="secondary" active={showFilter}>
                        <i className="glyphicon glyphicon-filter" />
                    </Button>
                </Col>
                <Col sm="5" className="order-sm-1">
                    <Pagination offset={from} count={count} size={size} setSize={setSize} setOffset={setOffset} />
                </Col>
            </Row>
            {showFilter && <SearchBar resource="Usuarios" formDefinition={searchFormDefinition} filters={filters} onSearch={onSearch} onClearSearch={onClearSearch} />}
            <Table>
                <thead><tr>
                    <SortButton tag="th" sortKey="active" sortBy={sortBy} setSortBy={setSortBy}>Id</SortButton>
                    <SortButton tag="th" sortKey="name" sortBy={sortBy} setSortBy={setSortBy}>Nombre</SortButton>
                    <SortButton tag="th" sortKey="organization" sortBy={sortBy} setSortBy={setSortBy}>Organización</SortButton>
                    <SortButton tag="th" sortKey="position" sortBy={sortBy} setSortBy={setSortBy}>Puesto</SortButton>
                    <SortButton tag="th" sortKey="hasCompletedProfile" sortBy={sortBy} setSortBy={setSortBy}>Registrado</SortButton>
                    <SortButton tag="th" sortKey="active" sortBy={sortBy} setSortBy={setSortBy}>Activo</SortButton>
                    <td />
                </tr></thead>
                <tbody>{
                    (users || []).map((user, idx) => (
                        <UserEntry key={idx} user={user} onSelect={select} editable={ManageUsersInUnit || ConfigureUsersInUnit} />
                    ))
                }</tbody>
            </Table>
        </div>
    </>);
}


function UserEntry({ user, onSelect, editable }) {
    const { id, firstName, lastName, email, position, jediId, isDual, isActive, organizationalUnit, hasCompletedProfile } = user;
    return (<tr className="clickable">
        <td>
            {id}
            {jediId ? <><br />
                jedi: {jediId}
                {isDual ? '(dual)' : ''}
            </> : null}
        </td>
        <td>{firstName} {lastName}<div className="small">{email}</div></td>
        <td>{organizationalUnit.name}</td>
        <td>{position}</td>
        <td>{hasCompletedProfile ? 'Sí' : 'No'}</td>
        <td>{isActive ? 'Sí' : 'No'}</td>
        <td>{editable ? (
            <Button color="primary" onClick={() => onSelect(user)}>Editar</Button>
        ) : null}</td>
    </tr>)
}


export default UserTable;