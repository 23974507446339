import React, {useMemo} from "react";
import {
    FormGroup,
    Table
} from "reactstrap";
import { mapObject } from "../../util/mapObject";
import { useSideChannelSubscription } from "../../util/useSideChannel";

function CalcField(props) {
    const {
        formContext: {
            sideChannel,
            // formData
        },
        schema: { title },
    } = props;

    const formData = useSideChannelSubscription(sideChannel) || {};

    const mapped = mapObject(formData, {
        'zonaGeograficaNacional': 'clientAditionalInfoSchema.zonaGeograficaNacional',
    });

    const duracionDeLaRelacion = useMemo(() => (
        null
    ), []);
    const productoSolicitado = useMemo(() => (
        null
    ), []);
    const montoProyectado = useMemo(() => (
        null
    ), []);
    const nivelIngresos = useMemo(() => (
        null
    ), []);
    const {zonaGeograficaNacional} = mapped;
    const paisDeOrigen = useMemo(() => (
        null
    ), []);
    const paisDeResidencia = useMemo(() => (
        null
    ), []);
    const canalDeDistribucion = useMemo(() => (
        null
    ), []);

    
    return (
        <FormGroup>
            <legend>
                {title}
            </legend>
            <Table hover size="sm"><tbody>
                <tr><td>Duración de la relación</td><td>{duracionDeLaRelacion}</td></tr>
                <tr><td>Producto Solicitado</td><td>{productoSolicitado}</td></tr>
                <tr><td>Monto proyectado</td><td>{montoProyectado}</td></tr>
                <tr><td>Nivel Ingresos</td><td>{nivelIngresos}</td></tr>
                <tr><td>Zona Geográfica Nacional</td><td>{zonaGeograficaNacional}</td></tr>
                <tr><td>País de Origen</td><td>{paisDeOrigen}</td></tr>
                <tr><td>País de Residencia</td><td>{paisDeResidencia}</td></tr>
                <tr><td>Canal de Distribución</td><td>{canalDeDistribucion}</td></tr>
            </tbody></Table>
        </FormGroup>
    );
}


export default CalcField;