import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';


export const NotificationApi = {
    async list({ offset, size, sortBy, ...filters }) {
        return checkErrors(await fetchApi.api.get('manage/notifications', { query: { offset, size, sortBy, ...filters } }));
    },

    async get(id) {
        return checkErrors(await fetchApi.api.get(`manage/notifications/${id}`));
    },


    async check() {
        return checkErrors(await fetchApi.api.get(`manage/notifications/notifications-check`));
    },

    async confirm(id) {
        return checkErrors(await fetchApi.api.get(`manage/notifications/notifications-confirm/${id}`));
    },

    async update(id, schema) {
        return checkErrors(await fetchApi.api.put(`manage/notifications/${id}`, { data: schema }));
    },
    async create(schema) {
        return (await checkErrors(await fetchApi.api.post(`manage/notifications/`, { data: schema }))).data;
    },
    async delete(id) {
        return checkErrors(
            await fetchApi.api.delete(`manage/notifications/${id}`)
        )
    },
}


export default NotificationApi;
