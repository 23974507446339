import makeDefferedPromise from "./makeDefferedPromise";


/**
 * A utility class for debouncing function calls and tracking whenever the
 * debounce countdown was started with a function call or not, and returning
 * a promise that resolves to the debounced function's result.
 */
export class PromiseDebouncer {
    /**
     * Constructs a new PromiseDebouncer.
     * @param {Function} func - The function to debounce.
     * @param {number} delay - The delay in milliseconds before executing the debounced function.
     */
    constructor(func, delay){
        /**
         * Wether the function is being actively debounced or not.
         * @type {boolean}
         * @public
         */
        this.armed = false;

        /**
         * the function to call.
         * @type {Function}
         * @public
         */
        this.func = func;

        /**
         * The delay to apply to the function.
         * @type {boolean}
         * @public
         */
        this.delay = delay;

        /**
         * The delay to apply to the function.
         * @type {boolean}
         * @public
         */
        this.callArgs = null;
        this.deferred = null;
        this.promise = null;
        this.timeout = null;
    }

    /**
     * Executes the debounced function.
     * @param {...any} args - Arguments to be passed to the debounced function.
     * @returns {boolean} - Indicates whether the function debouncing was armed.
     */
    tickWith(...args){
        const gotArmed = false;

        if (!this.armed) { 
            const deferred = makeDefferedPromise();
            this.armed = true;
            this.callArgs = args;
            this.deferred = deferred;
            this.promise = deferred.promise.then(() => {
                const args = this.callArgs;
                this.armed = false;
                this.callArgs = null;

                return this.func(...args);
            });
        }

        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.callArgs = args;
        this.timeout = setTimeout(() => {
            this.deferred.resolve();
        }, this.delay);

        return gotArmed;
    }
}


export default PromiseDebouncer;