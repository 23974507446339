import { useCallback, useMemo, useRef } from "react";
import clone from "../../util/clone";

import JsonTreeEditorNode from "./JsonTreeEditorNode";
import JsonStringEditor from "./StringEditor";
import JsonNumberEditor from "./NumberEditor";
import JsonBooleanEditor from "./BooleanEditor";
import JsonArrayEditor from "./ArrayEditor";
import { EDITORS, TYPE_ARRAY, TYPE_BOOLEAN, TYPE_NULL, TYPE_NUMBER, TYPE_OBJECT, TYPE_STRING } from "./util";
import JsonObjectEditor from "./ObjectEditor";
import useActionReducer from "../../util/useActionReducer";


EDITORS[TYPE_STRING  ] = JsonStringEditor;
EDITORS[TYPE_NUMBER  ] = JsonNumberEditor;
EDITORS[TYPE_OBJECT  ] = JsonObjectEditor;
EDITORS[TYPE_ARRAY   ] = JsonArrayEditor;
EDITORS[TYPE_BOOLEAN ] = JsonBooleanEditor;
EDITORS[TYPE_NULL    ] = () => null;


function JsonTreeEditor({
    mode="text",
    hideRoot,
    value: jsonValue,
    onChange
}){
    const ref = useRef();
    ref.current = { onChange };
    ref.current.jsonObject = useMemo(() => {
        try {
            return (mode === "text") ? JSON.parse(jsonValue) : jsonValue;
        } catch {
            return {}
        }
    }, [mode, jsonValue]);

    const setObject = useCallback((path, value) => {
        ref.current.onChange(path ? clone.set(ref.current.jsonObject, path, value) : value);
    }, []);

    return <div className={`json-tree-editor ${hideRoot ? 'no-root' : 'root'}`}><JsonTreeEditorNode
        componentOnly={hideRoot}
        node={ref.current.jsonObject} setObject={setObject}
    /></div>
}


export default JsonTreeEditor;