import useTitle from '../util/useTitle';


function AppTitle({title}){
    useTitle(title);

    return null;
}


export default AppTitle;