import rjsfValidateFormData from "@rjsf/core/lib/validate";

import deduplicate from './deduplicate';
import { getObject, invertMap, mapObject, setObject } from './mapObject';

export const ERRORS_KEY = '__errors';


function validateFormData(formObject, schema, objectMap, customFormats, context){
    const formData = mapObject(formObject, invertMap(objectMap), undefined, context);
    return cleanErrors(rjsfValidateFormData(formData, schema, undefined, undefined, undefined, customFormats).errorSchema)
}


function cleanErrors(errorSchema){
    if (!errorSchema) return [];

    const cleaned = {};
    const stack = [["", cleaned, errorSchema]];
    while (stack.length) {
        const [prefix, dst, current] = stack.shift();
        Object.entries(current).forEach(([key, value]) => { if (key === ERRORS_KEY) {
            value = value.filter(x => x !== 'should be equal to constant');
            if (prefix && value.length) {
                dst[key] = deduplicate(value);
            }
        } else {
            dst[key] = {};
            stack.push([key, dst[key], value]);
        }});
    }

    return cleaned;
}


export function appendError(errors, path, result){
    const listPath = `${path || ''}${path.length ? '.' : ''}${ERRORS_KEY}`;
    let errorList = getObject(errors, listPath);

    if (!errorList) {
        errorList = [];
        setObject(errors, listPath, errorList);
    }

    if (Array.isArray(result) && result.length) {
        errorList?.push(...result);
    } else {
        errorList?.push(result);
    }

}

export function errorSchemaToFlatList(errorSchema){
    const list = []
    const stack = [['', errorSchema]];
    while(stack.length) {
        const [path, current] = stack.shift();
        const prefix = path === '' ? '' : `${path}.`;
        if (current && typeof current === 'object' && !Array.isArray(current)) {
            Object.entries(current).forEach(([k, v]) => {
                if (k === ERRORS_KEY) {
                    v.forEach(e => list.push([path, e]));
                } else {
                    stack.unshift([`${prefix}${k}`, v]);
                }
            });
        }
    }
    return list;
}

export function mergeFlatListToErrorSchema(flatErrorList, errorSchema){
    errorSchema = errorSchema || {};
    flatErrorList.forEach(([path, error]) => appendError(errorSchema, path, error));
    return errorSchema;
}


export default validateFormData;