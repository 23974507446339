import React, { useCallback, useContext, useMemo, useRef } from 'react';
import Context2D from '../Context';
import useDragEffect from '../useDragEffect';


function SelectionRectangle2({
    x=0,
    y=0,
    width=10,
    height=10,
    className='',
    onClick,
    draggable,
    onDrag: prop_onDrag,
    onDrop
}){
    const pad = 3;
    const { viewport2DataCoords } = useContext(Context2D);
    const coordinateMap = useCallback(({x, y}) => {
        const [mx, my] = viewport2DataCoords([x, y], {toInt: true});
        return {x: mx, y: my};
    }, [viewport2DataCoords]);
    const dragDelta = useRef();
    const [onDragStart, onDrag] = useMemo(() => prop_onDrag ? [
        (idx, sx, sy) => { dragDelta.current = { x: x - sx, y:  y - sy}; },
        (idx, x, y) => prop_onDrag(idx, x + (dragDelta.current?.x || 0), y + (dragDelta.current?.y || 0)),
    ] : null, [prop_onDrag]);
    const {
        dragState,
        onMouseDownDelegate,
        onClickDelegate,
    } = useDragEffect({
        onDrag,
        onDragStart,
        onDrop,
        onClick,
        coordinateMap
    });

    return <>
        <rect
            className={`selection ${className} ${draggable ? 'draggable' : ''}  ${dragState ? 'is-dragging' : ''}`}
            onClick={onClickDelegate}
            onMouseDown={onMouseDownDelegate}
            x={x - pad - width/8} y={y - pad}
            width={width + 2 * pad + (width/8)*2}
            height={height + 2 * pad}
        />
    </>;
}
export default SelectionRectangle2;
