import React, { useEffect } from 'react'


function useTitle(title){
    useEffect(() => {
        const suffix = 'J.E.D.I.'
        document.title = title ? `${title} | ${suffix}` : suffix;
    }, [title])
}


export default useTitle;