import React, { useMemo, useState } from 'react';
import { Button as RSButton } from "reactstrap";
import { useJnx } from '../../util/jnx';
import { useSideChannelSubscription } from '../../util/useSideChannel';
import { useOpenModal } from '../FormModal';
import Loader from '../Loader';
import getPathFromId from "../../util/getPathFromId";


function Button(props) {
    const {
        formData,
        formContext,
        schema: {
            title,
            icon,
            tooltip,
        },
        idSchema: { $id } = {},
        uiSchema: {
            'ui:titleExpr': titleExpr,
            'ui:legendBottomExpr': legendBottomExpr,
            'ui:color': color,
            classNames: className,
            'ui:loaderOnClick': loaderOnClick,
            'ui:loaderText': loaderText,
            'ui:showIf': showIfExpr = true,
            'ui:onClick': onClickExpr
        } = {},
    } = props;

    const {
        setFormDataValues,
        sideChannel,
        bindings: fcBindings,
        // openModal,
    } = formContext;

    const openModal = useOpenModal();
    const [loading, setLoading] = useState();

    const path = useMemo(() => getPathFromId($id), [$id]);
    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}];

    const showIfJnx = useJnx(showIfExpr);
    const showIf = useMemo(() => (
        showIfJnx && showIfJnx.eval(rootFormData || {}, '', {
            root: rootFormData,
            formContext,
        })
    ), [formData, rootFormData, formContext]);

    const legendBottomJnx = useJnx(legendBottomExpr);
    const titleJnx = useJnx(titleExpr);

    const bindings = useMemo(() => ({
        ...fcBindings,
        openModal,
        rootFormData,
        formObject,
        formContext,
    }), [openModal, rootFormData, formObject, fcBindings, formContext]);

    const text = useMemo(() => {
        return (
            (title || titleJnx?.eval(rootFormData, path, bindings))?.trim()
        );
    }, [
        title,
        titleJnx, path, rootFormData, bindings
    ]);

    const textlegendBottom = useMemo(() => {
        return (legendBottomJnx?.eval(rootFormData, path, bindings)?.trim());
    }, [
        legendBottomJnx, path, rootFormData, bindings
    ]);

    const functionBinds = useMemo(() => ({
        set: setFormDataValues,
        log: (...args) => console.log("jsonata console log\n", ...args)
    }), [setFormDataValues]);

    const onClick = useJnx(onClickExpr, {
        fn: async ([e], onClickJnx) => {
            e.preventDefault();
            if (onClickJnx) {
                if (loaderOnClick) setLoading(true);
                try {
                    await onClickJnx.evalAsync(rootFormData, path, bindings);
                } finally {
                    if (loaderOnClick) setLoading(false);
                }
            }
        },
        functionBinds
    }, [openModal, path, rootFormData, bindings, loaderOnClick, functionBinds]);

    return (
        showIf ?
            <>
                <RSButton
                    className={`${className || ''}`}
                    color={color}
                    onClick={onClick}
                    title={tooltip || undefined}
                    data-cy={$id}
                >
                    {loading ? <Loader sm>{loaderText}</Loader> : (<>
                        {icon ? <i className={icon} /> : null}
                        {icon && text ? " " : null}
                        {text || null}
                    </>)}
                </RSButton>
                {textlegendBottom ? <span className='legendBottom-button'>{textlegendBottom}</span> : null}
            </>
            : null
    );
}


export default Button;