import dfsSearch from "./dfsSearch";


function parseObjectMapFromSchema(schema, initialObjectMap, options) {
    const objectMap = [];
    const {
        removeNullMappedFields
    } = options;

    if (initialObjectMap) {
        if (Array.isArray(initialObjectMap)) {
            objectMap.push(...initialObjectMap);
        } else {
            objectMap.push(...Object.entries(initialObjectMap));
        }
    }

    dfsSearch([['', schema, '', null]], ([path, current, prop, parent]) => {
        const pathPrefix = path.length ? `${path}.` : '';

        const {
            type,
            title,
            'map:field': mapField,
            'map:array': mapArray,
            'map:array-fields': mapArrayFields,
        } = current;

        if (path && mapField) {
            objectMap.push([mapField, path]);
            if (prop && parent && type === "null" && title === " " && removeNullMappedFields) {
                delete parent[prop];
            }
        }

        switch (current.type) {
            case "object":
                return Object.entries(current.properties).map(
                    ([prop, propSchema]) => [`${pathPrefix}${prop}`, propSchema, prop, current.properties]
                );
            case "array":
                if (mapArray) {
                    const arraySubMap = parseObjectMapFromSchema(current.items, null, options);
                    if (mapArrayFields) {
                        arraySubMap.push(...mapArrayFields);
                    }
                    if (arraySubMap.length > 0) {
                        objectMap.push([mapArray, { array: path, map: arraySubMap }, null, null]);
                    }
                }
                break;
        }
    });

    return objectMap;
}

export default parseObjectMapFromSchema;