import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';
import checkDocumentResponse, { STATUS } from './checkDocumentResponse';
import IntegrationsApi from "./IntegrationsApi";


export const RequisitionApi = {
    async getRequisitions({ resource, offset, size, sortBy, ...filters }) {
        return checkErrors(await fetchApi.api.get(resource, { query: { offset, size, sortBy, ...filters } }));
    },

    async getRequisitionDrafts({ offset, size, sortBy, ...filters }) {
        return checkErrors(await fetchApi.api.get(`requisitions/drafts`, { query: { offset, size, sortBy, ...filters } }));
    },

    async getRequisitionArchived({ offset, size, sortBy, ...filters }) {
        return checkErrors(await fetchApi.api.get(`requisitions/archived`, { query: { offset, size, sortBy, ...filters } }));
    },

    async getUWEEntityPreviousLog({ entityId }) {
        return checkErrors(
            await fetchApi.api.get(`requisitions/${entityId}/previous-log`)
        )
    },

    async getRequisition({ requisitionId }) {
        const requisition = await checkErrors(
            await fetchApi.api.get(`requisitions/${requisitionId}`)
        )

        if (requisition?.metadata?.jediId) {
            requisition.externalData = await RequisitionApi.getExternalData({ requisitionId: requisition.id });
        }

        return requisition;
    },

    async cancelRequisition({ requisitionId }) {
        return checkErrors(
            await fetchApi.api.post(`requisitions/${requisitionId}/cancel`)
        )
    },

    async resendRequisition({ requisitionId }) {
        return checkErrors(
            await fetchApi.api.post(`requisitions/${requisitionId}/resend`)
        )
    },

    async getRequisitionDraft({ draftId }) {
        return checkErrors(
            await fetchApi.api.get(`requisitions/drafts/${draftId}`)
        )
    },

    async deleteRequisitionDraft({ draftId }) {
        return checkErrors(
            await fetchApi.api.delete(`requisitions/drafts/${draftId}`)
        )
    },

    async getRequisitionHistory({ requisitionId }) {
        return (await checkErrors(
            await fetchApi.api.get(`requisitions/${requisitionId}/history`)
        )).data;
    },

    async getWorkflowStates({ requisitionId } = {}) {
        const query = { requisitionId };
        return (await checkErrors(
            await fetchApi.lookup.get(`workflow-states`, { query })
        )).data;
    },

    async getWorkflowStatusMap({ requisitionId } = {}) {
        const states = (await RequisitionApi.getWorkflowStates({ requisitionId })) || [];
        return states.reduce((_, { name, title }) => {
            (_[name] = title || name);
            return _;
        }, {});
    },

    async getRequisitionLog({ requisitionId }) {
        return checkErrors(
            await fetchApi.api.get(`requisitions/${requisitionId}/log`)
        )
    },

    async teleportRequisitionToLogEntry({ requisitionId, stepIdx }) {
        return checkErrors(
            await fetchApi.api.post(`requisitions/${requisitionId}/log/teleport`, { data: { index: stepIdx } })
        )
    },

    async getRequisitionAssignments({ requisitionId }) {
        return checkErrors(await fetchApi.api.get(`requisitions/${requisitionId}/assignments`));
    },

    async setRequisitionAssignment({ requisitionId, roleId, userId }) {
        return checkErrors(await fetchApi.api.post(`requisitions/${requisitionId}/assign`, {
            data: { roleId, userId }
        }));
    },

    async setRequisitionStep({ requisitionId, step }) {
        return checkErrors(await fetchApi.api.post(`requisitions/${requisitionId}/set-step`, {
            data: { step }
        }));
    },

    async createRequisition({ newRequisition }) {
        return checkErrors(await fetchApi.api.post(`requisitions/`, { data: newRequisition }));
    },

    async updateRequisition({ id, page, formData }) {
        return checkErrors(await fetchApi.api.put(`requisitions/${id}/${page}`, { data: formData }));
    },

    async addProducts({ requisitionId, productIds }) {
        return checkErrors(
            await fetchApi.api.post(`requisitions/${requisitionId}/products`, { data: { productIds } })
        );
    },

    async awaitDocuments({ requisitionId, page }) {
        return checkErrors(
            await fetchApi.api.put(`requisitions/${requisitionId}/await-documents`, {
                data: {
                    page
                }
            })
        );
    },

    async createRequisitionNote({ requisitionId, note, sendNotification = true }) {
        return checkErrors(await fetchApi.api.post(`requisitions/${requisitionId}/notes`, {
            data: {
                note,
                sendNotification
            }, json: true
        }));
    },

    async updateRequisitionNote({ requisitionId, noteId, note }) {
        return checkErrors(await fetchApi.api.put(`requisitions/${requisitionId}/notes/${noteId}`, {
            data: note, json: true
        }));
    },

    async getRequisitionNotes({ requisitionId }) {
        return checkErrors(await fetchApi.api.get(`requisitions/${requisitionId}/notes`));
    },

    async getRoleAssignments({ requisitionId }) {
        return checkErrors(await fetchApi.api.get(`requisitions/${requisitionId}/role-assignments`));
    },

    async getRequisitionZipDownloadUrl({ requisitionId, filter }) {
        return (await checkErrors(await fetchApi.api.get(`requisitions/${requisitionId}/download-documents`, { query: { filter } }))).data;
    },
    async getWorkflowRoles({ requisitionId }) {
        return checkErrors(await fetchApi.api.get(`requisitions/${requisitionId}/workflow-roles`));
    },
    async getProgressByOrganization({ requisitionId }) {
        return checkErrors(await fetchApi.api.get(`requisitions/getProgressByOrganization/${requisitionId}`));
    },

    async generatePreviewFor({ requisitionId, data }) {
        return (await checkErrors(
            await fetchApi.api.post(`requisitions/${requisitionId || 0}/form-preview`, {
                data, json: true
            })
        )).data;
    },

    async verifyPreviewGeneration({ referenceId }) {
        const response = checkDocumentResponse((await checkErrors(
            await fetchApi.api.get(`requisitions/form-preview/verify/${referenceId}`)
        )).data);

        console.log("response", response);

        if (response.status === STATUS.NOTFOUND || response.status === STATUS.ERROR) {
            throw new Error("Error buscando documento.");
        }

        return response;
    },

    getExternalData({ requisitionId }) {
        return IntegrationsApi.CallOperationUrl("jedi/get-requisition-status", {
            req: requisitionId
        });
    }

}


export default RequisitionApi;
