import React, { useCallback, useMemo } from 'react';

import { matchesPattern } from '../../../util/patternMatching';
import OE_FIELD_MAP from './types';
import ErrorBoundary from '../../ErrorBoundary';
import { useJnx } from '../../../util/jnx';

function ObjectEditorField({
    id, fieldProps, onChange, value, scope, object
}) {
    const showIfJnx = useJnx(fieldProps.showIf);

    const show = useMemo(() => showIfJnx ? showIfJnx.eval(
        object,
        fieldProps.property,
        { scope, fieldProps, object }
    ) : true, [object, showIfJnx, scope]);

    const Component = OE_FIELD_MAP.filter(fdef => matchesPattern(fieldProps, fdef.pattern))[0]?.Component;

return Component && show ? (<div className={fieldProps.class || 'col-lg-12'}><ErrorBoundary>
        <Component id={id} object={object} fieldProps={fieldProps} scope={scope} value={value} onChange={onChange} />
    </ErrorBoundary></div>) : null;
}

export default ObjectEditorField;