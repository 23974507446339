import {useEffect} from "react";


function usePeriodicEffect(
    effectFn,
    delay=1000,
    dependencies=[]
){
    useEffect(() => {
        const interval = setInterval(() => {
            effectFn();
        }, delay);
        return () => {
            clearInterval(interval);
        };
    }, [...dependencies]);

}


export default usePeriodicEffect;