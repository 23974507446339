import React, { useContext } from 'react';
import { Container } from 'reactstrap';
import AppContext from '../../context/AppContext';
import { AuthContext } from '../../context/AuthContext';
import NavMenu from '../NavMenu';
import Sidebar from '../Sidebar';


function InternalLayout({ children }) {
  const {user} = useContext(AuthContext);
  const {sidebarExpanded} = useContext(AppContext);
  const versionTag = window.APP_VERSION;

  return (
    <div className={`app-layout ${sidebarExpanded ? 'has-sidebar-tab-open' : ''}`}>
      <NavMenu />
      {user ? <Sidebar /> : null}
      <Container className="main-container" fluid>
        {children}
      </Container>
      {versionTag ? (<div className="version-tag">{versionTag}</div>) : null}
    </div>
  );
}


export default InternalLayout;