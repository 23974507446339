import React, { useMemo, useContext } from 'react';
import { useHistory } from 'react-router';
import { useOpenModal } from '../components/FormModal';
import { AuthContext } from '../context/AuthContext';
import ModalContainer from '../components/ModalContainer';

function SelectWorkflowBeforeCreate(props) {

    const { slug, customButton, callBack = null } = props;

    const auth = useContext(AuthContext);

    const history = useHistory();

    const openModal = useOpenModal();

    const WORKFLOW_SELECT = (workflows) => {
        const options = workflows?.map((wf) => { return { text: wf.workflow.name, color: 'primary', value: wf } });
        return [
            "ConfirmationModal",
            ``,
            `Seleccione el Workflow con el que desea trabajar`,
            options,
            { reversible: "-" }
        ]
    };

    const unitOrgWorkflows = useMemo(() => auth?.user?.user?.organizationalUnit?.workflows?.filter(x => x.workflow.slug?.includes(slug)), [auth]);

    async function onCreateUWEEntity() {

        if (!unitOrgWorkflows || unitOrgWorkflows?.length < 2) {

            const wf = unitOrgWorkflows?.[0] || { workflow: { workflowId: 1, workflow: { name: 'ASG' } } };


            if (!callBack) {

                history.push({
                    pathname: `/workflow/${slug}/new`,
                    search: '?type=regular',
                    workflow: wf
                })
            } else {
                callBack({ workflow: wf, show: false });
            }

        }

        const wf = (await openModal(...WORKFLOW_SELECT(unitOrgWorkflows)));
        if (wf) {

            if (!callBack) {

                history.push({
                    pathname: `/workflow/${slug}/new`,
                    search: '?type=regular',
                    workflow: wf,
                    showWorkflow: true
                })
            } else {
                callBack({ workflow: wf, show: true });
            }

        }

    }

    return (
        <>
            <ModalContainer />
            {customButton(onCreateUWEEntity)}
        </>);
}

export default SelectWorkflowBeforeCreate;