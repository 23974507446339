import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { useHistory } from "react-router";

import useSearchParams from "../util/useSearchParams";
import RequisitionProgress from "./RequisitionProgress";
import RequisitionAssignments from "./RequisitionAssignments";
import RequisitionNote from "./RequisitionNote";
import RequisitionHistory from "./RequisitionHistory";
import Notification from '../components/Notification';
import UWEFormComponent from "./UWEFormComponent";
import { AuthContext } from "../context/AuthContext";
import RequisitionApi from "../api/RequisitionsApi";
import { useOpenModal } from "../components/FormModal";
import RequisitionManagement from "./RequisitionManagement";


const RESEND_REQUISITION_MODAL_DEF = (currentRequisition) => [
    "ConfirmationModal",
    "Reenviar Requisición",
    `Seguro que quiere reenviar la requisición #${currentRequisition?.metadata.number} a Jedi?`,
    [
        { text: "Si", color: "primary", value: true },
        { text: "No", color: "secondary", value: false },
    ]
];

const CANCEL_REQUISITION_MODAL_DEF = (currentRequisition) => [
    "ConfirmationModal",
    "Cancelar Requisición",
    `Seguro que quiere cancelar la requisición #${currentRequisition?.metadata.number}?`,
    [
        { text: "Si", color: "primary", value: true },
        { text: "No", color: "secondary", value: false },
    ]
];

const COPY_REQUISITION_MODAL_DEF = (currentRequisition, message = "") => [
    "ConfirmationModal",
    "Copiar a Nueva Requisición",
    message || `Seguro que quiere copiar la información de esta requisición a una nueva?`,
    [
        { text: "Si", color: "primary", value: true },
        { text: "No", color: "secondary", value: false },
    ],
    { reversible: true }
];


function RequisitionUWEFormComponent({
    currentRequisition,
    currentStatus,
    readOnly,
    form,
    slug
}) {
    const [
        [showProgress, setShowProgress],
        [showAssignments, setShowAssignments],
        [showNotes, setShowNotes],
        [showHistory, setShowHistory]
    ] = useSearchParams({
        progress: slug === "requisitions" ? 1 : 0,
        assignments: slug === "requisitions" ? 1 : 0,
        notes: slug === "requisitions" ? 1 : 0,
        history: slug === "requisitions" ? 1 : 0,
    });

    const history = useHistory();
    const openModal = useOpenModal();

    const auth = useContext(AuthContext);
    const petitionerRole = 'Peticionario';
    const isPetitioner = auth?.user?.user?.id === currentRequisition?.assignments[petitionerRole];
    const { OverrideWorkflow } = auth?.user?.permissions || {};
    const IsAgencyManager  = auth?.user?.user?.accessProfile?.name === "agencymanager";

    const needsResend = currentStatus?.id === "event.resendToJedi" || currentStatus?.id === "event.waitPOCompletion";
    const { isOpen } = currentRequisition || {};

    const showLeft = (showProgress | 0) || (showAssignments | 0);
    const showRight = (showNotes | 0) || (showHistory | 0);

    const [_mainContent, setMainContent] = useState();
    const mainContent = _mainContent || "form";

    async function resendRequitsition() {
        if (await openModal(...RESEND_REQUISITION_MODAL_DEF(currentRequisition))) {
            await RequisitionApi.resendRequisition({ requisitionId: currentRequisition.id });
            history.push("/");
        }
    }

    async function cancelRequitsition() {
        if (await openModal(...CANCEL_REQUISITION_MODAL_DEF(currentRequisition))) {
            await RequisitionApi.cancelRequisition({ requisitionId: currentRequisition.id });
            history.push("/");
        }
    }

    async function copyToNewRequisition() {
        const resource = `requisitions/ASGRequisition/list`;
        const validation = currentRequisition?.metadata?.status === "Devuelto - Inactivo" ? await RequisitionApi.getRequisitions({ resource, reqCopyNumber: currentRequisition?.metadata?.number }) : null;
        const reqCopy = validation?.items?.[0];
        const message = reqCopy ? `Esta requisición ya fue copiada en la requisición ${reqCopy.metadata.number}, está seguro que desea continuar?` : "";
        if (await openModal(...COPY_REQUISITION_MODAL_DEF(currentRequisition, message))) {
            history.push('/workflow/requisitions/new', {
                reqCopyNumber: currentRequisition?.metadata?.number,
                workflowId: currentRequisition?.workflowId,
                reqCopy: JSON.stringify(cleanupRequisitionDataForCopy(currentRequisition.data))
            });
        }
    }

    return (<div className="form-container">
        <div className={`sidepanel sidepanel-left ${showLeft ? 'expanded' : ''}`}>
            <ExpandableTabSidePanel
                title="Progreso"
                content="progress"
                expanded={(showProgress | 0)} setExpanded={setShowProgress}
            >
                <RequisitionProgress slug={slug} requisition={currentRequisition} />
            </ExpandableTabSidePanel>
            <ExpandableTabSidePanel
                title="Asignaciones"
                content="assignment"
                expanded={showAssignments | 0} setExpanded={setShowAssignments}
            >
                <RequisitionAssignments requisition={currentRequisition} auth={auth} />
            </ExpandableTabSidePanel>
        </div>
        <div className={`sidepanel sidepanel-right ${showRight ? 'expanded' : ''}`}>
            {mainContent !== "notes" && slug !== "tickets" ? <ExpandableTabSidePanel
                title="Notas"
                expanded={showNotes | 0} setExpanded={setShowNotes}
                onMaximize={() => setMainContent("notes")}
            >
                <RequisitionNote requisition={currentRequisition} totalExpanded={mainContent === "notes"} readOnly={readOnly} />
            </ExpandableTabSidePanel> : null}
            {mainContent !== "history" ? <ExpandableTabSidePanel
                title="Historial"
                expanded={showHistory | 0} setExpanded={setShowHistory}
                onMaximize={() => setMainContent("history")}
            >
                <RequisitionHistory requisition={currentRequisition} totalExpanded={mainContent === "history"} readOnly={readOnly} />
            </ExpandableTabSidePanel> : null}
        </div>
        <div className="form-component requisition-form">
            <div className="requisition-title">
                {currentRequisition?.entityTypeId === 1 ? (currentRequisition?.metadata?.number || currentRequisition?.id) : form?.title}
                <div className="btn-bar">
                    {isOpen && OverrideWorkflow && needsResend ? (
                        <Button color="primary" onClick={resendRequitsition}>
                            {currentStatus?.id === "event.waitPOCompletion" ? 'Sincronizar estado con Jedi' : 'Reenviar a Jedi'}
                        </Button>
                    ) : null}
                    {isPetitioner || IsAgencyManager ? (isOpen ? (
                        <Button className="cancel-btn" color="primary" data-cy="cancel-requisition" onClick={cancelRequitsition}>Cancelar</Button>
                    ) : (
                        <Button className="cancel-btn" color="primary" data-cy="copy-requisition" onClick={copyToNewRequisition}>Copiar a Nueva Requisición</Button>
                    )) : null}
                </div>
            </div>
            {mainContent === "form" ?
                <>
                    {currentRequisition?.state?.status === 'Devuelto - Inactivo' ? showBackReason('Motivo', 'El tiempo para resometer la requisición a la ASG caducó. Puede contactar al Oficial de Enlace para orientación sobre próximos pasos.') : null}
                    {currentRequisition?.metadata?.toCompleteReason ? showBackReason('Motivo', currentRequisition.metadata.toCompleteReason) : null}
                    {currentRequisition?.metadata?.sendBackReason && slug === "requisitions" ? showBackReason('Motivo de devolución', currentRequisition.metadata.sendBackReason) : null}
                    <UWEFormComponent
                        currentRequisition={currentRequisition} form={form}
                    />
                </>
                : null
            }
            {mainContent === "notes" && slug !== "tickets" ? <FullExpandedPanel title="Notas" onClose={() => { setShowNotes(0); setMainContent(); }} onMinimize={() => setMainContent()} >
                <RequisitionNote requisition={currentRequisition} totalExpanded readOnly={readOnly} />
            </FullExpandedPanel> : null}
            {mainContent === "history" ? <FullExpandedPanel title="Historial" onClose={() => { setShowHistory(0); setMainContent(); }} onMinimize={() => setMainContent()} >
                <RequisitionHistory requisition={currentRequisition} totalExpanded readOnly />
            </FullExpandedPanel> : null}
            {mainContent === "wf-admin" ? <FullExpandedPanel title="Manejo de Requisición" onClose={() => { setMainContent(); }} >
                <RequisitionManagement requisition={currentRequisition} />
            </FullExpandedPanel> : null}
        </div>
        <div className="toolbar-bottom-affix">
            {isOpen && OverrideWorkflow ? (
                <Button color={_mainContent === "wf-admin" ? "primary" : "clear"} type="button"
                    onClick={() => _mainContent === "wf-admin" ? setMainContent() : setMainContent("wf-admin")}>
                    <i className="fa fa-hammer" />
                </Button>
            ) : null}
        </div>
    </div>)
}


function ExpandableTabSidePanel({ title, children, expanded, setExpanded, onMaximize }) {
    return expanded ? (<Card className="expandable-tab-side-panel-panel">
        <CardHeader>
            {title}
            <div className="btn-bar">
                {onMaximize ? (<Button onClick={onMaximize} title={`Expandir ${title}`} ><i className="fa fa-expand" /></Button>) : null}
                <Button onClick={() => { setExpanded(0); }} title={`Cerrar ${title}`} ><i className="fa fa-times" /></Button>
            </div>
        </CardHeader>
        <CardBody>{children}</CardBody>
    </Card>) : (
        <button
            className="expandable-tab-side-panel-tab"
            onClick={() => { setExpanded(1); }}
            title={`Cerrar ${title}`}
        >{title}</button>
    );
}

function showBackReason(typeMessage, message) {
    return (
        <Notification color="warning">{`${typeMessage}: ${message}`}</Notification>
    );
}


function FullExpandedPanel({
    title,
    onClose,
    onMinimize,
    children
}) {
    return (<Card className="requisition-form-full-expanded-sidepanel">
        <CardHeader className="card-header">
            {title}
            <div className='btn-bar'>
                {onMinimize ? <Button onClick={onMinimize} title={`Minimizar ${title}`} ><i className="fa fa-compress" /></Button> : null}
                {onClose ? <Button onClick={onClose} title={`Cerrar ${title}`} ><i className="fa fa-times" /></Button> : null}
            </div>
        </CardHeader>
        <CardBody>{children}</CardBody>
    </Card>)
}



function cleanupRequisitionDataForCopy(reqData) {
    const copy = JSON.parse(JSON.stringify(reqData));
    Object.keys(copy).filter(k => /AllItemsDeniedReason$/.test(k)).forEach(k => delete copy[k]);
    delete copy.dispatch;
    delete copy.quote;
    delete copy.notes;
    delete copy.technology?.pritsAuthorizationFile;
    delete copy.ogpAuthorizationFile;
    delete copy.fundsCertificationFile;
    (copy.items || []).forEach(item => {
        Object.keys(item).filter(k => /(Reject|Accept)$/.test(k)).forEach(k => delete item[k]);
        delete item.panel2;
        delete item.quantityDispatch;
        delete item.quantityOriginal;
        delete item.totalDispatch;
    })

    return copy;
}


export default RequisitionUWEFormComponent;