import { useState, useMemo, useEffect } from 'react';
import getSVGXY from './getSVGXY';


function useDragEffect({ idx, onDrag, onDrop, onClick, onDragStart, coordinateMap }) {
    const [dragState, setDragState] = useState();

    const onMouseDownDelegate = useMemo(() => {
        return (e) => {
            e.stopPropagation();
            setDragState('primed')
        }
    }, []);

    const onClickDelegate = useMemo(
        () => (onClick ? (event) => (!dragState || dragState === 'primed') && onClick(event) : undefined),
        [onClick, dragState]
    );

    useEffect(() => {
        if (onDrag && dragState) {
            const onMouseUp = (event) => {
                setDragState();
                if (dragState === 'dragging' && onDrop) {
                    event.stopPropagation();
                    event.stopImmediatePropagation();
                    event.preventDefault();
                    onDrop(idx);
                }
            };
            const onMouseMove = (event) => {
                let pt = getSVGXY(event);
                if (pt) {
                    if (coordinateMap) pt = coordinateMap(pt);
                    if (dragState === 'primed') {
                        setDragState('dragging');
                        if (onDragStart) {
                            onDragStart(idx, pt.x, pt.y, event.movementX, event.movementY);
                        }
                    }
                    onDrag(idx, pt.x, pt.y, event.movementX, event.movementY);
                }
            };
            // const onMouseMove = (event) => onPointDrag(event.target.clientX, event.target.clientY);
            document.addEventListener('mouseup', onMouseUp);
            document.addEventListener('mousemove', onMouseMove);
            return () => {
                document.removeEventListener('mouseup', onMouseUp);
                document.removeEventListener('mousemove', onMouseMove);
            };
        }

        return undefined;
    }, [idx, coordinateMap, dragState, onDragStart, onDrag, onDrop]);

    return {
        dragState,
        onMouseDownDelegate,
        onClickDelegate,
    };
}

export default useDragEffect;
