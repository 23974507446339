import React from 'react';
import { Spinner } from 'reactstrap';


function Loader({ fullscreen, sm, children }) {
    let component = (<Spinner animation="border" size={sm ? "sm": undefined} />);

    if (children) {
        component = <div className="loader-container">{component}<div>{children}</div></div>;
    }

    if (fullscreen) {
        return <div className="loader fullscreen"><div>{component}</div></div>;
    }

    return component;
}


export default Loader;