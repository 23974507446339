import { useMemo } from "react";

import dfsSearch from "./dfsSearch";
import { appendError } from "./formValidation";
import { concatenatePaths, getObject } from "./mapObject";
import Jnx, { parseJnxExpr } from "./jnx";


const REQUIRED_JNX = new Jnx("$isEmpty($value) ? 'es requerido.' : false");
const REQUIRED_IF_JNX = (jnx) => {
    jnx = parseJnxExpr(jnx);
    jnx.expr = `($isTruthy(${jnx.expr}) and $isEmpty($value)) ? 'es requerido.' : false`;
    return new Jnx(jnx);
};


export default function useJnxFormValidation(schema, uiSchema, formFieldVisibilityRef) {
    return useMemo(() => {
        const validations = parseValidations(schema, uiSchema);

        return (validations.length ? ((formData, errors, scope, formFields) =>
            runJnxValidation(validations, formFieldVisibilityRef, formData, errors, scope, formFields || {})
        ) : undefined);

    }, [uiSchema]);
}

function runJnxValidation(validations, formFieldVisibilityRef, formData, errors, _, formFields) {
    validations.forEach(({ path: acPath, jnx }) => {
        acPath.getAll(formData).forEach(([path, value]) => {
            const isVisible = formFields[path];
            const result = isVisible ? jnx.eval(formData, path, {
                value,
                rootFormData: formData
            }) : false;
            if (result) {
                appendError(errors, path, result);
            }
        })
    });
    return errors;
}


/** Represents an object path that is aware of arrays in the path and can be used to list all reachable objects.
 */
class ArrayConsciousObjectPath {
    constructor(segments) {
        this.segments = segments || [];
    }

    append(...components) {
        const segments = this.segments.slice();
        const last = segments[segments.length - 1] || '';
        const current = concatenatePaths(last, ...components);
        if (!segments.length) {
            segments.push(current);
        } else {
            segments[segments.length - 1] = current;
        }
        return new ArrayConsciousObjectPath(segments);
    }

    appendArray() {
        return new ArrayConsciousObjectPath(this.segments.concat(''));
    }

    getAll(data) {
        const list = [];
        const N = this.segments.length;
        const N1 = N - 1;
        dfsSearch([
            ['', 0, data]
        ], ([parentPath, segmentIdx, searchData]) => {
            const segment = this.segments[segmentIdx];
            let segmentPath = `${parentPath}${parentPath.length ? '.' : ''}${segment}`;
            const segmentData = getObject(searchData, segment);

            if (segmentData && Array.isArray(segmentData) && segmentIdx < N1) {
                const nextIdx = segmentIdx + 1;
                const segmentSep = segmentPath.length ? '.' : ''
                return segmentData.map((item, idx) => [`${segmentPath}${segmentSep}${idx}`, nextIdx, item]);
            } else if (segmentIdx === N1) {
                if (segmentPath.endsWith('.')) segmentPath = segmentPath.substring(0, segmentPath.length - 1);
                list.push([segmentPath, segmentData]);
            }
        });

        return list;
    }

}


/** Parses the validation expressions in the given schema and uiSchema pair.
 * @param {object} schema - the jsonschema to parse
 * @param {object} uiSchema - the associated ui schema to parse
 * @returns {array} array of validations
 */
function parseValidations(schema, uiSchema) {
    const validations = [];

    dfsSearch([[
        new ArrayConsciousObjectPath(['']), '', schema, uiSchema
    ]], ([currentPath, parentName, currentSchema, currentUISchema]) => {
        if (!currentUISchema) return;

        const { type, properties, items, title, 'map:formField': formField } = currentSchema;
        if (formField) {
            currentPath = currentPath.append(formField);
        }
        const name = (title || parentName).replaceAll('*', '').trim();
        const {
            'akc:requiredIfVisible': requiredIfVisible,
            'akc:requiredIf': requiredIfExpr,
            'akc:validate': validateExpr
        } = currentUISchema;

        if (requiredIfVisible) {
            validations.push({ path: currentPath, jnx: REQUIRED_JNX });
        }

        if (requiredIfExpr) {
            validations.push({ path: currentPath, jnx: REQUIRED_IF_JNX(requiredIfExpr) });
        }

        if (validateExpr) {
            validations.push({ path: currentPath, jnx: new Jnx(validateExpr) });
        }

        switch (type) {
            case "object": {
                return Object.entries(properties).map(([propName, propSchema]) => [
                    currentPath.append(propName),
                    name,
                    propSchema,
                    currentUISchema[propName]
                ]);
            }
            case "array": {
                return [[
                    currentPath.appendArray(),
                    name,
                    items,
                    currentUISchema.items
                ]];
            }
            default: break;
        }
    });

    return validations;
}
