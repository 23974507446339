import { Trans } from '@lingui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Form, Nav, NavItem, NavLink } from 'reactstrap';
import { Link, useHistory, useParams } from "react-router-dom";

import useResourceLoader from '../../util/useResourceLoader';
import RequisitionsApi from '../../api/RequisitionsApi';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';
import forms from "../forms/forms";

import RequisitionLog from './RequisitionLog';
import RequisitionNotes from './RequisitionNotes';
import RequisitionPreviousForms from './RequisitionPreviousForms';
import WaitForRequisitionFiles from "../../components/WaitForRequisitionFiles";
import RequestReloadContext from '../../context/RequestReloadContext';

import { toDrDateTime } from '../../util/dates';
import { AuthContext } from '../../context/AuthContext';
import roleNameMatches from '../../util/roleNameMatches';
import canViewStatus from '../../util/canViewStatus';
import { NO_FORM } from '../../constants';
import useSearchParams from '../../util/useSearchParams';
import CurrentRequisitionForm from "../../blc/CurrentRequisitionForm";
import InternalLayout from '../../components/layouts/InternalLayout';
import useTitle from '../../util/useTitle';
import AppContext from '../../context/AppContext';
import WorkflowApi from '../../api/WorkflowApi';


export function RequisitionDetails({
    history,
    location
}) {
    const { id, entityType: slug } = useParams();
    const [
        [show, setShow],
        [readonlyParam],
        [sentFrom]
    ] = useSearchParams({
        show: 'form',
        readonly: undefined,
        from: undefined
    });

    const [reloadCount, setReloadCount] = useState(sentFrom === 'requisition-intake' ? 1 : 0);

    const [currentRequisition, loading, errorLoadingRequisitions] = useResourceLoader(() => {
        return RequisitionsApi.getRequisition({ requisitionId: id });
    }, [reloadCount], null);

    const [workflowInfo, loadingWorkflowInfo, errorLoadingWorkflowInfo] = useResourceLoader(() => (currentRequisition?.workflowId ? (
        WorkflowApi.getWorkflowInfoForId({ id: currentRequisition?.workflowId })
    ) : null), [currentRequisition?.workflowId]);

    const isClosed = (currentRequisition || {}).isOpen === false;
    const isComplete = (currentRequisition || {}).completed === true;
    const readonly = !!(readonlyParam * 1) || isClosed;
    const [statusIdx, setStatusIdx] = useState(0);

    const auth = useContext(AuthContext);
    const { closeRequisition, viewPreviousForms } = ((auth || {}).user || {}).permissions || {};
    const statuses = useMemo(() => currentRequisition && currentRequisition.status.filter(status => status.canWork), [currentRequisition]);

    const currentStatus = useMemo(() => {
        if (!statuses) return null;
        return Number.isFinite(statusIdx) ? statuses[statusIdx] : (statuses.length === 1 ? statuses[0] : null);
    }, [statuses, statusIdx]);

    const rrcState = RequestReloadContext.useHook(() => {
        console.log('rrcState hook', reloadCount);
        setReloadCount(reloadCount + 1);
    }, [reloadCount]);


    const [showDebugControls, setShowDebugControls] = useState();

    const page = currentStatus?.page || 'view-requisition';
    const FormComponent = forms[page];
    const hasNoForm = FormComponent === NO_FORM;
    const FileFormComponent = forms['requisition-files'];
    const CloseRequisitionFormComponent = forms['requisition-close'];
    const showFilesControls = false;

    useEffect(() => {
        if (reloadCount > 0 && !page) {
            history.push("/");
        }
    }, [reloadCount, page])

    const tabs = useMemo(() => ([
        ...(!readonly && !hasNoForm ? [
            { tab: "form", title: `Formulario` },
        ] : []),
        ...(viewPreviousForms ? [
            { tab: "previousForms", title: "Formularios Anteriores" }
        ] : []),
        ...(showFilesControls ? [
            { tab: "files", title: "Documentos" },
        ] : []),
        ...(showDebugControls ? ([
            { tab: "log", title: "Log de Estados" },
        ]) : []),
        { tab: "assignments", title: "Asignaciones" },
        { tab: "notes", title: "Notas" },
        ...(!isComplete && (!isClosed && closeRequisition) ? [
            { tab: "close", title: "Desestimar Requisición", side: "right" }
        ] : []),
    ]).reduce((_, t) => {
        _[t.tab] = t;
        return _;
    }, {}), [
        hasNoForm,
        showDebugControls,
        showFilesControls,
        isComplete
    ])

    const activeTab = tabs[show] || tabs.form || tabs.previousForms || Object.values(tabs)[0];
    const { awaitDocuments } = currentStatus || {};
    const showControls = !awaitDocuments;

    useTitle(currentRequisition?.metadata?.number || "Requisición");

    return <InternalLayout>
        <AppContext.SetSidebarTab value="home" />
        {(loading ? (
            <Loader fullscreen />
        ) : (errorLoadingRequisitions ? (
            <Notification color="danger">{JSON.stringify(errorLoadingRequisitions)}</Notification>
        ) : (!currentRequisition ? (
            <Notification color="danger">Requisición no encontrada. Esto debe ser un error, por favor contacte a la administraci&oacute;n).</Notification>
        ) : (<div>
            <RequestReloadContext.Provider value={rrcState}>
                <button style={{
                    position: 'absolute',
                    top: 60,
                    right: 0,
                    opacity: '1%'
                }} onClick={() => setShowDebugControls(!showDebugControls)}>
                    {showDebugControls ? 'hide' : 'show'} debug
                </button>

                {showControls ? (<>
                    {/* <Nav tabs className="form-tabs">{Object.entries(tabs).map(([key, tab]) => (
                    <NavItem key={key} className={tab.side ? `side-${tab.side}` : ''}><NavLink
                        active={activeTab === tab}
                        onClick={() => setShow(key)}
                    >{tab.title}</NavLink></NavItem>
                ))}</Nav> */}

                    {activeTab ? (activeTab.tab === "files" ? (
                        <FileFormComponent readonly={readonly} currentRequisition={currentRequisition} page='requisition-files' />
                    ) : (activeTab.tab === "previousForms" ? (
                        <RequisitionPreviousForms readonly={readonly} currentRequisition={currentRequisition} />
                    ) : (activeTab.tab === "log" ? (
                        <RequisitionLog readonly={readonly} id={id} />
                    ) : (activeTab.tab === "assignments" ? (
                        null
                    ) : (activeTab.tab === "notes" ? (
                        <RequisitionNotes readonly={readonly} id={id} />
                    ) : (activeTab.tab === "close" ? (
                        <CloseRequisitionFormComponent currentRequisition={currentRequisition} />
                    ) : (<>
                        {showDebugControls ? <DebugControls
                            id={id}
                            readonly={readonly}
                            currentRequisition={currentRequisition}
                            statuses={statuses}
                            currentStatus={currentStatus}
                            statusIdx={statusIdx}
                            setStatusIdx={setStatusIdx}
                        /> : null}
                    </>))))))) : null}
                </>) : null}

                {hasNoForm || !workflowInfo || loadingWorkflowInfo ? null : (
                    <CurrentRequisitionForm
                        slug={slug}
                        viewForm={workflowInfo.viewForm}
                        hide={activeTab !== tabs.form} currentRequisition={currentRequisition} currentStatus={currentStatus} />
                )}

            </RequestReloadContext.Provider>
        </div>))))}</InternalLayout>;
}


function DebugControls({
    id, currentRequisition,
    statuses,
    currentStatus,
    statusIdx, setStatusIdx,
}) {
    const history = useHistory();
    const { page, role, canSendBack } = currentStatus || {};

    async function simulateFormSubmit() {
        await RequisitionsApi.updateRequisition({ id, page, formData: currentRequisition });
        history.push("/");
    }

    async function simulateFormSendBack() {
        await RequisitionsApi.updateRequisition({ id, page, formData: { ...currentRequisition, sentBack: 1 } });
        history.push("/");
    }

    return (<>
        {statuses.length > 1 ? (<>
            View Form
            <select value={statusIdx} onChange={({ target: { value } }) => setStatusIdx(value | 0)}>{statuses.map(({ page, role }, index) => (
                <option key={index} value={index}>{page} ({role})</option>
            ))}</select>
        </>) : null}
        <div>Current Form page {JSON.stringify(page)} (role: {JSON.stringify(role)})</div>
        <Button color="primary" onClick={simulateFormSubmit}>Simulate Form Submit</Button>
        {canSendBack ? (
            <Button color="primary" onClick={simulateFormSendBack}>Simulate Form Send Back</Button>
        ) : null}
        <hr />
    </>);
}


export default RequisitionDetails;