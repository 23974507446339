import React, { useState } from "react";
import useSearchParams from "../../../util/useSearchParams";
import EditNotificationForm from "./EditNotification";
import NotificationTable from "./NotificationTable";
import PopupNotification from "../../../components/PopupNotification";


function Notifications() {
    const [
        [id, setId]
    ] = useSearchParams({
        id: undefined
    });

    const [previewNotif, setPreviewNotif] = useState(null);

    const contentToRender = id ? (
        <EditNotificationForm setPreviewNotif={setPreviewNotif} id={id} />
    ) : (
        <NotificationTable setPreviewNotif={setPreviewNotif} />
    );

    return (
        <>
            <PopupNotification isPreview={1} setPreviewNotif={setPreviewNotif} prevNotification={previewNotif} />
            {contentToRender}
        </>
    );
}

export default Notifications;