import React, { useState, useContext } from 'react';
import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Nav, NavbarText
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { AuthContext } from '../context/AuthContext';
import { Logo } from "./_general/Logo";

import UserIcon from './UserIcon';
import Clock from './Clock';
import AppContext from '../context/AppContext';
import IntegrationsApi from '../api/IntegrationsApi';
import Loader from './Loader';
import useLoader from '../util/useLoader';


function NavMenu({ logoOnly }) {
    const auth = useContext(AuthContext);
    const user = auth?.user?.user;
    const permissions = ((auth || {}).user || {}).permissions || {};
    const [collapsed, setCollapsed] = useState(true);
    function toggleNavbar() {
        setCollapsed(!collapsed);
    }
    const { navbarContent } = useContext(AppContext);

    const manageAnything = permissions.ManageUsersInUnit || permissions.ManageOrganizationalUnits || permissions.ConfigureOrganizationalUnits || permissions.ConfigureUsersInUnit;
    const notificationCount = 3;
    // console.log("manageAnything", manageAnything, permissions);

    return (
        <header><Navbar
            className="navbar-toggleable-sm mb-3"
            fixed="top"
            expand="sm"
        >
            <NavbarBrand tag={Link} to="/"> <Logo width={57} height={58} /></NavbarBrand>
            {logoOnly ? null : (<>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse isOpen={!collapsed} navbar>
                <Nav className="navbar-nav">
                    <NavbarText><Clock className="nav-clock" /></NavbarText>
                    {navbarContent || null}
                </Nav>
                <Nav className="navbar-nav">
                    {auth.user ? (<NavbarText>
                        {/* <NavLink tag={Link} to="/notifications" >
                            <span className="badged-icon nav-icon">
                                <i className="fa fa-bell" />
                                {notificationCount > 0 ? <span className="icon-badge bg-primary">{notificationCount}</span> : null}
                            </span>
                        </NavLink> */}
                    </NavbarText>): null}
                    {(user?.jediId && user?.isDual) ? (<NavbarText>
                        <GoToJediButton />
                    </NavbarText>): null}
                    {manageAnything ? (<NavbarText>
                        <NavLink tag={Link} to="/system-settings" ><i className="fa fa-cog nav-icon" /></NavLink>
                    </NavbarText>): null}
                    {auth.user ? (<NavbarText className="d-flex align-items-center">
                        <NavLink tag={Link} to="/support"><span className="navbar-help-text">Ayuda</span></NavLink>
                    </NavbarText>) : null}
                    {auth.user ? (<NavItem><UncontrolledDropdown className="user-nav-dropdown">
                        <DropdownToggle caret nav>
                            <i className="fa fa-user nav-icon" />
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem className="user-info d-flex align-items-center" tag={Link} to="/profile" >
                                <UserDataPane icon={auth?.user?.actualUser ? "user-secret" : ""} user={auth.user} />
                            </DropdownItem>
                            {auth?.user?.actualUser ? (<>
                                <hr className="dropdown-divider" />
                                <DropdownItem className="user-info d-flex align-items-center" >
                                    <UserDataPane icon="user" user={auth.user.actualUser} />
                                </DropdownItem>
                                <hr className="dropdown-divider" />
                                <DropdownItem className="d-flex align-items-center" tag={Link} to="/" onClick={() => auth.stopImpersonation()}>
                                    Dejar de Impersonar
                                </DropdownItem>
                            </>) : null}
                            <hr className="dropdown-divider" />
                            <DropdownItem className="d-flex align-items-center" tag={Link} to="/" onClick={() => auth.logout()}>
                                Log Out
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown></NavItem>) : null}
                </Nav>
            </Collapse>
            </>)}
        </Navbar></header>
    );
}


function UserDataPane({ user, icon }){
    const {
        firstName,
        lastName,
        organizationalUnit,
        accessProfile,
        roles,
    } = user?.user || {};

    return (<div>
        {icon ? <i className={`fa fa-${icon}`} /> : null}
        <div className="user">
            {` ${firstName || ""}  ${lastName || ""}`}
        </div>
        {organizationalUnit ? (
            <div className="organization">{organizationalUnit?.code}</div>
        ): null}
        {accessProfile ? (
            <div className="role">{accessProfile?.displayName || "----"}</div>
        ) : null}
        {(roles || []).map((ur, index) => (
            <div key={index} className="role">{ur?.role?.name}</div>
        ))}
    </div>);
}


function GoToJediButton(){
    const [loading, errorLoading, loadFn] = useLoader();

    function goToJedi(e){
        e.preventDefault();
        loadFn(() => IntegrationsApi.GetOperationUrl('jedi/jump-to-jedi/login').then((url) => {
            if (url) {
                window.open(url, '_blank');
            }
        }));
    }

    return (
        <Button color="clear" onClick={goToJedi} >{loading ? <Loader /> : "J.E.D.I."}</Button>
    );
}


export default NavMenu;