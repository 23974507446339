import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useSideChannelSubscription } from '../util/useSideChannel';


function RequisitionClientTabs({
    canAddClients,
    selectedIndex,
    setSelectedIndex,
    addNewClient,
    clientErrors,
    sideChannel
}) {
    const formObject = useSideChannelSubscription(sideChannel, 1);

    let clients = (formObject || {}).clients || [];
    if (clients.length === 0) { clients = [{}] };
    const selectedClient = clients[selectedIndex] || clients[0];
    const isJointly = (formObject || {}).isJointly;
    clientErrors = clientErrors || [];

    if (!isJointly) return null;

    return (<Nav tabs className="form-clients-tabs">
        {clients.map((client, index) => {
            const name = client.retailClientBasicInfo ? (
                `${client.retailClientBasicInfo.firstName || ''} ${client.retailClientBasicInfo.lastName || ''}`
            ) : client.retailClientBasicInfo && (
                `${client.businessClientBasicInfo.legalRepresentativeFirstName || ''} ${client.businessClientBasicInfo.legalRepresentativeLastName || ''}`
            );
            return (
                <NavItem key={index}>
                    <NavLink active={selectedClient === client} onClick={() => setSelectedIndex(index)}>
                        {name || (index === 0 ? "Cliente Titular" : "Cliente Secundario")}
                        {clientErrors[index] ? (<span className="text-danger"><i className="fa fa-exclamation-triangle" /></span>) : null}
                    </NavLink>
                </NavItem>
            );
        })}
        {canAddClients ? (<NavItem className="add-button" onClick={() => addNewClient()}>
            <NavLink><i className="fa fa-plus" /></NavLink>
        </NavItem>) : null}
    </Nav>);
}


export default RequisitionClientTabs;