import React from "react";
import useSearchParams from "../../../util/useSearchParams";
import EditRole from "./EditRole";
import ListRoles from "./ListRoles";


function Roles() {
    const [
        [role, setRole]
    ] = useSearchParams({
        role: undefined
    });

    return role ? (
        <EditRole roleId={role} setRole={setRole} />
    ) : (
        <ListRoles setRole={setRole} />
    );
}

export default Roles;