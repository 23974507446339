import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Input } from "reactstrap";
import JsonComponent from "../../FormBuilder/JsonComponent";


const PATTERN = { type: "json" };

function JsonField({ fieldProps: prop, value, onChange }) {
    const { label } = prop;
    const json = useMemo(() => JSON.stringify(value), [value]);

    return (
        <>
            <label className="title">{label}</label>
            <JsonComponent value={value} onChange={onChange} />
        </>)
}



JsonField.pattern = PATTERN;


export default JsonField;