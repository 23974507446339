import React from 'react';
import { Container } from 'reactstrap';
import NavMenu from '../NavMenu';


function ExternalLayout({ children }) {
    const versionTag = window.APP_VERSION;
    return (<div className="app-layout">
        <NavMenu logoOnly />
        <Container className="main-container" fluid>
            {children}
        </Container>
        {versionTag ? (<div className="version-tag">{versionTag}</div>) : null}
    </div>);
}


export default ExternalLayout;