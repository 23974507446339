import { useEffect, useMemo, useState } from "react";
import IconButton from "@rjsf/core/lib/components/IconButton";
import getPathFromId from "../../util/getPathFromId";
import reactTraverse from "../../util/reactTraverse";
import { useJnx } from "../../util/jnx";
import { useSideChannelSubscription } from '../../util/useSideChannel';

const ADDITIONAL_PROPERTY_FLAG = "__additional_property";
const REQUIRED_FIELD_SYMBOL = "*";


function SchemaFieldTemplate(props) {
    const {
        children,
        classNames,
        description,
        disabled,
        displayLabel,
        errors,
        formContext,
        help,
        rawErrors,
        hidden,
        id,
        label,
        onDropPropertyClick,
        onKeyChange,
        readonly,
        required: propRequired,
        schema,
        uiSchema,
    } = props;

    const {
        setFormDataValues,
        sideChannel,
        onLocateField,
        bindings: fcBindings,
    } = formContext;

    useEffect(() => {
        const visibility = formContext?.formFields?.current;
        const path = getPathFromId(id);

        if (visibility && path) {
            visibility[path] = true;

            return () => {
                visibility[path] = false;
            };
        }
    }, [id, formContext]);

    const {
        'akc:requiredIfVisible': requiredIfVisible,
        'ui:labelSuffix': labelSuffix,
        'ui:labelFontSize': labelFontSize,
        'ui:titleExpr': titleExpr,
        'ui:onChange': onChangeExpr,
    } = uiSchema;

    const required = requiredIfVisible || propRequired;

    if (hidden) {
        return <div className="hidden">{children}</div>;
    }

    const path = useMemo(() => getPathFromId(id), [id]);
    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}];

    const bindings = useMemo(() => ({
        ...fcBindings,
        rootFormData,
        formObject,
        formContext,
    }), [rootFormData, formObject, fcBindings, formContext]);

    const titleJnx = useJnx(titleExpr);

    const text = useMemo(() => {
        return (
            (titleJnx?.eval(rootFormData, path, bindings) || label)?.trim()
        );
    }, [
        label,
        titleJnx, path, rootFormData, bindings
    ]);

    const functionBinds = useMemo(() => ({ set: setFormDataValues }), [setFormDataValues]);
    const onChangeJnx = useJnx(onChangeExpr, { functionBinds });

    useEffect(() => {
        if (onChangeJnx != null) {
            onChangeJnx.eval(rootFormData, path, bindings);
        }
    }, [rootFormData, id, onChangeExpr, onChangeJnx])

    const field = (<>
        {displayLabel && <>
            <Label label={text} labelFontSize={labelFontSize} suffix={labelSuffix} required={required} id={id} />
            {schema?.description ? description : null}
        </>}
        {children}
        {rawErrors?.length ? errors : null}
        {schema?.help ? help : null}
    </>);



    const [isHighlighthed, setHighlighthed] = useState(0);
    const hasAdditional = !!schema[ADDITIONAL_PROPERTY_FLAG];
    const fieldLocator = useMemo(() => {
        return onLocateField ? ({
            onMouseEnter(e) {
                setHighlighthed(h => h + 1);
            },
            onMouseLeave(e) {
                setHighlighthed(h => h - 1);
            },
            onClick(e) {
                const topmostSftNode = reactTraverse(e.target).filter(fn => fn.elementType?.name === SchemaFieldTemplate.name).pop();
                onLocateField(topmostSftNode.memoizedProps.id);
            }
        }) : ({})
    }, [onLocateField, setHighlighthed]);
    useEffect(() => {
        if(!onLocateField) setHighlighthed(0);
    }, [onLocateField])

    return <div
        className={`${classNames || ''} ${(isHighlighthed > 0) ? 'is-highligthed' : ''}`}
        {...fieldLocator}
    >{hasAdditional ? (<div className="row">
        <div className="col-xs-5 form-additional"><FieldKeyLabel {...props} /></div>
        <div className="form-additional form-group col-xs-5">{field}</div>
        <div className="col-xs-2"><IconButton
            type="danger" icon="remove"
            className="array-item-remove btn-block"
            tabIndex="-1"
            style={{ border: "0" }}
            disabled={disabled || readonly}
            onClick={onDropPropertyClick(label)}
        /></div>
    </div>) : (
        field
    )}</div>;
}


function FieldKeyLabel({ id, label, onKeyChange, required, labelFontSize }) {
    const keyLabel = `${label} Key`; // i18n ?
    const keyId = `${id}-key`;
    return (<div className="form-group">
        <Label labelFontSize label={keyLabel} required={required} id={keyId} data-cy={keyId} />
        <LabelInput label={label} required={required} id={keyId} data-cy={keyId} onChange={onKeyChange} />
    </div>);
}


function Label({ label, suffix, required, id, labelFontSize }) {
    return label ? (
        <label className={`control-label ${labelFontSize}`} data-cy={`${id}_label`} htmlFor={id}>
            {label}{suffix || null}
            {required && <span className="required">{REQUIRED_FIELD_SYMBOL}</span>}
        </label>
    ) : null;
}


function LabelInput({ id, label, onChange }) {
    return (<input
        className="form-control" type="text"
        data-cy={id}
        id={id} onBlur={event => onChange(event.target.value)}
        defaultValue={label}
    />);
}


export default SchemaFieldTemplate;