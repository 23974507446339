import React, { useState, useMemo, useRef, useContext } from "react";
import {
    FormGroup,
    Table
} from "reactstrap";
import jsonata from "jsonata";
import { getObject, concatenatePaths } from "../../util/mapObject";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import xor from "../../util/xor";
import Jnx from "../../util/jnx";
import dfsSearch from "../../util/dfsSearch";
import { TitleField } from "./ObjectFieldTemplate";
import getPathFromId from "../../util/getPathFromId";

function HideObjectField(props) {
    const {
        formContext,
        idPrefix, idSeparator,
        registry,
        disabled, readonly,
        hideError, formData: propFormData,
        onBlur, onFocus,
        onChange: propOnChange,
        uiSchema, errorSchema, idSchema: propIdSchema, schema,
    } = props;
    const { sideChannel, setFormDataValues } = formContext;
    const {
        title,
        properties:
        childFields,
        'map:formField': formField } = schema;
    const { fields: { SchemaField } } = registry;
    const {
        'ui:className': className,
        'ui:newSection': uiNewSection,
        'ui:expandable': expandable,
        'ui:expandedDefault': expandedDefault = true,
        'ui:gridColumnGap': gridColumnGap,
        'ui:hideLegend': hideLegend = false
    } = uiSchema;

    const [wasPropertyKeyModified, setWasPropertyKeyModified] = useState();

    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}];

    const [formData, onChange, idSchema] = useRelativeFormData({ propIdSchema, formField, propFormData, propOnChange, rootFormData, setFormDataValues });

    const { $id } = idSchema;

    const formDataRef = useRef();
    formDataRef.current = formData;

    function onKeyChange(oldValue, value, errorSchema) {
        if (oldValue === value) {
            return;
        }

        value = getAvailableKey(value, formData);
        const _formData = formDataRef.current || {};
        const newKeys = { [oldValue]: value };
        const renamed = Object.keys(_formData).reduce((_, key) => {
            _[newKeys[key] || key] = _formData[key];
            return _;
        }, {});

        setWasPropertyKeyModified(true);

        onChange(
            renamed,
            errorSchema &&
            props.errorSchema &&
            { ...props.errorSchema, [value]: errorSchema }
        );
    }

    function onDropPropertyClick(key) {
        return (event) => {
            event.preventDefault();
            const copiedFormData = { ...formDataRef.current };
            delete copiedFormData[key];
            onChange(copiedFormData);
        };
    }

    function onPropertyChange(name, value, propErrorSchema) {
        const newFormData = { ...formDataRef.current };
        newFormData[name] = value;
        onChange(
            newFormData,
            propErrorSchema && errorSchema &&
            { ...errorSchema, [name]: propErrorSchema }
        );
    }

    const route = useMemo(() => getPathFromId($id), [$id]);

    const bindings = useMemo(() => ({
        formContext,
        formObject,
        rootFormData
    }), [
        formContext,
        formObject,
        rootFormData
    ]);

    const [visibleCount, hide] = useMemo(() => {

        const {
            showCount,
            nonEmptyCount,
            emptyChecks,
            aloneChecks
        } = Object.keys(childFields).reduce((_, name) => {

            const childUISchema = uiSchema[name] || {};
            const [
                hideAttr, inverLogic
            ] = childUISchema['akc:hideAttr'] ? [childUISchema['akc:hideAttr'], false] : [childUISchema['akc:showAttr'], true];

            if (childUISchema["ui:showIf"]) {
                const response = evalShowIf(rootFormData, route, childUISchema["ui:showIf"], bindings);
                if (response) {
                    _.showCount += 1;
                } else {
                    _.emptyChecks[name] = 1;
                }
            } else {
                _.showCount += 1;
            }

            // if (hideAttr === 'ifAlone') {
            //     _.aloneChecks.push([name, inverLogic]);
            // } else if (hideAttr && hideAttr.ifEmpty) {
            //     const isEmpty = xor(!getObject(rootFormData, hideAttr.ifEmpty), inverLogic);
            //     _.emptyChecks[name] = isEmpty;
            //     _.nonEmptyCount += !isEmpty;
            //     _.showCount += !isEmpty;
            // } else if (hideAttr && hideAttr.requisitionData) {
            //     const hide = xor(formObject && formObject.id ? hideFieldBaseOnData(hideAttr.requisitionData, formObject) : true, inverLogic);
            //     _.emptyChecks[name] = hide;
            //     _.nonEmptyCount += !hide;
            //     _.showCount += !hide;
            // } else if (hideAttr && hideAttr.formData) {
            //     const hide = xor(rootFormData ? hideFieldBaseOnData(hideAttr.formData, rootFormData): true, inverLogic);
            //     _.emptyChecks[name] = hide;
            //     _.nonEmptyCount += !hide;
            //     _.showCount += !hide;
            // } else if (hideAttr && hideAttr.formContext) {
            //     const hide = xor(rootFormData ? hideFieldBaseOnData(hideAttr.formContext, formContext): true, inverLogic);
            //     _.emptyChecks[name] = hide;
            //     _.nonEmptyCount += !hide;
            //     _.showCount += !hide;
            // } else if (childUISchema['ui:hideOnEmpty'] && !(formData || {})[name]) {
            //     _.emptyChecks[name] = true;
            // } else if (hideAttr === 'hide') {
            //     _.emptyChecks[name] = true;
            // } else {
            //     _.showCount += 1;
            // }

            return _;
        }, { showCount: 0, nonEmptyCount: 0, emptyChecks: {}, aloneChecks: [] });

        const isEmpty = (nonEmptyCount === 0);

        return [
            showCount,
            {
                ...emptyChecks,
                ...aloneChecks.reduce((_, [c, inverLogic]) => { _[c] = xor(isEmpty, inverLogic); return _; }, {}),
            }
        ]
    }, [childFields, rootFormData, uiSchema, bindings]);

    const [expanded, setExpanded] = useState(expandedDefault);
    function toggleExpand(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setExpanded(!expanded);
    }

    return visibleCount === 0 ? null : (
        <FormGroup className={className}>
            <TitleField
                id={$id}
                isSection={uiNewSection}
                title={title}
                bindings={bindings}
                expandable={expandable}
                expanded={expanded}
                hideLegend={hideLegend}
                toggleExpand={toggleExpand}
            />
            {expanded || !expandable ? (<div className={`${gridColumnGap || ''} prop-fields`}>
                {Object.entries(childFields).map(([name, fieldDef]) => hide[name] ? null : (
                    <SchemaField
                        key={name}
                        name={name}
                        required={isRequired(name, schema, hide[name], uiSchema)}
                        schema={fieldDef}
                        uiSchema={uiSchema[name]}
                        errorSchema={errorSchema[name]}
                        idSchema={idSchema[name]}
                        idPrefix={idPrefix}
                        formContext={formContext}
                        idSeparator={idSeparator}
                        formData={(formData || {})[name]}
                        wasPropertyKeyModified={wasPropertyKeyModified}
                        onKeyChange={(value, errorSchema) => onKeyChange(name, value, errorSchema)}
                        onChange={(value, errorSchema) => onPropertyChange(name, value, errorSchema)}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        registry={registry}
                        disabled={disabled}
                        readonly={readonly}
                        hideError={hideError}
                        onDropPropertyClick={onDropPropertyClick}
                    />
                ))}
            </div>) : null}
        </FormGroup>
    );
}

function useRelativeFormData({ propIdSchema, formField, propFormData, propOnChange, rootFormData, setFormDataValues }) {
    const path = useMemo(() => (getPathFromId(propIdSchema.$id)), [propIdSchema.$id]);
    const formFieldPath = useMemo(() => !formField ? path : (concatenatePaths(path, formField)), [path, formField]);
    const formData = useMemo(() => (path === formFieldPath ? propFormData : getObject(rootFormData, formFieldPath)), [path, formFieldPath, rootFormData, propFormData]);
    const formDataRef = useRef();
    formDataRef.current = formData;
    const onChange = useMemo(() => (path === formFieldPath ? propOnChange : (newFormData) => {
        setFormDataValues({ [formFieldPath]: newFormData });
    }), [propOnChange, setFormDataValues]);
    const idSchema = useMemo(() => {
        if (path === formFieldPath) {
            return propIdSchema;
        }
        const idPrefix = getIdPrefix(propIdSchema.$id);
        const newId = getIdFromPath(formFieldPath, idPrefix);
        const idSchema = {};
        dfsSearch([[idSchema, propIdSchema, newId]], ([resultSchema, currentSchema, $id], scope) => {
            resultSchema.$id = $id;
            Object.entries(currentSchema).forEach(([name, child]) => {
                if (name !== "$id") {
                    const childId = `${$id}_${name}`;
                    const childResult = {};
                    resultSchema[name] = childResult;
                    scope.stack.push([childResult, child, childId]);
                }
            });
        });
        return idSchema;
    }, [propIdSchema, formFieldPath]);

    return [formData, onChange, idSchema];
}


function hideFieldBaseOnData(attr, data) {
    if (!data) return true;
    const conditions = Object.entries(attr).map(([attrName, attrVal]) => {
        const fieldValue = getObject(data, attrName);

        if (Array.isArray(fieldValue)) {
            if ((attrVal || {}).intersects) {
                return attrVal.intersects.some(val => fieldValue.indexOf(val) !== -1);
            }

            if (attrName === 'productInstitutions') {
                return !(fieldValue.some(x => x === attrVal));
            }

            return fieldValue.some(x => x === attrVal);

        } else if (Array.isArray(attrVal)) {
            return attrVal.some(x => x === fieldValue);

        } else if (fieldValue !== undefined) {
            return fieldValue === attrVal;
        } else {
            return false;
        }
    });

    return conditions.some(cond => cond);
}

export function getIdPrefix(id) {
    return id?.split('_')[0];
}

export function getIdFromPath(path, idPrefix) {
    return `${idPrefix}_${path.split('.').join('_')}`;
}

export function compileShowIf(objectShowIf) {
    return (objectShowIf instanceof Jnx) ? objectShowIf : new Jnx(objectShowIf);
}

export function evalShowIf(formData, route, objectShowIf, bindings) {
    return compileShowIf(objectShowIf).eval(formData, route, bindings);
}


export function getAvailableKey(preferredKey, formData) {
    let index = 0;
    let newKey = preferredKey;

    while (Object.prototype.hasOwnProperty.call(formData, newKey)) {
        index += 1;
        newKey = "".concat(preferredKey, "-").concat(index);
    }

    return newKey;
}


export function isRequired(name, schema, hideField, uiSchema) {
    const childUISchema = uiSchema[name] || {};
    return (Array.isArray(schema.required) && schema.required.indexOf(name) !== -1 && !hideField) || (childUISchema['akc:required'] && !hideField);
}


export default HideObjectField;