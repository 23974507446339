import { useState } from "react";
import { Redirect } from 'react-router';
import { Button } from "reactstrap";
import safeJsonParse from "../util/safeJson";
import CatalogModal from "./modals/CatalogModal";

function CatalogRequisitionDropdown({ unitOrgWorkflows, SelectWorkflowBeforeCreate }) {

    const [contract, setContract] = useState(false);
    const [modal, setModal] = useState(false);
    const [getCart, setGetCart] = useState(false);
    const [wf, setWf] = useState(null);
    const [showWF, setShowWorkflow] = useState(null);

    const toggle = () => {

        // clean local storage when open and close
        const lsContract = localStorage.getItem('contract');
        if (lsContract) {
            localStorage.removeItem('contract');
        }

        const lsItemProduct = localStorage.getItem('products');
        if (lsItemProduct) {
            localStorage.removeItem('products');
        }

        setModal(!modal)
    };

    const openModal = () => {
        toggle();
    };

    if (getCart && safeJsonParse(getCart)?.length > 0 && contract) {
        window.localStorage.removeItem('contract');
        window.localStorage.removeItem('products');

        return <Redirect
            to={{
                pathname: `/workflow/requisitions/new`,
                search: '?type=contract',
                state: { cart: getCart, contractId: contract },
                workflow: wf,
                showWorkflow: showWF
            }}
        />
    } else if (getCart && safeJsonParse(getCart)?.length > 0 && !contract) {
        window.localStorage.removeItem('products');

        return <Redirect
            to={{
                pathname: `/workflow/requisitions/new`,
                search: '?type=contract',
                state: { cart: getCart },
                workflow: wf,
                showWorkflow: showWF
            }}
        />
    }

    return (<>
        <Button color="primary" data-cy="button-create-requisition-contrato" onClick={() => openModal()}>Contrato</Button>
        {modal ? <CatalogModal SelectWorkflowBeforeCreate={SelectWorkflowBeforeCreate} contractId={contract} isOpen={modal} onCancel={toggle} onConfirm={({ cart, contract, workflow, show }) => { setWf(workflow); setShowWorkflow(show); setGetCart(JSON.stringify(cart)); setContract(contract); }} /> : null}
    </>);
}


export default CatalogRequisitionDropdown;