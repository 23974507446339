import jsonata from "jsonata";
import { useEffect, useMemo } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { concatenatePaths, getObject, mapObject } from "../../util/mapObject";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import applyFormatting, { useAppliedFormatter } from "../../util/applyFormatting";
import { useJnx } from "../../util/jnx";
import getPathFromId from "../../util/getPathFromId";


function ComputedField(props) {
    const {
        formContext,
        name,
        schema,
        onChange,
        idSchema: { $id },
        formData: formDataFieldValue,
    } = props;

    const {
        title,
        references,
        format,
        jsonata: jsonataComputation,
        computation,
        scope: schemaScope = '..',
        onUpdate,
        hidden
    } = schema;

    const {
        sideChannel,
        bindings: fcBindings,
        setFormDataValues,
    } = formContext;

    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1, 2, 3, 4]) || [{}, {}];

    const bindings = useMemo(() => ({
        ...fcBindings,
        formObject,
        formContext,
    }), [formObject, fcBindings, formContext]);

    const path = useMemo(() => getPathFromId($id), [$id]);
    const valueJnx = useJnx(jsonataComputation, { defaultScope: schemaScope });
    const functionBinds = useMemo(() => ({
        set: setFormDataValues,
        log: (...args) => console.log("jsonata console log\n", ...args)
    }), [setFormDataValues]);
    const onUpdateJnx = useJnx(onUpdate, { functionBinds });

    useEffect(() => {
        if (valueJnx) {
            try {
                const refValue = valueJnx.eval(rootFormData, path, bindings);
                if (refValue !== formDataFieldValue) {
                    onChange(refValue);
                }
            } catch (e) {
                console.log("Computed Field error");
                console.error(e);
            }
        }
    }, [valueJnx, rootFormData, path, formDataFieldValue]);

    useEffect(() => {
        if (onUpdateJnx) {
            onUpdateJnx.evalAsync(rootFormData, path, { ...bindings, value: formDataFieldValue });
        }
    }, [onUpdateJnx, formDataFieldValue]);


    const text = useAppliedFormatter(formDataFieldValue, format);

    return hidden ? null : (<FormGroup disabled>
        {title !== " " ? <label className="control-label" htmlFor="root_preferredBranchId">
            {title}
        </label> : null}
        <Input
            disabled
            data-cy={$id}
            type="text"
            className="custom"
            value={text || ""}
            readOnly
        />
    </FormGroup>);
}


export default ComputedField;