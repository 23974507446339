import React from "react";
import {TitleField} from "../ObjectFieldTemplate";


function ArrayFieldTitle({
    expandable,
    toggleExpand,
    expanded,
    idSchema,
    title,
    required,
}) {
    if (!title) {
        return null;
    }
    return (<TitleField
        $id={idSchema.$id}
        title={title}
        expandable={expandable}
        expanded={expanded}
        required={required}
        toggleExpand={toggleExpand}
    />);

}


export default ArrayFieldTitle;