
export function isRequired(name, schema) {
    return Array.isArray(schema.required) && schema.required.indexOf(name) !== -1;
}

export function getAvailableKey(preferredKey, formData) {
    let index = 0;
    let newKey = preferredKey;

    while (Object.prototype.hasOwnProperty.call(formData, newKey)) {
        index += 1;
        newKey = "".concat(preferredKey, "-").concat(index);
    }

    return newKey;
}
