import React, { useContext } from 'react';
import Context2D from "./Context";


function ScalableMovableSVG({
    className,
    onWheel,
    onMouseMove,
    onMouseDown,
    onRightClick,
    children,
}) {
    const { width, height, center, zoom: propZoom } = useContext(Context2D);
    const zoom = propZoom || 1;
    const w2 = width / 2;
    const h2 = height / 2;
    const [cx, cy] = center || [w2, h2];
    const dx = w2 - cx * zoom;
    const dy = h2 - cy * zoom;
    const onContextMenu = onRightClick ? (event => {
        event.preventDefault();
        onRightClick(event); 
    }) : undefined;

    return (<svg
        className={className}
        onContextMenu={onContextMenu}
        width={width}
        height={height}
        onWheel={onWheel}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
    ><g transform={`translate(${dx} ${dy}) scale(${zoom} ${zoom})`}>
        <g>{children}</g>
    </g></svg>);
}

export default ScalableMovableSVG;
