import React, { useEffect, useState } from 'react';
import InternalLayout from '../../components/layouts/InternalLayout';
import useTitle from "../../util/useTitle";

export default function UserAccessForm(props) {
    useTitle("User Access");
    return (
        <InternalLayout>
            <iframe 
                name="ASG584FORM Web Form"
                title="ASG584FORM Web Form"
                height="1900px"
                width="100%"
                scrolling="no"
                src="https://c1hck453.caspio.com/dp/3a9eb0004a85e45facf340759576"
                >
                Sorry, but your browser does not support frames.
            </iframe>
        </InternalLayout>
    );
}