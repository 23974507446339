
export const STATUS = {
    NOTFOUND: 'notfound',
    PROCESSING: 'processing',
    ERROR: 'error',
    AVAILABLE: 'available',
};


export default function checkDocumentResponse(response) {
    const checkResult = {
        items: response?.correspondence || [],
        readyCount: 0,
        totalCount: response?.correspondence?.length || 0,
        status: null,
    };

    const {
        apiStatus,
        statusCode,
        correspondence,
    } = response;

    if (apiStatus !== API_STATUS.OK) {
        checkResult.status = STATUS.NOTFOUND;
    // Check to see if it's still being processed...
    } else if (statusCode === STATUS_CODE.SUBMITTED || statusCode === STATUS_CODE.MATCH) {
        checkResult.status = STATUS.PROCESSING;
    // If any of the documents were finished, attempt to find by templateName
    } else if (statusCode === STATUS_CODE.FINISH || statusCode === STATUS_CODE.FINISHPART) {
        let pendingCount = 0;
        let errorCount = 0;

        correspondence.forEach((file, fileIdx) => {
            if (file.statusCode === FILE_STATUS_CODE.PEND) {
                file.pending = true;
                pendingCount += 1;
            } else if (file.statusCode !== FILE_STATUS_CODE.GEN) {
                file.error = true;
                errorCount += 1;
            } else {
                file.ready = true;
            }
        });

        if (pendingCount > 0) {
            checkResult.status = STATUS.PROCESSING;
        } else if (errorCount > 0) {
            checkResult.status = STATUS.ERROR;
        } else {
            checkResult.status = STATUS.AVAILABLE;
        }
    } else if (statusCode === STATUS_CODE.NOMATCH) {
        checkResult.status = STATUS.AVAILABLE; // no documents matched in spondyr
    } else {
        checkResult.status = STATUS.ERROR; // An error or something else occurred preventing file from being generated
    }

    return checkResult;
}


const API_STATUS = [
    "OK"
].reduce((_, x) => {_[x] = x; return _;}, {});

const STATUS_CODE = [
    "SUBMITTED", "MATCH", "FINISH", "FINISHPART", "NOMATCH",
].reduce((_, x) => {_[x] = x; return _;}, {});

const FILE_STATUS_CODE = [
    "PEND", "GEN",
].reduce((_, x) => {_[x] = x; return _;}, {});
