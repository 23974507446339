import React from 'react';
import { Input } from 'reactstrap';


const PATTERN = { type: "string" };


function StringField(props) {
    const { fieldProps, value, onChange } = props;
    const {
        label,
        isNumber,
        property,
        format,
        default: defaultValue,
    } = fieldProps || props;

    return (<>
        {label ? <label className="title">{label}</label> : null}
        <Input type={isNumber ? 'number' : (format === 'textarea' ? 'textarea' : 'string')} className="form-control"
            value={value || defaultValue || ""}
            name={property}
            onChange={({ target: { value } }) => onChange(isNumber ? parseInt(value, 10) : value)}
        />
    </>);
}



StringField.pattern = PATTERN;


export default StringField;