import React, { useMemo } from "react";


function Arrow({
    path: propPath,
    text,
    loopingTest,
    fromSize,
    shrinkFrom, shrinkTo, minLength,
}){
    if (!propPath) return null;

    let retValue = null;

    const path = useMemo(() => loopingTest ? propPath : shrinkLine(propPath, {
        start: shrinkFrom,
        end: shrinkTo,
        minLength
    }), [propPath, loopingTest, shrinkFrom, shrinkTo, minLength]);

    const [x1, y1] = path[0];

    const pathstring = useMemo(() => path.map(([x, y], idx) => (
        idx ? `L ${x} ${y}` : `M ${x} ${y}`
    )).join(' '), [path]);
    
    const pathstringLoop = useMemo(() => path.map(([x, y], idx) => (
        idx ? `q 0 -150 ${-fromSize?.width/1.5 | 0} 0` : `M ${x} ${y}`
    )).join(' '), [path]);

    let stroke = "";
    if(text === 'sendBack'){
        stroke = "5, 5"
    }

    if(loopingTest){
        retValue = <path d={pathstringLoop} markerEnd="url(#arrowhead)" strokeDasharray={stroke} fill="none"/>
    }
    else{
        retValue = <path d={pathstring} markerEnd="url(#arrowhead)" strokeDasharray={stroke}/>
    }
    

    return Number.isFinite(x1) && Number.isFinite(y1) ? <>
        <circle cx={x1} cy={y1} r={2} />
        {retValue}
    </> : null;
}



function shrinkLine(line, options) {
    const {
        start = 5,
        end = 5,
        minLength = 5
    } = options || {};
    const minHalfLength = Math.max(1, minLength / 2);
    const [[x1, y1], [x2, y2]] = line;
    const [dx, dy] = [x2 - x1, y2 - y1];
    const d = Math.sqrt(dx * dx + dy * dy);
    const d2 = d / 2;

    if (d < .001) return line;
    const [dxHat, dyHat] = [dx / d, dy / d];

    const [mx, my] = [(x1 + x2) / 2, (y1 + y2) / 2];
    const [dstart, dend] = [Math.max(d2 - start, minHalfLength), Math.max(d2 - end, minHalfLength)];

    const newline = [
        [mx - dxHat * dstart, my - dyHat * dstart],
        [mx + dxHat * dend, my + dyHat * dend],
    ];

    return newline;
}


export default Arrow;