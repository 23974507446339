import { useState } from "react";
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import CatalogModal from "../../blc/modals/CatalogModal";

function HomePanel() {

    const [CategoryID, setCategoryID] = useState(false);
    const [modal, setModal] = useState(false);
    const [getCart, setGetCart] = useState(false);

    // CATEGORIES
    const ALIMENTOS = 11;
    const COMBUSTIBLES = 12;
    const EQUIPOS = 13;
    const MATERIALES = 14;
    const SERVICIOS = 15;
    const BATERIAS = 19;

    const toggle = () => {
        setModal(!modal)
    };

    const openModal = (CategoryID) => {
        const lsCategory = localStorage.getItem('products');
        if (lsCategory) {
            localStorage.removeItem('products');
        }
        setCategoryID(`&category=${CategoryID}`);
        toggle();
    };

    if (getCart && JSON.parse(getCart).length > 0) {
        return <Redirect
            to={{
                pathname: "./workflow/requisitions/new",
                state: { cart: getCart, categoryId: CategoryID }
            }}
        />
    }

    // function bindEvent(element, eventName, eventHandler) {
    //     if (element.addEventListener) {
    //         element.addEventListener(eventName, eventHandler, false);
    //     } else if (element.attachEvent) {
    //         element.attachEvent(`on${eventName}`, eventHandler);

    //     }
    // }

    // bindEvent(window, 'message', (e) => {
    //     if (e.data && e.data.length > 0) {
    //         setGetCart(e.data);
    //     }
    // });

    return (<>
        <UncontrolledDropdown className="new-req-dropdown">
            <DropdownToggle caret color="primary">
                Requisicion Nueva
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem className="user-info d-flex align-items-center" onClick={() => openModal(ALIMENTOS)}>
                    Alimentos
                </DropdownItem>
                <DropdownItem className="user-info d-flex align-items-center" onClick={() => openModal(BATERIAS)}>
                    Baterías
                </DropdownItem>
                <DropdownItem className="user-info d-flex align-items-center" onClick={() => openModal(COMBUSTIBLES)}>
                    Combustibles
                </DropdownItem>
                <DropdownItem className="user-info d-flex align-items-center" onClick={() => openModal(EQUIPOS)}>
                    Equipos
                </DropdownItem>
                <DropdownItem className="user-info d-flex align-items-center" onClick={() => openModal(MATERIALES)}>
                    Materiales
                </DropdownItem>
                <DropdownItem className="user-info d-flex align-items-center" onClick={() => openModal(SERVICIOS)}>
                    Servicios
                </DropdownItem>
                {/* <DropdownItem className="user-info d-flex align-items-center" onClick={() => openModal(VEH)}>
                    Vehiculos
                </DropdownItem> */}
                <DropdownItem className="user-info d-flex align-items-center" tag={Link} to="/workflow/requisitions/new?type=informal" >
                    Informal
                </DropdownItem>
                <DropdownItem className="user-info d-flex align-items-center" tag={Link} to="/workflow/requisitions/new?type=exceptional" >
                    Excepcional
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
        <SidebarStatusButton
            icon="fa fa-inbox"
            status1={2}
            status2={2}
            to="/"
        >Revisar</SidebarStatusButton>
        <SidebarStatusButton
            icon="icon icon-draft"
            status1={2}
            status2={2}
        >Borrador</SidebarStatusButton>
        <SidebarStatusButton
            icon="fa fa-envelope"
            status1={2}
            status2={2}
        >Enviados</SidebarStatusButton>

        <CatalogModal CategoryID={CategoryID} isOpen={modal} onCancel={toggle} onConfirm={setGetCart} />

            {/* <Modal isOpen={modal}
            toggle={toggle}
            size='xl'
            backdrop='static'
        >
            <ModalBody style={{ padding: 0 }}>
                <iframe title="catalog" src={SOURCE + CategoryID} height={530} width='100%' style={{ border: 0 }} />
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggle} variant="secondary">Cerrar</Button>
            </ModalFooter>
        </Modal> */}

        </>);
}


        function SidebarStatusButton({
            icon, status1, status2,
            color = "primary",
            children,
            to
        }) {
    return <div className="sidebar-status-icon">
            <i className={icon} />
            <span>{status1}</span>
            <span>{status2}</span>
            <Link className={`btn btn-${color}`} to={to}>
                {children}
            </Link>
        </div>;
}


        export default HomePanel;