import React, { useMemo, useRef, useState, useCallback } from "react";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import SVGDrawer from "../../../components/SVGDrawer";
import Defs from "../../../components/SVGDrawer/shapes/Defs";
import usePeriodicEffect from "../../../util/usePeriodicEffect";
import useSearchParams from "../../../util/useSearchParams";
import "./workflow.scss";
import WorkflowStep from "./WorkflowStep";
import WorkflowArrow from "./WorkflowArrow";
import SwimmingLane from "./SwimmingLane";
import EditorContainer from "../../../components/FormBuilder/EditorContainer";
import SidebarPanel from "../../../components/FormBuilder/SidebarPanel";
import WorkflowDetailsSidepanel from "./WorkflowDetailsSidepanel";
import WorkflowObjectSidepanel from "./WorkflowObjectSidepanel";
import WorkflowRolesSidepanel from "./WorkflowRolesSidepanel";
import WorkflowParamSidepanel from "./WorkflowParamSidepanel";


const TABS_SIDEBAR = {
    'details'    : { title: 'Details'     , component: WorkflowDetailsSidepanel},
    'object'     : { title: 'Object'      , component: WorkflowObjectSidepanel },
    'roles'      : { title: 'Roles'       , component: WorkflowRolesSidepanel  },
    'paramSchema': { title: 'Param Schema', component: WorkflowParamSidepanel  }
}
const DEFAULT_TAB = Object.keys(TABS_SIDEBAR)[0];


function WorkflowDiagram({
    state,
    dispatchAction
}) {
    const ref = useRef();
    const {
        initialized,
        layout,
        steps,
        arrows,
        roles,
        height,
        selection,
        paramSchema
    } = state;

    const [
        [tab2, setTab2]
    ] = useSearchParams({
        tab2: DEFAULT_TAB
    });

    usePeriodicEffect(() => {
        if (ref.current) {
            const rect = ref.current.element.getBoundingClientRect();
            const docEl = document.body.parentElement;
            const currentHeight = Math.max(0, docEl.clientHeight - 50 - docEl.clientTop - rect.y) | 0;
            if (!height || (height !== currentHeight)) {
                dispatchAction.setViewportHeight(currentHeight);
            }
        }
    }, 1000, [ref.current]);

    const selectedObject = steps[selection];

    const objectOnChange = useCallback((newObject) => {
        dispatchAction.setStepAttrs({ name: selection, data: newObject });
    }, [steps, selection]);

    const renameSelectedStep = dispatchAction.renameSelectedStep;

    const PanelComponent = TABS_SIDEBAR[tab2]?.component;

    return initialized ? (<>
        <Button color="warning" onClick={() => dispatchAction.addStep({})}>Create new step</Button>
        <EditorContainer>
            <SVGDrawer type="workflow" className="workflow" ref={ref} height={height}
                canPan canZoom minZoom={-2}
            >
                <g>{roles.map((role, idx) => (
                    <SwimmingLane
                        key={idx}
                        x={-layout.swimmingLane.gap}
                        y={idx * layout.swimmingLane.height}
                        gap={layout.swimmingLane.gap}
                        width={layout.swimmingLane.width}
                        height={layout.swimmingLane.height}
                        title={role}
                    />
                ))}</g>
                <g>{Object.values(steps).map((stepdef, idx) => (
                    <WorkflowStep
                        key={idx}
                        selected={selection === stepdef.name}
                        stepdef={stepdef}
                        onClick={() => { dispatchAction.setSelection({ selection: stepdef.name }); if (!/object|roles/.test(tab2)) setTab2('object') }}
                        onDrag={(_, x, y) => { dispatchAction.setStepPosition({ name: stepdef.name, position: { x, y } }) }}
                        onSetSize={(width, height) => dispatchAction.setStepSize({ name: stepdef.name, size: { width, height } })}
                    />
                ))}</g>
                <g>{arrows.map(({ from, to, text }, idx) => (<WorkflowArrow
                    key={idx}
                    text={text}
                    from={steps[from]}
                    to={steps[to]}
                />))}</g>
                <Defs />
            </SVGDrawer>

            <SidebarPanel
                title="Workflow Diagram"
                collapsable
                adjustable>
                <div className="_formEditor _workflowEditor">
                    <Nav tabs>{Object.entries(TABS_SIDEBAR).map(([key, { title }]) => (
                        <NavItem key={key} onClick={() => setTab2(key)}>
                            <NavLink active={tab2 === key}>{title}</NavLink>
                        </NavItem>
                    ))}</Nav>
                    <TabContent activeTab={tab2}>{PanelComponent ? (
                        <TabPane tabId={tab2}><PanelComponent
                            dispatchAction={dispatchAction}
                            object={selectedObject}
                            objectOnChange={objectOnChange}
                            setPathForSelectedObject={renameSelectedStep}
                            selectedObject={selectedObject}
                            onChange={objectOnChange}
                            paramSchema={paramSchema}
                            setPath={renameSelectedStep}
                            state={state}
                        /></TabPane>
                    ) : null}</TabContent>
                </div>
            </SidebarPanel>
        </EditorContainer>
    </>) : null;
}


export default WorkflowDiagram;