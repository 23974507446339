import { Trans } from '@lingui/react';
import React, { useMemo, useState } from 'react';
import { Input } from 'reactstrap';

import RequisitionsApi from '../../api/RequisitionsApi';
import useResourceLoader from '../../util/useResourceLoader';
import Notification from '../../components/Notification';
import Loader from '../../components/Loader';
import forms from "../forms/forms";
import { NO_FORM } from '../../constants';
import useSearchParams from '../../util/useSearchParams';


export function RequisitionPreviousForms({ currentRequisition }) {
    const [workflowStates, loadingWS, errorLoadingWS] = useResourceLoader(
        () => RequisitionsApi.getWorkflowStates({ requisitionId: currentRequisition.id }),
        [currentRequisition.id],
        []
    );

    const [[_formIdx, setFormIdx]] = useSearchParams({
        form: 0
    })
    const formIdx = _formIdx | 0;

    const state = useMemo(() => JSON.parse(currentRequisition.state), [currentRequisition.state]);
    const requisitionForms = useMemo(() => (workflowStates.filter(
        ws => ws.id && ws.page && state[ws.id]
    ).map(
        ({ page, title }) => ({ page, title })
    )), [workflowStates, state]);

    const currentForm = requisitionForms[formIdx] || requisitionForms[0];
    const FormComponent = forms[(currentForm || {}).page];

    const canEditOldRequisitionForms = true;

    console.log(currentForm);

    return loadingWS ? (
        <Loader fullscreen />
    ) : (errorLoadingWS ? (
        <Notification error={errorLoadingWS} />
    ) : ((requisitionForms.length > 0 ? (<>
        <Input type="select" value={formIdx} onChange={({ target: { value } }) => setFormIdx(value | 0)}>{requisitionForms.map(({ page, title }, idx) => (
            <option key={page} value={idx}>{title}</option>
        ))}</Input>
        {FormComponent && FormComponent !== NO_FORM ? (
            <FormComponent
                onlySave
                readonly={!canEditOldRequisitionForms}
                currentRequisition={currentRequisition}
                page={currentForm.page}
                generateDocuments={currentForm.generateDocuments}
            />
        ) : null}
    </>) : (
        <Notification>No hay formularios anteriores para ense&ntilde;ar.</Notification>
    ))));
}

export default RequisitionPreviousForms;