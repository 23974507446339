import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../constants";
// import leftPad from "../leftPad";

class DateFormatter{
    static tryParse(format) {
        const m = /^date$/.exec(format);
        return m ? new DateFormatter() : null;
    }

    // constructor() {
    // }

    apply(value) {
        value = dayjs.utc(value).local().format(DATETIME_FORMAT);
        return value;
    }

    parse(value) {
        const m = /date\((.+)\)/.exec(value);
        return m ? m[1]: 0;
    }

}


export default DateFormatter;