import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import SLASetting from "./SLASetting";
import Forms from "./Forms";
import UserTable from "../users/UsersTable";
import OrganizationalUnitsTable from "./OrganizationalUnit/OrganizationalUnitsTable";
import IntegrationsTable from "./Integrations/IntegrationsTable";
import InternalLayout from "../../components/layouts/InternalLayout";
import { AuthContext } from "../../context/AuthContext";
import useLoader from "../../util/useLoader";
import IntegrationsApi from "../../api/IntegrationsApi";
import Loader from "../../components/Loader";
import Jnx from "../../util/jnx";
import ObjectSchemas from "./ObjectSchema";
import WorkflowIndex from "./Workflow";
import Export from "./Export";
import EditNotification from "./Notifications/EditNotification";
import NotificationTable from "./Notifications/NotificationTable";
import Notifications from "./Notifications";
import Roles from "./Roles";
import BulkFiles from "./BulkFiles";

const TABS = {
    undefined: { noTab: true, component: () => <div>Seleccione el componente del sistema que desea manejar</div> },
    'users-jedi': {
        title: 'Usuarios', permission: 'ManageUsersInUnit', jnx: 'user.user.jediId and $isFalsy(user.user.accessProfile.name = "administrator")',
        component: () => <Loader />,
        onActivate({ loadFn }) {
            loadFn(async () => {
                const url = await IntegrationsApi.GetOperationUrl('jedi/jump-to-jedi/login');
                if (url) {
                    window.open(url, '_blank');
                }
            });
        }
    },
    'users': { title: 'Usuarios', component: UserTable, permission: ['ManageUsersInUnit', 'ConfigureUsersInUnit'], jnx: '$not(user.user.jediId) or (user.user.jediId and (user.user.accessProfile.name = "agencymanager" or user.user.accessProfile.name = "administrator"))' },
    'roles': { title: 'Roles', component: Roles, permission: 'ManageWorkflow', jnx: { expr: "user.email = 'systemadmin@akcelita.com'" } },
    'organizationalunits': { title: 'Agencias', component: OrganizationalUnitsTable, permission: ['ManageOrganizationalUnits', 'ConfigureOrganizationalUnits'] },
    'notification': { title: 'Notificaciones', component: Notifications, permission: ['ManageNotifications'] },
    'forms': { title: 'Formularios', component: Forms, permission: 'ManageWorkflow', jnx: { expr: "user.email = 'systemadmin@akcelita.com'" } },
    'objectSchemas': { title: 'Tipos de Entidad', component: ObjectSchemas, permission: 'ManageWorkflow', jnx: { expr: "user.email = 'systemadmin@akcelita.com'" } },
    'sla': { title: 'SLA', component: SLASetting, permission: 'ManageWorkflow', jnx: { expr: "user.email = 'systemadmin@akcelita.com'" } },
    'integrations': { title: 'Integraciones', component: IntegrationsTable, permission: 'ManageIntegrations', jnx: { expr: "user.email = 'systemadmin@akcelita.com'" } },
    'workflow': { title: 'Workflows', component: WorkflowIndex, permission: 'ManageWorkflow', jnx: { expr: "user.email = 'systemadmin@akcelita.com'" } },
    'export': { title: 'Exports', component: Export, permission: 'ManageWorkflow', jnx: { expr: "user.email = 'systemadmin@akcelita.com'" } },
    'bulkFiles': { title: 'Bulk Files', component: BulkFiles, permission: 'ManageWorkflow', jnx: { expr: "user.email = 'systemadmin@akcelita.com'" } },
}

const DEFAULT_TAB = Object.keys(TABS)[0];

function SystemSettings() {
    const history = useHistory();
    const { setting = DEFAULT_TAB } = useParams();
    const auth = useContext(AuthContext);
    const { permissions } = auth?.user || {};
    const [loading, error, loadFn] = useLoader();

    const tabs = useMemo(() => Object.entries(TABS).reduce((_, [tab, tabDef]) => {
        const isArray = tabDef.permission && Array.isArray(tabDef.permission);
        let validate;
        
        if (isArray) {
            validate = Object.entries(tabDef.permission).some(permission => permissions[permission[1]]);;
        } else {
            validate = (tabDef.permission ? (permissions && permissions[tabDef.permission]) : true)
        }

        if (
            (tabDef.permission ? validate : true) &&
            (tabDef.jnx ? (new Jnx(tabDef.jnx)).eval(auth || {}, '') : true)
        ) {
            tabDef.name = tab;
            _[tab] = tabDef;
        }
        return _;
    }, {}), [permissions, auth]);

    const activeTab = tabs[setting];
    const { component: Component } = activeTab || {};

    function setActiveTab(tab) {
        history.push(`/system-settings/${tab}`);
    }

    const context = useRef();
    context.current = {
        prefix: `/system-settings/`,
        auth,
        history,
        loadFn
    };

    useEffect(() => {
        if (activeTab?.onActivate) {
            activeTab.onActivate(context.current);
        }
    }, [activeTab]);

    return <InternalLayout><div>
        <Nav tabs>{Object.entries(tabs).map(([key, { title, noTab }]) => noTab ? null : (
            <NavItem key={key} onClick={() => setActiveTab(key)}>
                <NavLink active={setting === key}>{title}</NavLink>
            </NavItem>
        ))}</Nav>
        {loading ? <Loader /> : (
            <TabContent activeTab={setting}>
                {Component ? (
                    <TabPane tabId={setting}><Component />
                    </TabPane>) : null}
            </TabContent>
        )}
    </div></InternalLayout>;
}


export default SystemSettings;