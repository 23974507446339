import React, { useMemo } from "react";
import { FormGroup, Input } from "reactstrap";
import useResourceLoader from '../../util/useResourceLoader';
import fetchApi from "../../util/fetchApi";
import checkErrors from "../../api/checkErrors";
import Notification from "../Notification";
import Loader from "../Loader";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { getObject } from "../../util/mapObject";
import { useJnx } from "../../util/jnx";


function LookupFormStaticField(props) {
    const {
        formData,
        formContext: { sideChannel },
        schema: {
            title,
            lookup,
        },
    } = props;
    const {
        resource = "Options",
        api,
        params,
        id,
        label,
    } = lookup || {};

    const rootFormData = useSideChannelSubscription(sideChannel, 0);
    const paramsString = useMemo(() => {
        const list = [];
        if (formData) list.push(`${id}=${formData}`);
        if(rootFormData && params) {
            list.push(...Object.entries(params).map(([k, v]) => {
                if (v && v.field) v = getObject(rootFormData, v.field);
                return v ? `${k}=${encodeURIComponent(v)}` : null;
            }).filter(s => !!s));
        }
        
        return list.length ? list.join("&") : null;
    }, [rootFormData, params, id, formData]);

    const [data, loadingData, errorLoadingData] = useResourceLoader(async () => {
        if (!api) throw new Error("Schema error: Missing lookup data");
        if (!paramsString) return {};
        return fetchApi.lookup.get(`${api}${paramsString ? `?${paramsString}` : ''}`).then(checkErrors);
    }, [api, paramsString]);

    const valueJnx = useJnx(label);
    const value = useMemo(() => valueJnx.eval(
        data,
        '', {
            formData, rootFormData, data
        }
    ), [valueJnx, formData, rootFormData, data]);

    return (
        <FormGroup>
            {(title && title !== " ") ? (<label className="control-label" htmlFor="root_preferredBranchId">
                {title}
            </label>): null}
            {loadingData ? (<div>
                <Loader>Loading {resource}</Loader>
            </div>) : (errorLoadingData ? (<Notification color="danger">
                <div>{errorLoadingData.message}</div>
            </Notification>) : (
                <Input type="text" disabled value={value} />
            ))}
        </FormGroup>
    );
}


export default LookupFormStaticField;