import { useState, useEffect, useMemo } from 'react';
import useDragEffect from './useDragEffect';


function usePanZoom({
    coordCenter,
    width, height, pct,
    _onImageClick,
    onPanStart,
    onPanStop,
    center: prop_center, zoom: prop_zoom,
    canPan, canZoom,
    minZoom=0, maxZoom=6,
    clampPan,
    onWheel: prop_onWheel,
}){
    const [zoom, _setZoom] = useState(prop_zoom);
    const w2 = width / 2;
    const h2 = height / 2;
    const [center, _setCenter] = useState([width/2, height/2]);

    function setZoom(zoom){
        zoom = Math.max(2 ** minZoom, Math.min(zoom || 1, 2 ** maxZoom))
        _setZoom(zoom);
        setCenter(center, zoom);
    }

    function setCenter(center, _zoom=zoom){
        _zoom = _zoom || 1;
        const [cx, cy] = center || [w2, h2];
        const w2z = w2 / _zoom;
        const h2z = h2 / _zoom;
        const cx2 = clampPan ? Math.max(w2z, Math.min(cx, width - w2z)): cx;
        const cy2 = clampPan ? Math.max(h2z, Math.min(cy, height - h2z)): cy;
        _setCenter([cx2, cy2]);
    }

    useEffect(() => setZoom(prop_zoom), [prop_zoom]);
    useEffect(() => setCenter(
        prop_center || (coordCenter ? ([
            coordCenter[0] * pct,
            coordCenter[1] * pct,
        ]) : [width / 2, height / 2])
    ), [prop_center, coordCenter, width, height]);

    const onWheel = prop_onWheel || (canZoom ? ({deltaY}) => {
        // eslint-disable-next-line no-restricted-properties
        setZoom(zoom * Math.pow(2, (1 - 2 * (deltaY > 0)) / 4));
    } : undefined);

    const [
        viewport2DataCoords,
        data2ViewportCoords,
    ] = useMemo(() => {
        const zoompct = zoom * pct;
        const [ox, oy] = [w2 - center[0] * zoom, h2 - center[1] * zoom];

        return [
            ([x, y], options) => {
                const {isDelta, toInt} = options || {};

                if (!isDelta) {
                    x -= ox;
                    y -= oy;
                }

                const pt = [x / zoompct, y / zoompct];

                return toInt ? pt.map(v => v | 0) : pt;
            },

            ([x, y], options) => {
                const {isDelta, toInt} = options || {};

                const pt = [x * zoompct, y * zoompct];

                if (!isDelta) {
                    pt[0] += ox;
                    pt[1] += oy;
                }

                return toInt ? pt.map(v => v | 0) : pt;
            },
        ];
    }, [center, zoom, w2, h2, pct]);

    const { onMouseDownDelegate: onImageMouseDown, onClickDelegate: onImageClick } = useDragEffect({
        onDrag: (idx, x, y, dx, dy) => {
            if (canPan) {
                setCenter([center[0] - dx / zoom, center[1] - dy / zoom]);
            }
        },
        onDragStart: onPanStart,
        onDrop: onPanStop,
        onClick: _onImageClick,
    });

    return {
        center, zoom,
        onWheel,
        viewport2DataCoords,
        data2ViewportCoords,
        onImageClick, onImageMouseDown
    }
}


export default usePanZoom;