import React, { useState, useContext } from 'react';
import { Button, CardBody, Card, ListGroup, ListGroupItem } from 'reactstrap';
import { useHistory } from 'react-router';
import InternalLayout from '../components/layouts/InternalLayout';
import CollapsibleCard from '../components/CollapsibleCard';
import useTitle from "../util/useTitle";
import { useZohoDeskWidgetCallFunction } from "../util/useZohoDeskWidget";
import { AuthContext } from '../context/AuthContext';
import Notification from '../components/Notification';
import WorkTray from "../components/Dashboard/WorkTray";


const DOCUMENTS = [
    { title: "Requisición de Materiales con la hoja de continuación", url: "https://asg.pr.gov/Formularios/Compras%20y%20suministros/REQUISICION%20DE%20MATERIALES%20CON%20LA%20HOJA%20DE%20CONTINUACION%20(SC-1001).pdf" },
    { title: "Adiestramiento Herramienta Padawan (Extensión de JEDI)", url: "https://padawanprodstorage.blob.core.windows.net/padawanprodpublicstorage/ADIEST_1.PDF" },
];


const VIDEOS = [
    // { title: "Video 1", url: "https://www.youtube.com/embed/GDZOa4pLl54"},
    // { title: "Manejo desde Celular", url: "https://www.youtube.com/embed/EwyglCkyLvI"},
];

const dashboardWorktray = {
    "element": {
        "type": "worktray",
        "colSpan": 4,
        "title": " ",
        "entityType": "HelpDeskTicket",
        "resource": "uwe-entities/objectSchema/HelpDeskTicket",
        "lookupParams": {},
        "tableActions": [
            {
                "label": "Crear Ticket",
                "backgroundColor": "#00AFB9",
                "route": "/workflow/tickets/new"
            }
        ],
        "defaultFilter": "-date",
        "filters": [
            // {
            //     "title": "Nombre",
            //     "key": "metadata-like-name",
            //     "type": "string"
            // },
            {
                "title": "Fecha Desde",
                "key": "after",
                "type": "string",
                "format": "date"
            },
            {
                "title": "Fecha Hasta",
                "key": "before",
                "type": "string",
                "format": "date"
            },
            // {
            //     "title": "Etapa",
            //     "key": "stage",
            //     "type": "string"
            // },
            // {
            //     "title": "Problema",
            //     "key": "metadata-like-description",
            //     "type": "string"
            // },
            // {
            //     "title": "Estatus",
            //     "key": "metadata-like-ticketStatus",
            //     "type": "string"
            // },
            {
                "title": "Mostrar Solicitudes Cerradas",
                "key": "isClosed",
                "ui:field": "BoolYesNo",
                "type": "boolean"
            }
        ],
        "columns": [
            {
                "title": "Solicitud",
                "sortkey": "metadata.application",
                "map:field": "metadata.application"
            },
            {
                "title": "Fecha",
                "sortkey": "createdAt",
                "map:field": {
                    "expr": "$onlyDate(createdAt)"
                },
                "format": ""
            },
            {
                "title": "Etapa",
                "sortkey": "status.0.name",
                "map:field": "status.0.name"
            },
            {
                "title": "Número de caso",
                "sortkey": "metadata.subject",
                "map:field": "metadata.subject"
            },
            {
                "title": "Días Abierto",
                "sortkey": "metadata.application",
                "map:field": {
                    "expr": "$getBusinessDatesCount(lastUpdated, $now())"
                }
            },
            {
                "title": "Agencia",
                "sortkey": "organizationalUnit.name",
                "map:field": "organizationalUnit.name"
            },
            {
                "title": "Acciones",
                "actions": [
                    {
                        "label": "Trabajar",
                        "backgroundColor": "#10112B",
                        "route": "/workflow/tickets/$id",
                        "require": [
                            {
                                "flag": "canWork"
                            }
                        ]
                    },
                    {
                        "label": "Ver",
                        "backgroundColor": " #15416F",
                        "route": "/workflow/tickets/$id/view",
                        "restrict": [
                            {
                                "flag": "canWork"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "history": {
        "length": 3,
        "action": "PUSH",
        "location": {
            "pathname": "/workflow/tickets/tray",
            "search": "",
            "hash": "",
            "key": "l9s23h"
        }
    },
    "scope": {
        "dashboard": {
            "wrapperType": "internal",
            "id": 11,
            "order": 3,
            "slug": "tickets",
            "name": " ",
            "image": {
                "icons": {
                    "base": "dimo-icon dimo-icon-v_uso",
                    "paths": [
                        "p1"
                    ]
                }
            },
            "definition": {
                "type": "dashboard",
                "elements": [
                    {
                        "type": "worktray",
                        "colSpan": 4,
                        "title": " ",
                        "entityType": "HelpDeskTicket",
                        "resource": "uwe-entities/objectSchema/HelpDeskTicket",
                        "lookupParams": {},
                        "tableActions": [
                            {
                                "label": "Crear Ticket",
                                "backgroundColor": "#00AFB9",
                                "route": "/workflow/tickets/new"
                            }
                        ],
                        "defaultFilter": "-date",
                        "filters": [
                            {
                                "title": "Nombre",
                                "key": "metadata-like-name",
                                "type": "string"
                            },
                            {
                                "title": "Fecha Desde",
                                "key": "after",
                                "type": "string",
                                "format": "date"
                            },
                            {
                                "title": "Fecha Hasta",
                                "key": "before",
                                "type": "string",
                                "format": "date"
                            },
                            {
                                "title": "Etapa",
                                "key": "stage",
                                "type": "string"
                            },
                            {
                                "title": "Problema",
                                "key": "metadata-like-subject",
                                "type": "string"
                            },
                            {
                                "title": "Estatus",
                                "key": "metadata-like-ticketStatus",
                                "type": "string"
                            },
                            {
                                "title": "Mostrar Solicitudes Cerradas",
                                "key": "isClosed",
                                "type": "boolean"
                            }
                        ],
                        "columns": [
                            {
                                "title": "Solicitud",
                                "sortkey": "metadata.application",
                                "map:field": "metadata.application"
                            },
                            {
                                "title": "Fecha",
                                "sortkey": "createdAt",
                                "map:field": {
                                    "expr": "$onlyDate(createdAt)"
                                },
                                "format": ""
                            },
                            {
                                "title": "Etapa",
                                "sortkey": "status.0.name",
                                "map:field": "status.0.name"
                            },
                            {
                                "title": "Problema",
                                "sortkey": "metadata.subject",
                                "map:field": "metadata.subject"
                            },
                            {
                                "title": "Estatus",
                                "sortkey": "metadata.ticketStatus",
                                "map:field": "metadata.ticketStatus"
                            },
                            {
                                "title": "Días Abierto",
                                "sortkey": "metadata.application",
                                "map:field": {
                                    "expr": "$getBusinessDatesCount(lastUpdated, $now())"
                                }
                            },
                            {
                                "title": "Agencia",
                                "sortkey": "organizationalUnit.name",
                                "map:field": "organizationalUnit.name"
                            },
                            {
                                "title": "Acciones",
                                "actions": [
                                    {
                                        "label": "Trabajar",
                                        "backgroundColor": "#10112B",
                                        "route": "/workflow/tickets/$id",
                                        "require": [
                                            {
                                                "flag": "canWork"
                                            }
                                        ]
                                    },
                                    {
                                        "label": "Ver",
                                        "backgroundColor": " #15416F",
                                        "route": "/workflow/tickets/$id/view",
                                        "restrict": [
                                            {
                                                "flag": "canWork"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "version": 20,
            "lastUpdated": "2023-07-10T15:49:27.0562558",
            "schema": {
                "type": "dashboard",
                "elements": [
                    {
                        "type": "worktray",
                        "colSpan": 4,
                        "title": " ",
                        "entityType": "HelpDeskTicket",
                        "resource": "uwe-entities/objectSchema/HelpDeskTicket",
                        "lookupParams": {},
                        "tableActions": [
                            {
                                "label": "Crear Ticket",
                                "backgroundColor": "#00AFB9",
                                "route": "/workflow/tickets/new"
                            }
                        ],
                        "defaultFilter": "-date",
                        "filters": [
                            {
                                "title": "Nombre",
                                "key": "metadata-like-name",
                                "type": "string"
                            },
                            {
                                "title": "Fecha Desde",
                                "key": "after",
                                "type": "string",
                                "format": "date"
                            },
                            {
                                "title": "Fecha Hasta",
                                "key": "before",
                                "type": "string",
                                "format": "date"
                            },
                            {
                                "title": "Etapa",
                                "key": "stage",
                                "type": "string"
                            },
                            {
                                "title": "Problema",
                                "key": "metadata-like-subject",
                                "type": "string"
                            },
                            {
                                "title": "Estatus",
                                "key": "metadata-like-ticketStatus",
                                "type": "string"
                            },
                            {
                                "title": "Mostrar Solicitudes Cerradas",
                                "key": "isClosed",
                                "type": "boolean"
                            }
                        ],
                        "columns": [
                            {
                                "title": "Solicitud",
                                "sortkey": "metadata.application",
                                "map:field": "metadata.application"
                            },
                            {
                                "title": "Fecha",
                                "sortkey": "createdAt",
                                "map:field": {
                                    "expr": "$onlyDate(createdAt)"
                                },
                                "format": ""
                            },
                            {
                                "title": "Etapa",
                                "sortkey": "status.0.name",
                                "map:field": "status.0.name"
                            },
                            {
                                "title": "Problema",
                                "sortkey": "metadata.subject",
                                "map:field": "metadata.subject"
                            },
                            {
                                "title": "Estatus",
                                "sortkey": "metadata.ticketStatus",
                                "map:field": "metadata.ticketStatus"
                            },
                            {
                                "title": "Días Abierto",
                                "sortkey": "metadata.application",
                                "map:field": {
                                    "expr": "$getBusinessDatesCount(lastUpdated, $now())"
                                }
                            },
                            {
                                "title": "Agencia",
                                "sortkey": "organizationalUnit.name",
                                "map:field": "organizationalUnit.name"
                            },
                            {
                                "title": "Acciones",
                                "actions": [
                                    {
                                        "label": "Trabajar",
                                        "backgroundColor": "#10112B",
                                        "route": "/workflow/tickets/$id",
                                        "require": [
                                            {
                                                "flag": "canWork"
                                            }
                                        ]
                                    },
                                    {
                                        "label": "Ver",
                                        "backgroundColor": " #15416F",
                                        "route": "/workflow/tickets/$id/view",
                                        "restrict": [
                                            {
                                                "flag": "canWork"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        },
        "dashboardData": {}
    }
}

export function Help(props) {

    const auth = useContext(AuthContext);
    const [isOpenDoc, setIsOpenDoc] = useState(false);
    const [isOpenVid, setIsOpenVid] = useState(false);
    const [isOpenFAQ, setIsOpenFAQ] = useState(false);
    const [isOpenTicket, setIsOpenTicket] = useState(false);

    const history = useHistory();
    useTitle("Ayuda");

    const zdwcf = useZohoDeskWidgetCallFunction();

    return (<InternalLayout>
        <div>
            <h1>Ayuda</h1><br />
            <div>
                {DOCUMENTS.length || VIDEOS.length ? (<>
                    {DOCUMENTS.length ? (<>
                        <CollapsibleCard title="Documentos">
                            <ListGroup>
                                {DOCUMENTS.map(({ title, url }, idx) => (<ListGroupItem key={idx}>
                                    <a href={url} target="_blank" rel="noreferrer">{title}</a>
                                </ListGroupItem>))}
                            </ListGroup>
                        </CollapsibleCard>
                    </>) : null
                    }
                    <CollapsibleCard title="Videos de Entrenamiento">
                        <div className="d-flex flex-wrap flex-row">
                            {
                                VIDEOS.length ?
                                    VIDEOS.map(({ title, url }, idx) => (<Card className="mb-4">
                                        <CardBody key={idx}>
                                            <h4 className="text-default">{title}</h4>
                                            <iframe width="560" height="315" src={url} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                        </CardBody>
                                    </Card>)) :
                                    <Notification color="warning">Al momento no se encuentra material en esta sección. Verifique de nuevo pronto.</Notification>
                            }
                        </div>
                    </CollapsibleCard>
                </>) : null}
            </div>
        </div>
        {DOCUMENTS.length ? (<>
            <CollapsibleCard title="Tickets">
                <WorkTray element={dashboardWorktray.element} scope={dashboardWorktray.scope} />
            </CollapsibleCard>
        </>) : null}
        {/* {auth?.user?.user?.isSuperuser && false ?
            (<div>
                <h3>Crear un Ticket</h3>
                <h4>¿Necesitas ayuda directo al equipo? Crea un ticket.</h4>
                <Button onClick={() => { history.push(`/create-ticket`) }} color="info">
                    Crear Ticket
                </Button>
            </div>) : null
        } */}
    </InternalLayout>);
}

export default Help;