import {useMemo} from 'react';
import IdentityFormatter from './format/IdentityFormatter';
import CurrencyFormatter from './format/CurrencyFormatter';
import DateFormatter from './format/DateFormatter';


const FORMATS = [
    CurrencyFormatter,
    DateFormatter
];

export const IDENTITY_FORMATTER = new IdentityFormatter();


function applyFormatting(value, format, options){
    return getFormatterFor(format).apply(value);
}


export function useAppliedFormatter(value, format){
    const formatter = useFormatter(format);
    return useMemo(() => formatter.apply(value), [value, formatter]);
}


export function useFormatter(format){
    return useMemo(() => getFormatterFor(format), [format]);
}


function getFormatterFor(format) {
    return (format ? (
        typeof format === 'string' ? (
            FORMATS.reduce((_, fmt) => {
                if (!_) return fmt.tryParse(format);
                return _;
            }, null)
        ) : (
            format
        )
    ) : (
        null
    )) || IDENTITY_FORMATTER
}


export default applyFormatting;