import {useEffect} from "react";


export default function SetBodyClassName({
    className
}){
    useEffect(() => {
        document.body.className = addClass(document.body.className, className);
        return () => {
            document.body.className = removeClass(document.body.className, className);
        }
    }, [className]);
    return null;
}


function addClass(className, newClass){
    return className.indexOf(newClass) >= 0 ? className : `${className} ${newClass}`;
}


function removeClass(className, newClass){
    return className.replace(` ${newClass}`, "");
}