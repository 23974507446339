// import { Trans } from '@lingui/react';
import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router';
import { Button, Row, Col, Container } from 'reactstrap';
import useSearchParams from '../util/useSearchParams';
import { AuthContext } from '../context/AuthContext';
import FormComponent from '../components/FormComponent';
import ExternalLayout from '../components/layouts/ExternalLayout';
import { INIT_SEARCH } from '../constants';
import useLoader from '../util/useLoader';
import IntegrationsApi from '../api/IntegrationsApi';


const LoginForm = FormComponent({
    displayName: 'Login',
    schema: {
        title: "Login",
        type: "object",
        properties: {
            email: { title: "Email", type: "string" },
            password: { title: "Password", type: "string" },
        },
        required: ["email", "password"],
    },
    uiSchema: {
        "password": { "ui:widget": "password" },
    },
    parseProps: () => ({
        auth: useContext(AuthContext),
    }),
    async onSubmit({ formData: { email, password } }, { props: { auth } }) {
        await auth.login({ email, password });
    },
    renderFormChildren: ({ history }) => {
        function forgotPassword() {
            history.push("/forgot-password")
        }

        return (<>
            <Row>
                <Col><Button color="primary" type="submit" data-cy="submit" block>Acceder</Button></Col>
                <Col><Button color="link" onClick={forgotPassword} block>Olvide mi contraseña</Button></Col>
            </Row>
            <hr />

            <div className="text-center">
                <h5 className="text-bold">¿Necesitas crear una cuenta?</h5>
                <p className="text-secondary">Ponte en contacto con tu administrador de sistemas</p>
                {/* <Button color="primary" outline onClick={signUp} block>Registrarme</Button> */}
            </div>
        </>);
    },
    renderFormSubmitted: () => (
        <Redirect path="/" />
    ),
    withoutNavigation: true
});



const Login = ({ history }) => {

    if (INIT_SEARCH.j === '1') {
        const [loading, errorLoading, loadFn] = useLoader();

        useEffect(() => {
            loadFn(() => IntegrationsApi.GetAnonymousOperationUrl('jedi/jump-to-jedi-login').then((url) => {
                if (url) {
                    window.location.replace(url);
                }
            }))
        }, []);

        return null;
    } else {
        return ((<ExternalLayout>
            <div className="col col-md-6 offset-md-3" >
                <LoginForm history={history} />
            </div>
        </ExternalLayout>));
    }

};

export default Login;