

async function checkErrors(response) {
    const data = await ((response instanceof Response) ? response.json() : response);
    const { status, title, error, code } = data;

    if (status && title) {
        throw new Error(title);
    } else if (error) {
        throw new ApiError(error, code);
    } else {
        return response;
    }
}

class ApiError extends Error {
    constructor(message, code) {
        super(message);
        this.name = "ApiError";
        this.code = code;
      }
};

export default checkErrors;