import { Trans } from '@lingui/react';
import React, { useMemo, useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from 'reactstrap';
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';

import useResourceLoader from '../../util/useResourceLoader';
import RequisitionsApi from '../../api/RequisitionsApi';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';
import { DATETIME_FORMAT } from '../../constants';

import { toDrDateTime } from "../../util/dates";
import InternalLayout from '../../components/layouts/InternalLayout';


export function RequisitionNotes({
    id: requisitionId,
    readonly
}) {
    const [note, setNote] = useState('');
    const [notes, loadingNotes, error, setNotes] = useResourceLoader(
        () => RequisitionsApi.getRequisitionNotes({ requisitionId }),
        [requisitionId],
        []
    );

    const [statusMap] = useResourceLoader(() => RequisitionsApi.getWorkflowStatusMap({ requisitionId }), [], {});

    function noteChanged({ target: { value } }) {
        setNote(value);
    };

    async function submitNote() {
        if (note) {
            const newNote = await RequisitionsApi.createRequisitionNote({ requisitionId, note });
            console.log(newNote);
            setNotes(notes => [newNote, ...notes]);
        }
    };

    return (<InternalLayout>
        {loadingNotes ? <Loader fullscreen /> : null}
        {error ? <Notification color="danger">{JSON.stringify(error)}</Notification> : null}
        <div>
            {!readonly ? (<Card className="requisition-note-add">
                <CardBody>
                    <textarea row="3" value={note} onChange={noteChanged} />
                </CardBody>
                <Button color="primary" onClick={submitNote}>A&ntilde;adir Nota Nueva</Button>
            </Card>) : null}
            <div className="requisition-notes-list">
                {!notes.length ? (
                    <Notification color="warning">No hay notas para esta requisición</Notification>
                ) : null}
                {notes.map(({ createdAt, message, user, associatedAction }, idx) => (
                    <Card className="requisition-note-container" key={idx}>
                        <CardHeader className="requisition-note-header">
                            <span className="requisition-note-date-time">{toDrDateTime(createdAt).format(DATETIME_FORMAT)}</span>
                            <span className="requisition-note-user">{user.firstName} {user.lastName}</span>
                            <span className="requisition-note-action">{statusMap[associatedAction] || associatedAction}</span>
                        </CardHeader>
                        <CardBody className="requisition-note-text">{message}</CardBody>
                    </Card>
                ))}
            </div>
        </div>
    </InternalLayout>);
}

export default RequisitionNotes;