import React from 'react';
import Notification from '../components/Notification';
import forms from "../views/forms/forms";
import WaitForRequisitionFiles from "../components/WaitForRequisitionFiles";
import Loader from '../components/Loader';
import useResourceLoader from '../util/useResourceLoader';
import FormApi from '../api/FormApi';
import UWEFormComponent from './UWEFormComponent';
import RequisitionUWEFormComponent from './RequisitionUWEFormComponent';


function CurrentRequisitionForm({
    hide,
    slug,
    viewForm,
    currentRequisition,
    currentStatus,
    onDirty,
}) {
    const { page: _formName, awaitDocuments, isOpen } = currentStatus || {};
    const formName = (isOpen ? _formName : null) || viewForm;

    const [form, loadingISF, errorLoadingISF] = useResourceLoader(() => (formName ? (
        FormApi.getByName({ name: formName })
    ) : null), [formName]);

    return <div className={`requisition-form ${hide ? 'hidden' : ''}`}>{awaitDocuments ? (
        <WaitForRequisitionFiles currentRequisition={currentRequisition} page={formName} />
    ) : (formName ? (
        loadingISF ? (
            <Loader fullscreen />
        ) : (errorLoadingISF ? (
            <Notification error={errorLoadingISF} />
        ) : (form ? (
            <RequisitionUWEFormComponent slug={slug} form={form} currentStatus={currentStatus} currentRequisition={currentRequisition} page={formName} onDirty={onDirty} />
        ) : (
            <Notification color="warning">Form not fully implemented yet</Notification>
        )))
    ) : (
        <Notification color="warning">The requisition does not have any forms that you can view.</Notification>
    ))}</div>;
}



export default CurrentRequisitionForm;