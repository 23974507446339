import dayjs from "dayjs";
import _ from "lodash";
import { getObject, invertMap, mapObject, setObject } from "./mapObject";

function withSignature(signature, fn) {
    fn.signature = signature;
    return fn;
}

const DOMINICAN_TZ = 'America/Dominica'; // AST

const CHARSETS = {
    'w': 'abcdefghijklmnopqrstuvwxyz',
    'W': 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    '0': '0123456789',
    '!': '!@#$%^&*(),<.>;:[{]}\\/?|',
};

const jsonataFunctions = {
    charSet: withSignature(
        '<s:a<s>>',
        (cstype) => cstype.split('').reduce((_, cstype) => {
            const CS = CHARSETS[cstype];
            if (CS && !_.sets[cstype]) _.list.push(...CS);
            return _;
        }, { list: [], sets: {} }).list
    ),
    fromCharCode: withSignature(
        '<n:s>',
        String.fromCharCode
    ),
    fromCodePoint: withSignature(
        '<n:s>',
        String.fromCodePoint
    ),
    charAt: withSignature(
        '<sn:s>',
        (str, index) => str.charAt(index)
    ),
    charCodeAt: withSignature(
        '<sn:n>',
        (str, index) => str.charCodeAt(index)
    ),
    codePointAt: withSignature(
        '<sn:n>',
        (str, index) => str.codePointAt(index)
    ),
    range: withSignature(
        '<nn?n?:a<n>>',
        (from, to = undefined, step = 1) => {
            if (to === undefined) {
                to = from;
                from = 0;
            }

            const arr = [];
            for (let i = from; i < to; i += step) arr.push(i);

            return arr;
        }
    ),
    copy: data => {
        navigator.clipboard.writeText(`${data}`);
    },
    log: (...args) => { console.log(...args); },
    alert: text => { alert(text); return text; },
    isJson: text => {
        try {
            JSON.parse(text);
            return true;
        } catch (e) {
            return false;
        }
    },

    mapObject,
    invertMap,
    getObject,
    setObject,

    isNumber: value => {
        if (!value || typeof value !== 'number')
            return false

        return true;
    },
    isTruthy: value => !!value,
    isFalsy: value => !value,
    isEmpty: value => {
        switch (typeof value) {
            case 'string': return value.trim() === '';
            case 'object': return !value;
            case 'undefined': return true;
            default: return false;
        }
    },
    toJson: object => JSON.stringify(object),
    fromJson: text => JSON.parse(text),
    wait: milliseconds => new Promise(resolve => {
        setTimeout(() => resolve(), milliseconds);
    }),
    throw: message => { throw new Error(message); },
    try: (action, onError, onSuccess) => {
        let p = Promise.resolve().then(action);
        if (onSuccess) p = p.then(onSuccess);
        return p.catch(onError);
    },
    random: withSignature('<:n>', Math.random),
    sample: withSignature(
        '<a:x>',
        (arr) => {
            const ridx = ((Math.random() * arr.length) | 0) % arr.length;
            return arr[ridx];
        }
    ),
    pathUp(path, i = 0) {
        const components = path.split('.');
        return components.slice(0, Math.max(0, components.length - i - 1)).join('.');
    },
    pathJoin(...paths) {
        return paths.reduce((_, p) => {
            if (!p) return _;
            if (p.startsWith('.')) p = p.substr(1);
            if (!p) return _;
            if (p.endsWith('.')) p = p.substr(0, p.length - 1);
            p.split('.').forEach(component => {
                if (component === '') {
                    _.pop();
                } else {
                    _.push(component);
                }
            });
            return _;
        }, []).join('.');
    },
    toDrDateTime: datetime => {
        // This is to make all datetime fields consistent, some come with a Timezone.
        if (typeof datetime === 'string') datetime = datetime.replace('Z', '');
        const inUTC = dayjs(datetime).tz("UCT", true);
        return inUTC.tz(DOMINICAN_TZ);
    },
    differenceDays: (startDate, endDate) => {
        startDate = new Date(jsonataFunctions.toDrDateTime(startDate).format("YYYY/MM/DD h:mm A"));
        endDate = new Date(jsonataFunctions.toDrDateTime(endDate).format("YYYY/MM/DD h:mm A"));
        let count = 0;
        let curDate = parseInt(+startDate / 86400000, 10) * 86400000;
        while (curDate < parseInt(+endDate / 86400000, 10) * 86400000) {
            count += 1;
            curDate = curDate + 24 * 60 * 60 * 1000
        }
        return count;
    },
    findIndexByKeyValue: (array, key, value) => {
        if (!array)
            return -1;

        for (let i = 0; i < array.length; i += 1) {
            if (array[i][key] === value) {
                return i;
            }
        }
        return -1;
    },
    delete: (obj, property) => {
        if (!obj)
            return;

        delete obj[property];
        return obj;
    },
    deepMerge(...objs) { return _.merge({}, ...objs); },
    onlyDate: datetime => {
        // This is to make all datetime fields consistent, some come with a Timezone.
        if (typeof datetime === 'string') datetime = datetime.replace('Z', '');
        const inUTC = dayjs(datetime).tz("UCT", true).format('DD/MM/YYYY');
        return inUTC;
    },
    getBusinessDatesCount: (startDate, endDate) => {
        startDate = new Date(jsonataFunctions.toDrDateTime(startDate).format("YYYY/MM/DD h:mm A"));
        endDate = new Date(jsonataFunctions.toDrDateTime(endDate).format("YYYY/MM/DD h:mm A"));
        let count = 0;
        let curDate = parseInt(+startDate / 86400000, 10) * 86400000;
        while (curDate < parseInt(+endDate / 86400000, 10) * 86400000) {
            const dayOfWeek = new Date(curDate).getDay();
            const isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
            if (!isWeekend) {
                count += 1;
            }
            curDate = curDate + 24 * 60 * 60 * 1000
        }
        return count;
    },
    setAccounts: (data, field, value, path, id) => {
        const newData = getObject(data, path);
        if(!newData || !field) return;
        newData[field] = value;
        const usesOtherAccount = newData?.usesOtherAccount;
        let modify = 0;
        if (value) {
            const position = document.getElementById(id)?.closest('td');
            let nextFocus = "";
            if (position) {
                nextFocus = position.nextElementSibling.querySelector('td input')?.id;
            }

            const resp = (data?.articles?.items || []).map((item, idx) => {
                if (item?.panel3?.items?.length) {

                    (item.panel3.items || []).map((accounts, idx) => {
                        if (!accounts?.[field] && accounts.usesOtherAccount === usesOtherAccount) {
                            modify = modify + 1;
                            accounts[field] = value;
                        }
                        return accounts;
                    });

                }
                return item;
            });
            const pathUp = path.replace('articles.items.', '');
            setObject(resp, pathUp, newData);
            return {
                data: resp,
                modify,
                nextFocus
            }
        }
    },
    minLength: (text, min) => {
        if (!text || text.length < min) {
            return false;
        }
        return true;
    },
    maxLength: (text, max) => {
        if (!text || text.length > max) {
            return false;
        }
        return true;
    },
    searchOrganization: (organizations, paths, value) => {
        if(!organizations || !paths || !value) return false;
        const arrIds = paths.split('/').filter(x => x !== '').map(x => parseInt(x,10));
        return organizations.filter(x => arrIds.some(a => a === x.id)).filter(x => value.some(a => a === x.name))?.length > 0;
    },
};

export default jsonataFunctions;