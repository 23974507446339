import { useCallback, useContext, useMemo, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { AuthContext } from "../context/AuthContext";
import { checkAccess } from "../util/useAccessCheck";


function TabPanel({
    tabs,
    selected,
    setSelected,
    showContent=true,
    contentWrapper,
}){
    const auth = useContext(AuthContext);
    contentWrapper = contentWrapper || (x => x);
    const SelectedTabComponent = useMemo(() => Object.entries(tabs).reduce(
        (_, [k, v]) => selected === k ? v.component : _,
        null
    ), [tabs, selected]);

    return <>
        <Nav tabs>{Object.entries(tabs).map(([key, { title, icon, require }]) => (
            <NavItem key={key} data-cy={`sidebar-button-${key}`} onClick={() => setSelected(key)}>
                { !require || checkAccess(auth, require[0], require[1]) ?
                <NavLink active={selected === key}>
                    {icon ? <i className={icon} /> : null}
                    {title}
                </NavLink> : null }
            </NavItem>
        ))}</Nav>
        {showContent ? (<TabContent activeTab={selected} className={SelectedTabComponent ? "showing": "empty"}>
            {SelectedTabComponent ? (<TabPane tabId={selected} className={selected}>
                {contentWrapper(<SelectedTabComponent />)}
            </TabPane>): null}
        </TabContent>) : null}
    </>
}


TabPanel.Uncontrolled = function({
    selected: defaultSelected,
    toggle=true,
    ...props
}){
    const [selected, _setSelected] = useState(defaultSelected);
    const setSelected = useCallback((newSelected) => _setSelected(
        (!toggle || (newSelected !== selected)) ? newSelected : null
    ), [
        toggle, selected
    ])
    return <TabPanel selected={selected} setSelected={setSelected} {...props} />
}


export default TabPanel;