function getSVGXY(event){
    const svg = getSVGFor(event.target);
    if (svg) {
        const { x: px, y: py } = svg.getBoundingClientRect();
        // console.log(px, py, event.clientX, event.clientY, { x: event.clientX - px, y: event.clientY - py })
        return { x: event.clientX - px, y: event.clientY - py };
    }

    return undefined;
}


function getSVGFor(svgElement){
    let svg = svgElement;
    while (svg && svg.parentElement && svg.nodeName !== 'svg') {
        svg = svg.parentElement;
    }
    return svg && svg.nodeName === 'svg' ? svg : null;
}


export default getSVGXY;