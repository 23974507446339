
const STEP_TYPES = {
    form: {
        canHavePermissions: true
    },
    externalProcess: {
        canHavePermissions: true
    },
    calculation: {
    },
    branch: {
    },
    documentGeneration: {
        canHavePermissions: true
    },
    notification: {
    },
    emailForm: {
        canHavePermissions: true
    },
    end: {
        canHavePermissions: true
    },
}



export function stepCanHavePermissions(step){
    return !!(STEP_TYPES[step?.type]?.canHavePermissions);
}