import React from 'react';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import useSearchParams from '../../../util/useSearchParams';
import NotificationApi from '../../../api/NotificationApi';
import FormComponent from '../../../components/FormComponent';
import parseFormDefinition from '../../../util/parseFormDefinition';
import PopupNotification from '../../../components/PopupNotification';

export const EditNotificationForm = FormComponent({
    ...parseFormDefinition(
        {
            page: 'EditNotificationForm',
            title: "Notificación",
            schema: {
                general: {
                    title: "Notificación",
                    type: "object",
                    ":classNames": "grid-3-columns",
                    properties: {
                        title: {
                            title: "Título", type: "string",
                            "map:field": "title",
                            "akc:requiredIfVisible": true,
                        },
                        displayFrom: {
                            title: "Fecha Desde", type: "string",
                            "map:field": "displayFrom",
                            "format": "date",
                            "akc:requiredIfVisible": true,
                        },
                        displayTo: {
                            title: "Fecha Hasta", type: "string",
                            "format": "date",
                            "map:field": "displayTo",
                            "akc:requiredIfVisible": true,
                        },
                    }
                },
                requiresConfirmation: {
                    title: "Requiere Confirmación", type: "boolean",
                    "map:field": "requiresConfirmation",
                },
                requiresConfirmationText: {
                    title: "Texto para confirmación", type: "string",
                    "map:field": "requiresConfirmationText",
                    "akc:requiredIfVisible": true,
                    ":classNames": "from-col-1-size-3 input-block",
                    "ui:showIf": { expr: "requiresConfirmation", scope: "." }
                },
                pages: {
                    title: "Contenido",
                    type: "array",
                    "ui:orderable": true,
                    "map:field": "pages",
                    "ui:arrayType": "cards",
                    "ui:newSection": true,
                    "minItems": 1,
                    ":classNames": "from-col-1-size-3",
                    items: {
                        type: "object",
                        "ui:field": "HideObjectField",
                        properties: {
                            content: {
                                title: "Contenido",
                                type: "string",
                                "map:field": "content",
                                "ui:field": "HtmlEditor",
                                ":classNames": "from-col-1-size-3",
                                "akc:requiredIfVisible": true,
                            }
                        }
                    }
                },
                files: {
                    title: "Archivos",
                    type: "array",
                    "ui:orderable": true,
                    "map:field": "files",
                    // "ui:arrayType": "cards",
                    "ui:newSection": true,
                    "minItems": 0,
                    ":classNames": "from-col-1-size-3",
                    items: {
                        type: "object",
                        "ui:field": "HideObjectField",
                        properties: {
                            file: {
                                "type": [
                                    "number",
                                    "null"
                                ],
                                "isFile": true,
                                "akc:requiredIfVisible": true,
                                ":classNames": "from-col-1-size-1",
                                "title": " ",
                                "ui:field": "FileField"
                            }
                        }
                    }
                },
                criteria: {
                    title: "Destinarios",
                    type: "array",
                    "ui:orderable": false,
                    "map:field": "criteria",
                    "ui:arrayType": "cards",
                    "ui:newSection": true,
                    "minItems": 1,
                    "ui:onEmptyMessage": "Por favor añada al menos un destinatario.",
                    "classNames": "from-col-1-size-3",
                    items: {
                        type: "object",
                        "ui:newSection": true,
                        "ui:addButtonText": "Agregar Destinatario",
                        ":classNames": "separator-border",
                        "ui:buttonClass": "btn-sm float-right",
                        "ui:removable-label": "Eliminar Destinatario",
                        "ui:btnStyle": {},
                        "ui:btn-removable-classNames": 'fs-12 font-weight-bold',
                        "ui:buttons-bottoms-classNames": 'col-lg-12',
                        "ui:showColumnsIf": {
                            organizations: { expr: "type = 1 or type = 2", "scope": ".." },
                            roles: { expr: "type = 2 or type = 3", "scope": ".." },
                            users: { expr: "type = 4", "scope": ".." },
                        },
                        properties: {
                            type: {
                                ":classNames": "from-col-2-size-1",
                                "type": "number",
                                "map:field": "criteria.type",
                                "title": "Tipo de Destinatario",
                                "akc:requiredIfVisible": true,
                                "enum": [1, 2, 3, 4],
                                enumNames: ['Organizaciones', 'Organizaciones + Roles', 'Roles', 'Usuarios']
                            },
                            organizations: {
                                title: " ",
                                description: "Organización",
                                type: "array",
                                "ui:arrayType": "cards",
                                "ui:overflowX": 'none',
                                "ui:orderable": false,
                                items: {
                                    type: "object",
                                    "ui:addButtonText": "Agregar Organización",
                                    "ui:buttonClass": "btn-sm",
                                    "ui:buttonColor": "secondary",
                                    "ui:field": "HideObjectField",
                                    ":classNames": "row border-bottom mb-4",
                                    "ui:btn-removable-classNames": 'fs-12',
                                    "ui:buttons-bottoms-classNames": 'col-lg-12',
                                    "ui:showColumnsIf": {
                                        organizationalUnitId: { expr: "$isFalsy(jediAgencyId)", "scope": ".." },
                                        includeOrgChildren: { expr: "$isFalsy(jediAgencyId) and $isTruthy(organizationalUnitId)", "scope": ".." },
                                        jediAgencyId: { expr: "$isFalsy(organizationalUnitId)", "scope": ".." },
                                    },
                                    properties: {
                                        organizationalUnitId: {
                                            title: "Organización JEDI 2.0",
                                            "ui:cellClassName": "col-lg-4",
                                            "lookup": {
                                                "resource": "Unidades Organizacionales",
                                                "api": "organizationalunits",
                                                "params": {
                                                    "workable": 1
                                                },
                                                "parentId": "parentId",
                                                "collapsable": true,
                                                "collapseLevel": 1,
                                                "id": "id",
                                                "label": "name",
                                                "sortBy": "name"
                                            },
                                            items: { type: "number" },
                                            "ui:field": "LookupFormField",
                                            "akc:requiredIf": { expr: "$isFalsy(jediAgencyId)", scope: ".." },
                                        },
                                        includeOrgChildren: {
                                            "type": "boolean",
                                            "title": "Incluir Sub Agencias",
                                            "ui:field": "BoolYesNo",
                                            "ui:cellClassName": "col-lg-8",
                                            "ui:readonly": false,
                                            "akc:requiredIf": { expr: "$isTruthy(organizationalUnitId)", scope: ".." },
                                        },
                                        jediAgencyId: {
                                            title: "Organización JEDI 1.0",
                                            "ui:cellClassName": "col-lg-12",
                                            lookup: {
                                                api: "api:integrations/jedi/call/getAgencies",
                                                method: 'post',
                                                jnx: { "expr": "data", "scope": ".." },
                                                resource: "Organizaciones",
                                                options: {
                                                    useCache: true
                                                },
                                                id: 'externalAgencyID',
                                                label: 'name',
                                                addOptionAll: true,
                                                valueOptionAll: 'Todas',
                                                keyOptionAll: -1
                                            },
                                            items: { type: "number" },
                                            "ui:field": "LookupFormField",
                                            "akc:requiredIf": { expr: "$isFalsy(organizationalUnitId)", scope: ".." },
                                        },
                                    }
                                }
                            },
                            roles: {
                                title: " ",
                                description: "Roles de la organización",
                                type: "array",
                                "ui:arrayType": "cards",
                                "ui:orderable": false,
                                "ui:overflowX": "hidden",
                                items: {
                                    type: "object",
                                    "ui:addButtonText": "Agregar Rol",
                                    "ui:field": "HideObjectField",
                                    ":classNames": "row border-bottom mb-4",
                                    "ui:buttonClass": "btn-sm",
                                    "ui:buttonColor": "secondary",
                                    "ui:showColumnsIf": {
                                        roleId: { expr: "$isFalsy(jediUserTypeId)", "scope": ".." },
                                        canWorkAs: { expr: "$isFalsy(jediUserTypeId)", "scope": ".." },
                                        canBeAssigned: { expr: "$isFalsy(jediUserTypeId)", "scope": ".." },
                                        canAssign: { expr: "$isFalsy(jediUserTypeId)", "scope": ".." },
                                        jediUserTypeId: { expr: "$isFalsy(roleId)", "scope": ".." },
                                    },
                                    properties: {
                                        roleId: {
                                            title: "JEDI 2.0 Rol",
                                            lookup: {
                                                api: 'roles',
                                                resource: "Roles",
                                                id: 'id',
                                                label: 'name'
                                            },
                                            items: { type: "number" },
                                            "ui:field": "LookupFormField",
                                            "akc:requiredIfVisible": true,
                                            "ui:cellClassName": "col-lg-4",
                                        },
                                        canWorkAs: {
                                            "title": "Puede Trabajar",
                                            "type": "boolean",
                                            "ui:cellClassName": "col-lg-2 text-nowrap pt-5",
                                        },
                                        canBeAssigned: {
                                            "title": "Puede ser asignado",
                                            "type": "boolean",
                                            "ui:cellClassName": "col-lg-2 text-nowrap pt-5",
                                        },
                                        canAssign: {
                                            "title": "Puede asignar",
                                            "type": "boolean",
                                            "ui:cellClassName": "col-lg-4 text-nowrap pt-5",
                                        },
                                        jediUserTypeId: {
                                            title: "JEDI 1.0 Tipo de Usuario",
                                            "ui:cellClassName": "col-lg-12",
                                            lookup: {
                                                api: "api:integrations/jedi/call/getUserTypes",
                                                method: 'post',
                                                jnx: { "expr": "data", "scope": ".." },
                                                resource: "Organizaciones",
                                                options: {
                                                    useCache: true
                                                },
                                                id: 'userTypeID',
                                                label: 'name'
                                            },
                                            items: { type: "number" },
                                            "ui:field": "LookupFormField",
                                            "akc:requiredIf": { expr: "$isFalsy(organizationalUnitId)", scope: ".." },
                                        },
                                    }
                                }
                            },
                            users: {
                                title: " ",
                                description: "Usuarios",
                                type: "array",
                                "ui:arrayType": "cards",
                                "ui:orderable": false,
                                items: {
                                    type: "object",
                                    "ui:showColumnsIf": {
                                        userName: { expr: "$isFalsy(jediId)", "scope": ".." },
                                        userNameJedi: { expr: "$isFalsy(userId)", "scope": ".." },
                                    },
                                    "ui:filterRow": { expr: "$isTruthy(fromCreate) or $exists(fromCreate) = false", "scope": ".." },
                                    properties: {
                                        userName: {
                                            "title": "Usuario JEDI 2.0",
                                            "ui:cellClassName": "text-left",
                                            "ui:field": "Autocomplete",
                                            "map:field": "userName",
                                            "ui:onSelect": {
                                                expr: "$set({($pathUp($fieldPath)~>$pathJoin('userId')): $object.id})",
                                            },
                                            "lookup": {
                                                "resource": "Usuarios",
                                                "params": { "data": { "expr": "userId", "scope": "..", "path": true } },
                                                "api": "agencyUsers",
                                                "id": "name",
                                                "method": "get",
                                                "label": {
                                                    "expr": "name"
                                                },
                                                "searchParam": "desc"
                                            },
                                            "akc:requiredIf": { expr: "$isFalsy(userId) and $isFalsy(jediId)", scope: ".." },
                                        },
                                        userNameJedi: {
                                            "title": "Usuario JEDI 1.0",
                                            "ui:cellClassName": "text-left",
                                            "ui:field": "Autocomplete",
                                            "map:field": "userName",
                                            "ui:onSelect": {
                                                expr: "$set({($pathUp($fieldPath)~>$pathJoin('jediId')): $object.userID})"
                                            },
                                            "lookup": {
                                                "resource": "Usuarios",
                                                "params": { "data": { "expr": "jediId", "scope": "..", "path": true } },
                                                "api": "api:integrations/jedi/call/getUsers/$data",
                                                "id": "fullName",
                                                "method": "post",
                                                "jnx": { "expr": "data", "scope": ".." },
                                                "label": {
                                                    "expr": "fullName"
                                                },
                                                "searchParam": "data"
                                            },
                                            "akc:requiredIf": { expr: "$isFalsy(userId) and $isFalsy(jediId)", scope: ".." },
                                        }
                                    }
                                }
                            }
                        }
                    },
                },
            }
        }
    ),
    withoutNavigation: true,
    parseProps: () => {
        const [[id, setId]] = useSearchParams({ id: undefined });
        return { id };
    },
    loadData: ({ id }) => id && id !== "new" ? NotificationApi.get(id) : null,
    onSubmit: ({ object }, { props: { id } }) => id && id !== "new" ? NotificationApi.update(id, object) : NotificationApi.create(object),
    submitButtons: {
        update: { text: "Actualizar", className: "btn-primary", "ui:showIf": "$formData.id" },
        create: {
            text: "Someter", className: "btn-primary", "ui:showIf": "$isFalsy($formData.id)",
            setProps: {
                "isDraft": false
            },
        },
        draft: {
            "text": "Guardar Borrador",
            "className": "btn-secondary",
            "ui:showIf": { expr: "$exists($initialFormObject.id) = false or $initialFormObject.id = 'new' or $initialFormObject.isDraft" },
            "setProps": {
                "isDraft": true
            },
            "confirm": [
                "Confirme para guardar la notificación como un borrador."
            ],
            ignoreValidation: true
        },
        preview: {
            text: "Vista Previa", className: "btn-secondary", "onBeforeSubmit": async (submitEvent) => {
                submitEvent.preventDefault();

                const { formObject, scope } = submitEvent;
                const { setPreviewNotif } = scope?.props;
                setPreviewNotif(formObject);
            },
            ignoreValidation: true
        },
    },
    renderFormSubmitted: () => (
        <Redirect to="../system-settings/notification" />
    )
});

export default EditNotificationForm;