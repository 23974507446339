import React, { useState, useEffect, useContext, useMemo } from 'react';
import { FormGroup, Label, Row, Col, Input, Button, Card, CardHeader, CardBody, Form, Table } from "reactstrap";
import { t, Trans } from '@lingui/macro';
import useResourceLoader from '../../../util/useResourceLoader';
import LookupApi from '../../../api/LookupApi';
import Loader from '../../../components/Loader';
import useLoader from '../../../util/useLoader';



const NotificationSearchBar = ({
    filters,
    onSearch,
    onClearSearch,
    statusState = 'active',
    excludeStage,
    excludeVisibility,
    onlyStage,
    showOptionsFilters
}) => {
    const [current, setCurrent] = useState(filters);
    const [resetCt, setResetCt] = useState(0);

    useEffect(() => setCurrent(filters), [filters]);

    const [[
        adquisitionMethods,
        agencies,
    ], loadingOptions, errorLoadingOptions] = useResourceLoader(() => Promise.all([
        LookupApi.getMetadataAttributeEnum("purchaseMethod"),
        LookupApi.getOrganizationalUnits({ "workable": "1" }),
    ]), [], [[], [], []]);

    const [statuses, loadingStatuses, errorLoadingStatuses] = useResourceLoader(async () => {
        const statuses = await LookupApi.getMetadataAttributeEnum("status");
        return statuses.filter(st => st.type === 'Fijo' && st.state === statusState);
    }, [statusState], []);

    const loading = loadingOptions || loadingStatuses;
    const error = errorLoadingOptions || errorLoadingStatuses;

    const handleChange = (e) => {
        const returnedValue = e.target.value
        const update = { [e.target.name]: returnedValue };
        setCurrent(Object.assign({}, current, update));
    }

    const handleCompleted = (e) => {
        const update = { 'completed': !current.completed };
        setCurrent(Object.assign({}, current, update));
    }

    const handleClosed = (e) => {
        const update = { 'closed': !current.closed };
        setCurrent(Object.assign({}, current, update));
    }

    const search = (e) => {
        e.preventDefault();
        console.log("current", current);
        onSearch(current);
    }

    function clearSearch(e) {
        e.preventDefault();
        setCurrent({});
        setResetCt(resetCt + 1);
        onClearSearch();
    }

    return (<Form onSubmit={search}>
        <Card className="search-bar square-top">
            <CardBody>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="title">Título</Label>
                            <Input key={resetCt} value={current.title} onChange={handleChange} id="title" name="title" />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="active">Mostrar solo activas</Label>
                            <Input key={resetCt} value={current.stage} onChange={handleChange} id="active" name="active" type="select">
                                <option value="">No</option>
                                <option value="True">Sí</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="draft">Mostrar solo borradores</Label>
                            <Input key={resetCt} value={current.stage} onChange={handleChange} id="draft" name="draft" type="select">
                                <option value="">No</option>
                                <option value="True">Sí</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button color="primary" type="submit">Filtrar</Button>
                        {" "}
                        <Button color="secondary" onClick={clearSearch}>Limpiar Filtro</Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </Form>);
}


const listFmt = {
    del1: '_', del2: '-',
    parse(text) {
        return text ? text.split(listFmt.del1).map(item => item.split(listFmt.del2).map(x => x | 0)) : [];
    },
    stringify(list) {
        return (list || []).map(x => x.join(listFmt.del2)).join(listFmt.del1);
    }
};


// function RoleFilterInput({
//     name, value, onChange
// }){
//     const items = useMemo(() => listFmt.parse(value), [value]);

//     const [roles, loadingRoles, errorLoadingRoles] = useResourceLoader(() => LookupApi.getRoles().then(
//         roles => roles.reduce((_, r) => {_[r.id] = r; return _;}, {})
//     ), []);
//     const [loadingUsers, errorLoadingUsers, userLoaderFn] = useLoader();
//     const [roleUsers, setRoleUsers] = useState({});

//     function removeItemAt(idx) {
//         const newItems = items.slice();
//         newItems.splice(idx, 1);
//         onChange({target: {value: listFmt.stringify(newItems), name}});
//     }

//     function addNewItem(){
//         onChange({target: {value: listFmt.stringify([...items, [0, 0]]), name}});
//     }

//     function setItemAt(idx, newItem) {
//         const newItems = items.slice();
//         newItems[idx] = newItem;
//         onChange({target: {value: listFmt.stringify(newItems), name}});
//     }

//     return (<>
//         <Trans>Filter By User And Role</Trans>
//         <Table size="sm">
//             <tbody>{loadingRoles ? <Loader /> : (<>
//                 {items.map((item, idx) => (<RoleFilterInputRow
//                     key={idx}
//                     roles={roles}
//                     item={item}
//                     onChange={newItem => setItemAt(idx, newItem)}
//                     onRemove={() => removeItemAt(idx)}
//                 />))}
//                 <tr><td  colSpan="3"><Button onClick={() => addNewItem()} color="secondary">
//                     <i className="fa fa-plus" title={t`Add role and user to filter.`} />
//                 </Button></td></tr>
//             </>)}</tbody>
//         </Table>
//     </>);
// }


export default NotificationSearchBar;