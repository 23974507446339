const appProps = {
    welcomeShown: false,
    sidebarTab: undefined,
    sidebarExpanded: true,
    navbarContent: undefined,
    newReqDomElement: undefined,
    modalContainer: undefined,
};


export default appProps;