import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ExternalLayout from '../components/layouts/ExternalLayout';
import AppContext from '../context/AppContext';

import { AuthContext } from '../context/AuthContext';
import useTitle from '../util/useTitle';
import AppTitle from '../components/AppTitle';
import NotificationApi from '../api/NotificationApi';
import useResourceLoader from '../util/useResourceLoader';


export function Home() {
    const auth = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        history.push(auth?.user ? "workflow/requisitions/tray" : "login");
    }, []);



    // useEffect(() => {
    //     if (notifications) {
    //         console.log("notifications", notifications)
    //     }
    // }, [notifications, loadingNotifications]);

    return (<ExternalLayout />);
}


export default Home;