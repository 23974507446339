import { useMemo } from "react";
import { useLocation } from "react-router";
import { interpolate } from "./mapObject";


export function interpolateRoute(route, scope) {
    return interpolate(`${route}`, scope);
}

export function concatRoute(r1, r2){
    if (r2.startsWith('/')) {
        return r2;
    }

    const delim = r1.endsWith('/') ? '' : '/';
    return `${r1}${delim}${r2}`;
}


export function useRouteInterpolation(route, scope){
    const { pathname } = useLocation();
    return useMemo(() => route ? concatRoute(
        pathname,
        interpolateRoute(route, scope)
    ) : null, [route, scope, pathname]);
}