import { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { AuthContext } from "../context/AuthContext";


export function checkAccess(auth, role, permission) {
    if (permission) {
        return (
            (auth?.user?.roles[role] || {})[permission] ||
            (auth?.user?.permissions || {})[permission]
        );
    } else {
        return !!(auth?.user?.roles[role]);
    }
}


function useAccessCheck(role, permission, redirectTo){
     const auth = useContext(AuthContext);
    const history = useHistory();
    const hasAccess = checkAccess(auth, role, permission);

    useEffect(() => {
        if (!hasAccess && redirectTo) {
            const url = (typeof redirectTo === 'function') ? redirectTo(auth) : redirectTo;
            if (url) history.push(url);
        }
    }, [hasAccess, redirectTo])

    return hasAccess;
}



export default useAccessCheck;