import React, { useCallback, useRef } from 'react';


function DropZoneTargets({
    top, bottom, left, right,
    visible,
    onZoneEnter, onZoneLeave, onZoneDrop
}){
    const sideRef = useRef();
    const dragEnter = useCallback((e) => {
        e.stopPropagation();
        const side = e.target.className;
        if (sideRef.current !== side) { onZoneLeave(sideRef.current); }
        sideRef.current = side;
        onZoneEnter(side);
    }, []);

    const dragLeave = useCallback((e) => {
        e.stopPropagation();
        const side = e.target.className;
        if (sideRef.current !== side) { return; }
        sideRef.current = null;
        onZoneLeave(side);
    }, []);

    const dragOver = useCallback(e => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const dragEnd = useCallback((e) => {
        e.stopPropagation();
        const side = sideRef.current;
        if (side) onZoneDrop(side);
    }, []);

    return (<div className={`dropzone-targets ${top ? 'has' : 'no'}-top ${bottom ? 'has' : 'no'}-bottom ${left ? 'has' : 'no'}-left ${right ? 'has' : 'no'}-right ${visible ? 'visible' : ''}`}>
        {top    ? <div className="top"    onDragEnter={dragEnter} onDragLeave={dragLeave} onDragOver={dragOver} onDrop={dragEnd} /> : null}
        {bottom ? <div className="bottom" onDragEnter={dragEnter} onDragLeave={dragLeave} onDragOver={dragOver} onDrop={dragEnd} /> : null}
        {left   ? <div className="left"   onDragEnter={dragEnter} onDragLeave={dragLeave} onDragOver={dragOver} onDrop={dragEnd} /> : null}
        {right  ? <div className="right"  onDragEnter={dragEnter} onDragLeave={dragLeave} onDragOver={dragOver} onDrop={dragEnd} /> : null}
    </div>);
}


export default DropZoneTargets;