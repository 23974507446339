import _ from "lodash";
import React, { useContext, useState, useCallback, useMemo, useEffect } from 'react';
import {
    Table,
    Button,
    Col,
    Row,
    Card,
    CardHeader,
    CardFooter
} from 'reactstrap';
import { useHistory } from 'react-router';
import { AuthContext } from '../../../context/AuthContext';
import useResourceLoader from '../../../util/useResourceLoader';
import Loader from '../../../components/Loader';
import Notification from '../../../components/Notification';
import useTitle from '../../../util/useTitle';
import SortButton from "../../../components/SortButton";
import useSearchParams from '../../../util/useSearchParams';
import Pagination from '../../../components/Pagination';
import NotificationApi from '../../../api/NotificationApi';
import NotificationSearchBar from './NotificationSearchBar';
import { useOpenModal } from '../../../components/FormModal';
import ModalContainer from "../../../components/ModalContainer";


function NotificationTable(props) {
    const history = useHistory()
    useTitle("Notificaciones");

    const openModal = useOpenModal();
    const [reset, setReset] = useState();

    const { setPreviewNotif } = props;

    const defaultView = useMemo(() => { }, []);

    const { user: { permissions: { ManageNotifications } = {} } = {} } = useContext(AuthContext) || {};

    const [
        [offset, setOffset],
        [size, setSize],
        [sortBy, setSortBy],
        [openFilter, setOpenFilter],
        [searchParams, setSearchParams]
    ] = useSearchParams({
        offset: 0,
        size: 10,
        sortBy: '-id',
        openFilter: false
    });

    const filters = useMemo(() => ({
        ...searchParams
    }), [searchParams]);

    const setFilters = useCallback((filters) => {
        setSearchParams({ ...searchParams, ...filters });
    }, [searchParams, setSearchParams]);

    useEffect(() => setFilters({ ...(filters || {}), ...(defaultView || {}) }), [defaultView]);

    const [paging, loadingNotifications, error] = useResourceLoader(async () => {
        const notifications = await NotificationApi.list({ offset, size, sortBy, ...filters });
        return Array.isArray(notifications) ? {
            from: 0,
            to: notifications.length,
            count: notifications.length,
            items: notifications
        } : notifications;
    }, [size, offset, sortBy, filters, reset]);

    const {
        from = 0,
        to = 0,
        count = 0,
        items: notifications = [],
    } = paging || {};


    const showFilter = useMemo(() => (openFilter === "true"), [openFilter]);

    const toggleFilter = (e) => {
        e.preventDefault();
        setOpenFilter(!showFilter);
    }

    const onSearch = (update) => {
        setFilters({ ...filters, ...update });
    }

    const onClearSearch = useCallback(() => setSearchParams({ offset, size, sortBy, ...defaultView }), [setSearchParams, offset, size, sortBy, defaultView]);

    const showOptionsFilters = {
        number: true,
        visibility: true,
        adquisitionMethods: true,
        after: true,
        before: true,
        stage: false
    };

    function select(notification) {
        if (notification) history.push(`./notification?id=${notification.id}`);
    }

    async function onPreview(id) {
        const notification = await NotificationApi.get(id);
        if (notification) {
            setPreviewNotif(notification);
        }
    }

    const CONFIRM_DELETE_NOTIFICATION_MODAL = [
        "ConfirmationModal",
        `Confirmar la eliminación`,
        `Seguro que quiere eliminar la notificación?`,
        [
            { text: "Si", color: "primary", value: true },
            { text: "No", color: "secondary", value: false },
        ]
    ];

    async function onDelete(id) {
        if (await openModal(...CONFIRM_DELETE_NOTIFICATION_MODAL)) {
            await NotificationApi.delete(id);
            setReset(new Date().getTime());
        }
    }

    return (<>
        {loadingNotifications ? <Loader fullscreen /> : null}
        {error ? <Notification color="danger">{JSON.stringify(error)}</Notification> : null}
        <ModalContainer />
        <Card className={`inbox-card primary ${showFilter ? '' : 'no-bottom-gap'}`}>
            <CardHeader>
                <Row>
                    <Col xs="9">
                        <div className="title">Notificaciones</div>
                    </Col>
                    <Col sm="2"><div className="float-right">
                        {ManageNotifications ? (
                            <Button color="primary" onClick={() => history.push("./notification?id=new")} >Nueva Notificación</Button>
                        ) : null}
                    </div></Col>
                    <Col sm="1"><div className="float-right">
                        <Button onClick={toggleFilter} type="button" outline color="secondary" active={showFilter}>
                            <i className="glyphicon glyphicon-filter" />
                        </Button>
                    </div></Col>
                </Row>
            </CardHeader>
            {showFilter ? (
                <NotificationSearchBar
                    filters={filters}
                    onSearch={onSearch}
                    statusState="draft"
                    showOptionsFilters={showOptionsFilters}
                    onClearSearch={onClearSearch}
                />
            ) : null}
        </Card>
        <Card className={`inbox-card ${showFilter ? '' : 'square-top'}`}>
            <Table>
                <thead><tr>
                    <SortButton tag="th" sortKey="title" sortBy={sortBy} setSortBy={setSortBy}>Título</SortButton>
                    <SortButton tag="th" sortKey="displayFrom" sortBy={sortBy} setSortBy={setSortBy}>Desde</SortButton>
                    <SortButton tag="th" sortKey="displayTo" sortBy={sortBy} setSortBy={setSortBy}>Hasta</SortButton>
                    <SortButton tag="th" sortKey="countView" sortBy={sortBy} setSortBy={setSortBy}>Vistas</SortButton>
                    <SortButton tag="th" sortKey="active" sortBy={sortBy} setSortBy={setSortBy}>Activo</SortButton>
                    <SortButton tag="th" sortKey="isDraft" sortBy={sortBy} setSortBy={setSortBy}>Borrador</SortButton>
                    <td />
                </tr></thead>
                <tbody>
                    {!notifications.length && <tr><td colSpan={7} className='text-center'>No hay notificaciones para mostrar</td></tr>}
                    {(notifications || []).map((notification, idx) => (
                        <NotificationEntry key={idx} notification={notification} onDelete={onDelete} onPreview={onPreview} onSelect={select} />
                    ))}
                </tbody>
            </Table>
            <CardFooter>
                <div className="float-right">
                    <Pagination offset={from} count={count} size={size} setSize={setSize} setOffset={setOffset} />
                </div>
            </CardFooter>
        </Card>
    </>);
}

function parseDateAsUTC(dateString) {

    if (!dateString) return;

    const parts = dateString.split('-');
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[2], 10);

    return new Date(Date.UTC(year, month, day));
}

function isTodayWithinDateRange(startDate, endDate) {
    const today = new Date();
    startDate = parseDateAsUTC(startDate);
    endDate = parseDateAsUTC(endDate);
    today.setUTCHours(0, 0, 0, 0);

    return startDate <= today && today <= endDate;
}

function NotificationEntry({ notification, onSelect, onPreview, onDelete }) {
    const { title, displayFrom, displayTo, isDraft, countView, id } = notification;

    return (<tr className="clickable">
        <td>{title}</td>
        <td>{displayFrom}</td>
        <td>{displayTo}</td>
        <td>{countView}</td>
        <td>{isTodayWithinDateRange(displayFrom, displayTo) ? <i className="glyphicon glyphicon-ok" /> : ''}</td>
        <td>{isDraft ? <i className="glyphicon glyphicon-ok" /> : ''}</td>
        <td>
            <Button color="primary" className='mr-1' onClick={() => onPreview(id)}>Vista Previa</Button>
            <Button color="primary" className='mr-1' onClick={() => onSelect(notification)}>Editar</Button>
            <Button color="primary" onClick={() => onDelete(id)}>Eliminar</Button>
        </td>
    </tr>)
}


export default NotificationTable;