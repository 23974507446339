import { t } from "@lingui/macro";
import React from "react";
import Switch from "../../../components/Switch";


function RolePermissionSwitch({
    isStepRole,
    actionName, role,
    value, onChange
}){
    return (<Switch
        value={isStepRole || value}
        title={isStepRole ? (
            t`"${actionName}" is always enabled for ${role}`
        ) : (value ? (
            t`Disable "${actionName}" for ${role}`
        ) : (
            t`Enable "${actionName}" for ${role}`
        ))}
        onChange={isStepRole ? undefined : (
            ({target: {checked}}) => onChange(checked)
        )}
        round
    />);
}


export default RolePermissionSwitch;