import React, { useMemo } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from 'reactstrap';
import dayjs from 'dayjs';
import useResourceLoader from '../util/useResourceLoader';
import RequisitionsApi from '../api/RequisitionsApi';
import { DATETIME_FORMAT } from '../constants';
import Notification from '../components/Notification';
import Loader from '../components/Loader';


const jediStatusBlacklist = new Set([
    'EXTERNALAGENCYRETURNED',
    'EXTERNALAGENCYREJECTED',
]);


function RequisitionHistory({ requisition, totalExpanded }) {
    const requisitionId = requisition?.id;

    const [history, loadingHistory, error] = useResourceLoader(
        () => RequisitionsApi.getRequisitionHistory({ requisitionId }),
        [requisitionId],
        []
    );

    const externalHistory = requisition?.externalData?.history;

    const mergedHistory = useMemo(() => [
        ...(history || []).filter(entry => (
            entry?.status !== 'Enviado a JEDI'
        )).map(entry => ({
            ...entry,
            date: dayjs.utc(entry.timestamp).local()
        })),
        ...(externalHistory || []).filter(({
            purchaseRequestStatusCode
        }) => !jediStatusBlacklist.has(purchaseRequestStatusCode)
        ).map(({
            createdDate,
            purchaseRequestStatus,
            details,
            purchaseRequestStatusReason,
            user: {fullName}
        }) => ({
            timestamp: createdDate,
            date: dayjs(createdDate),
            status: purchaseRequestStatus,
            option: purchaseRequestStatusReason,
            reason: details,
            user: {name: fullName}
        }))
    ].sort(
        (b, a) => a.date.diff(b.date)
    ), [history, externalHistory]);

    return loadingHistory ? <Loader /> : (
        <>
            {error ? <Notification color="danger">{JSON.stringify(error)}</Notification> : null}
            {!totalExpanded ?
                <div className="requisition-history-list">
                    {mergedHistory.length  ? mergedHistory.map((entry, idx) => (
                        <SidePanelHistoryEntry key={idx} entry={entry} />
                    )) : (
                        <Notification color="warning">Historia vacía</Notification>
                    )}
                </div>
                :
                <div className="requisition-history-expanded">
                    <ul className="striped-list">
                        {mergedHistory.length ? mergedHistory.map((entry, idx) => (
                            <MaximizedHistoryEntry key={idx} entry={entry} />
                        )) : (
                            <Notification color="warning">Historia vacía</Notification>
                        )}
                    </ul>
                </div>
            }
        </>
    );
}


function SidePanelHistoryEntry({entry: {date, user: {name}, status, option, reason}}) {
    return (<div className="entry">
        <div className='timestamp'>{date.format(DATETIME_FORMAT)}</div>
        <div className='username'>{name}</div>
        <div className="action">{status}</div>
        {option ? <div className="option">{option}</div> : null }
        {reason && reason.trim() ? <div className="reason">{reason}</div> : null}
    </div>);
}


function MaximizedHistoryEntry({entry: {date, status, reason}}) {
    return (<li className="requisition-history">
        <div className='row'>
            <div className='col-lg-2'><span className="date-time"> {date.format(DATETIME_FORMAT)}</span></div>
            <div className='col-lg-10'>
                <span className="text">{status}</span>
                {reason ? ` - ${reason}` : null}
            </div>
        </div>
    </li>);
}

export default RequisitionHistory;
