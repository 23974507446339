import React, { useMemo } from 'react';
import { FormGroup } from "reactstrap";
import { useJnx } from '../../util/jnx';
import { useSideChannelSubscription } from '../../util/useSideChannel';
import getPathFromId from "../../util/getPathFromId";

function StaticTextBlurb(props) {
    const {
        formData,
        formContext,
        schema: { content, title, description, showTitle = false, tagType = 'label' },
        idSchema: { $id } = {},
        uiSchema: {
            'ui:showIf': showIfExpr = true,
        } = {},
    } = props;

    const {
        setFormDataValues,
        sideChannel,
        bindings: fcBindings,
    } = formContext;

    const path = useMemo(() => getPathFromId($id), [$id]);
    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}];

    const showIfJnx = useJnx(showIfExpr);
    const showIf = useMemo(() => (
        showIfJnx && showIfJnx.eval(rootFormData || {}, '', {
            root: rootFormData,
            formContext,
            formObject
        })
    ), [formData, rootFormData, formContext, formObject]);

    const DynamicLabel = ({ title }) => {
        return React.createElement(
            tagType,
            { id: "root_htmlEditor__title" },
            title
        );
    };

    const html = content || formData;

    return (
        showIf ? <FormGroup>
            {title && showTitle && <DynamicLabel title={title} />}
            {description && <div className="field-description">{description}</div>}
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </FormGroup> : null
    );
}

export default StaticTextBlurb;