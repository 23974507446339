import { useCallback, useMemo, useRef, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import InputMask from 'react-input-mask';
import { getAvailableKey, isRequired } from "./HideObjectField";


function CompositeInputField(props) {
    const {
        disabled, readonly,
        formData,
        onBlur, onFocus,
        onChange,
        uiSchema, errorSchema, idSchema, schema,
    } = props;
    const { title, properties: childFields, layout: schemaLayout } = schema;
    const { $id } = idSchema;
    
    const layout = useMemo(() => (schemaLayout || Object.keys(childFields)).map(
        row => (Array.isArray(row) ? row : [row]).map(name => [name, childFields[name]])
    ), [schemaLayout, childFields]);

    const formDataRef = useRef();
    formDataRef.current = formData;

    function onPropertyChange(name, value, propErrorSchema) {
        const newFormData = { ...formDataRef.current };
        newFormData[name] = value;
        onChange(
            newFormData,
            propErrorSchema && errorSchema && { ...errorSchema, [name]: propErrorSchema }
        );
    }

    const isReadOnly = disabled || readonly;

    return (<FormGroup disabled={disabled}>
        {title !== " " ? <label className="control-label" htmlFor={$id}>
            {title}
        </label> : null}
        <div className={`form-control composite-input-field ${isReadOnly ? 'readonly' : ''}`}>
            {layout.map((row, rowIdx) => (<div key={rowIdx} className="composite-input-field-row">{row.map(([name, fieldDef]) => (
                <CompositeFieldComponent
                    key={name}
                    required={isRequired(name, schema, false, uiSchema)}
                    schema={fieldDef}
                    errorSchema={errorSchema && errorSchema[name]}
                    idSchema={idSchema[name]}
                    formData={(formData || {})[name]}
                    onChange={(value, errorSchema) => onPropertyChange(name, value, errorSchema)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    disabled={disabled}
                    readonly={readonly}
                />
            ))}</div>))}
        </div>
    </FormGroup>);
}


function CompositeFieldComponent({
    required,
    schema: {
        title,
        type,
        format,
        mask,
        prefix,
        suffix,
        size,
    },
    errorSchema,
    idSchema: {$id},
    formData,
    onChange,
    onBlur,
    onFocus,
    disabled,
    readonly,
}){
    const {
        inputMask,
        inputType,
    } = useMemo(() => {
        if (type === "integer" || type === "number") return {inputType: "number"};
        if (type === "string"){
            if (/^(date|email)$/.test(format)) return {inputType: format};
            if (format === 'phone') return {inputMask: "(999) 999-9999"};
            if (mask) return {inputMask: mask};
        }

        return "text";
    }, [type, format]);

    return (<>
        {prefix || null}
        {readonly ? <div>{formData}</div> :  <div className={`field ${errorSchema ? 'has-errors' : ''}`}>
            {inputMask ? (
                <InputMask
                    className="form-control"
                    id={$id}
                    data-cy={$id}
                    required={required}
                    disabled={disabled}
                    mask={inputMask}
                    value={formData || ""}
                    placeholder={required && title ? `${title}*` : title}
                    onChange={({target: {value}}) => onChange(value)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    readOnly={readonly}
                />
            ) : (
                <Input 
                    id={$id}
                    data-cy={$id}
                    required={required}
                    disabled={disabled}
                    value={formData || ""}
                    size={size}
                    type={inputType}
                    placeholder={required && title ? `${title}*` : title}
                    onChange={({target: {value}}) => onChange(value)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
            )}
            {errorSchema ? <ErrorTag errorSchema={errorSchema} /> : null}
        </div>}
        {suffix || null}
    </>);
}


function ErrorTag({
    errorSchema: {
        __errors
    } = {}
}){
    const error = useMemo(() => __errors.join(", "), [__errors]);
    return <div className="error-tag" title={error} />
}


export default CompositeInputField;