export default function leftPad(text, n, filler='0'){
    n = n | 0;
    text = `${text}`;

    if ((filler?.length |0) === 0) filler = '0';

    const list = Array.from(new Array(Math.max(0, n - text.length)), () => filler);
    list.push(text)

    const result = list.join('');
    const N = result.length;

    return result.substr(N - n, n);
}
