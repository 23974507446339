const jsonSchemas = {
    iterSchemaPath(path, schema, options) {
        const {
            schemaJsonPath,
        } = options || {};

        let currentSchema = schema;
        const components = path.split('.');
        const iterPath = [];

        const schemas = [[iterPath.join('.'), currentSchema]];
        while(components.length) {
            let component = components.shift();
            if (currentSchema) {
                if (currentSchema.type === "object") {
                    if (schemaJsonPath && component === 'properties') component = components.shift();
                    iterPath.push(component);
                    currentSchema = currentSchema.properties[component];
                } else if (currentSchema.type === "array") {
                    currentSchema = currentSchema.items;
                    iterPath.push('items');
                } else {
                    currentSchema = null;
                }
    
                if (currentSchema) schemas.push([iterPath.join('.'), currentSchema]);
            }
        };

        return schemas;
    },

    collectSchemaChainFor(path, schema, options) {
        return jsonSchemas.iterSchemaPath(path, schema, options).map(x => x[1]);
    },

    getSubSchemaForField(path, schema, options) {
        return jsonSchemas.iterSchemaPath(path, schema, options).pop()[1];
    }
};


export default jsonSchemas;