import Home from './views/Home';
import Login from './views/Login';
// import ViewCurrentRequisition from './views/ViewCurrentRequisition';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
// import CreateUser from "./views/CreateUser";
import RequisitionInbox from "./views/requisition/RequisitionInbox";
import NewRequisition from "./views/requisition/NewRequisition";
import ViewRequisition from "./views/requisition/ViewRequisition";
import RequisitionDetails from "./views/requisition/RequisitionDetails";
import RequisitionLog from "./views/requisition/RequisitionLog";
import CreateNewUser from "./views/users/CreateNewUser";
import UsersTable from './views/users/UsersTable';
import EditUser from './views/users/EditUser';
import Profile from './views/Profile';
import EditOrganizationalUnit from './views/SystemSettings/OrganizationalUnit/EditOrganizationalUnit';
import CreateNewOrganizationalUnit from './views/SystemSettings/OrganizationalUnit/CreateNewOrganizationalUnit';

import SystemSettings from './views/SystemSettings';

// import forms from "./views/forms/forms";
import forms from './views/forms/forms';
import RequisitionNotes from "./views/requisition/RequisitionNotes";
import { Help } from "./views/Help";
import { CatalogPublic } from './views/catalogPublic';
import RequisitionDraftInbox from './views/requisition/RequisitionDraftInbox';
import RequisitionArchivedInbox from './views/requisition/RequisitionArchivedInbox';
import EditIntegration from './views/SystemSettings/Integrations/EditIntegration';
import IntegrationUser from './views/users/IntegrationUser';
import IntegrationUserTest from './views/users/test/IntegrationUserTest';
import ProcessLogin from './views/integration/ProcessLogin';
import TicketPage from './views/TicketPage';
import UserAccessForm from './views/UserAccessForm';
import CreateIntegration from './views/SystemSettings/Integrations/CreateIntegration';


const routes = {

    '/process-login': { component: ProcessLogin, excludeFromProfileCheck: true },
    '/login': { component: Login, default: 1, excludeFromProfileCheck: true },
    '/forgot-password': { component: ForgotPassword, excludeFromProfileCheck: true },
    '/reset-password/:token': { component: ResetPassword, excludeFromProfileCheck: true },
    '/register': { component: ResetPassword, excludeFromProfileCheck: true },

    '/': { exact: true, component: Home, default: 10 },
    '/workflow/:entityType/draft': { exact: true, component: RequisitionDraftInbox, requires: 'login' },
    '/workflow/:entityType/archived': { exact: true, component: RequisitionArchivedInbox, requires: 'login' },
    '/workflow/:entityType/tray': { exact: true, component: RequisitionInbox, requires: 'login' },
    '/workflow/:entityType/new': { exact: true, component: NewRequisition, requires: 'login' },
    '/workflow/:entityType/:id': { exact: true, component: RequisitionDetails, requires: 'login' },
    '/workflow/:entityType/:id/view': { exact: true, component: ViewRequisition, requires: 'login' },
    '/requisitions/:id/log': { exact: true, component: RequisitionLog, requires: 'login' },
    '/requisitions/:id/notes': { exact: true, component: RequisitionNotes, requires: 'login' },

    '/public-catalog/': { exact: true, component: CatalogPublic, requires: 'login' },

    ...(Object.entries(forms).reduce((_, [path, component]) => {
        _[`/${path}`] = {
            component,
        };
        return _;
    }, {})),

    '/users/create': { component: CreateNewUser, exact: true, requires: 'login' },
    '/users/:id': { component: EditUser, exact: true, requires: 'login' },
    '/users': { component: UsersTable, exact: true, requires: 'login' },

    '/integration/user/:jediid/settings': { component: IntegrationUser, exact: true, excludeFromProfileCheck: true },
    '/test/integration/users/iframe': { component: IntegrationUserTest, exact: true },

    '/profile': { component: Profile, exact: true, requires: 'login', excludeFromProfileCheck: true },

    '/system-settings': { component: SystemSettings, exact: true, requires: 'login' },
    '/system-settings/:setting': { component: SystemSettings, requires: 'login' },
    '/support': { component: Help, requires: 'login' },

    // organizationalUnits
    '/organizationalunits/create': { component: CreateNewOrganizationalUnit, exact: true, requires: 'login' },
    '/organizationalunits/:id': { component: EditOrganizationalUnit, requires: 'login' },
    '/integrations/create': { component: CreateIntegration, requires: 'login' },
    '/integrations/:id': { component: EditIntegration, requires: 'login' },
    '/create-ticket': {component: TicketPage, requires: 'login'},
    '/user-access-form': {component: UserAccessForm}
}


export default routes;