import { Trans } from '@lingui/react';
import React, { useState } from 'react';
import { useParams } from "react-router-dom";

import useResourceLoader from '../../util/useResourceLoader';
import RequisitionsApi from '../../api/RequisitionsApi';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';

import RequestReloadContext from '../../context/RequestReloadContext';

import CurrentRequisitionForm from "../../blc/CurrentRequisitionForm";
import InternalLayout from '../../components/layouts/InternalLayout';
import FormApi from '../../api/FormApi';
import RequisitionUWEFormComponent from '../../blc/RequisitionUWEFormComponent';
import WorkflowApi from '../../api/WorkflowApi';


export function ViewRequisition() {
    const { id, entityType: slug } = useParams();

    const [reloadCount, setReloadCount] = useState(0);

    const [currentRequisition, loadingRequisition, errorLoadingRequisition] = useResourceLoader(
        () => RequisitionsApi.getRequisition({ requisitionId: id }),
        [reloadCount], null);

    const [form, loadingForm, errorLoadingForm] = useResourceLoader(async () => {
        if (!currentRequisition) return;
        const workflowInfo = currentRequisition?.workflowId ? await WorkflowApi.getWorkflowInfoForId({ id: currentRequisition.workflowId }) : await WorkflowApi.getWorkflowInfoForSlug({ slug });
        return FormApi.getByName({ name: workflowInfo.viewForm })
    }, [slug, currentRequisition], {});

    const loading = loadingRequisition || loadingForm;
    const error = errorLoadingRequisition || errorLoadingForm;

    const rrcState = RequestReloadContext.useHook(() => {
        console.log('rrcState hook', reloadCount);
        setReloadCount(reloadCount + 1);
    }, [reloadCount]);

    return <InternalLayout>{(loading ? (
        <Loader fullscreen />
    ) : (error ? (
        <Notification color="danger">{JSON.stringify(error)}</Notification>
    ) : (!currentRequisition ? (
        <Notification color="danger">Requisición no encontrada. Esto debe ser un error, por favor contacte a la administraci&oacute;n).</Notification>
    ) : (<RequestReloadContext.Provider value={rrcState}>
        <RequisitionUWEFormComponent readOnly
            slug={slug}
            currentRequisition={currentRequisition} form={form}
        />
    </RequestReloadContext.Provider>))))}</InternalLayout>;
}


export default ViewRequisition;