import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router';
import IntegrationsApi from "../../../api/IntegrationsApi";
import FormComponent from '../../../components/FormComponent';
import InternalLayout from '../../../components/layouts/InternalLayout';
import { FORM_UPDATE_SUBMIT_BUTTON } from '../../../constants';
import parseFormDefinition from '../../../util/parseFormDefinition';

export const EditIntegrationForm = FormComponent({
    ...parseFormDefinition({
        page: 'EditIntegration',
        title: "Integracion",
        schema: {
            general: {
                title: "Integración",
                type: "object",
                ":classNames": "grid-2-columns",
                properties: {
                    name: {
                        title: "Nombre",
                        type: "string",
                        "map:field": "name"
                    },
                    enabled: {
                        title: "Activo",
                        type: "boolean",
                        "ui:field": "BoolYesNoField",
                        "map:field": "enabled"
                    },
                }
            },
            internal: {
                title: "Integración Interna",
                "ui:expandable": true,
                ":classNames": "grid-2-columns",
                type: "object",
                "ui:field": "HideObjectField",
                "ui:newSection": true,
                properties: {
                    box: {
                        title: " ",
                        type: "object",
                        properties: {
                            secret: {
                                title: "Clave Secreta",
                                type: "string",
                                "map:field": "internalSecret"
                            },
                            btns: {
                                title: " ",
                                type: "object",
                                ":classNames": "flex-h spaced",
                                properties: {
                                    regenBtn: {
                                        title: "Regenerar Clave",
                                        icon: "fa fa-key",
                                        type: "string",
                                        "ui:onClick": [
                                            "$set({'internal.box.secret': (",
                                            "   $s := $charSet('wW0!');",
                                            "   $range(64) ~> $map(function($i) { $sample($s) }) ~> $join",
                                            ")})"
                                        ],
                                        "ui:field": "Button"
                                    },
                                    copyBtn: {
                                        title: "Copiar Clave",
                                        icon: "fa fa-copy",
                                        type: "string",
                                        "ui:onClick": [
                                            "(",
                                            "   $copy(internal.box.secret);",
                                            "   $alert('Clave Copiada')",
                                            ")"
                                        ],
                                        "ui:field": "Button"
                                    }
                                }
                            }
                        }
                    },
                    permissions: {
                        title: "Permisos",
                        ":classNames": "from-col-2-size-1 from-row-1-size-2",
                        type: "array",
                        "ui:field": "LookupMultiSelectCheckboxesFormField",
                        "map:field": "permissions",
                        lookup: {
                            resource: "Permisos",
                            api: "integration/permissions",
                            id: "name",
                            label: "description"
                        },
                        items: {
                            type: "string"
                        }
                    },
                    // https://jedi-dev-web.azurewebsites.net/#/Authenticate/Logout
                    logOutUrl: {
                        title: "URL de redireccion para logout",
                        type: "string",
                        "map:field": "logOutUrl",
                        "ui:showIf": {"expr": "'loginAndRedirectUser' in permissions", "scope": "."}
                    }
                }
            },
            external: {
                title: "Integración Externa",
                "ui:expandable": true,
                ":classNames": "grid-2-columns",
                type: "object",
                properties: {
                    externalEndpoint: {
                        type: "string",
                        ":classNames": "from-col-1-size-1",
                        title: "Endpoint externo",
                        "map:field": "externalEndpoint"
                    },
                    externalSecret: {
                        type: "string",
                        ":classNames": "from-col-1-size-1",
                        title: "Secreto externo",
                        "ui:placeholder": "Entre un secreto nuevo",
                        "map:field": "externalSecret"
                    },
                    externalApi: {
                        title: "API Externo",
                        type: "string",
                        ":classNames": "from-col-1-size-2",
                        "ui:options": {rows: 7},
                        format: "textarea",
                        "map:field": "externalApi",
                        "akc:validate": "$not($isJson($value)) ? 'API Externo debe ser un JSON' : false"
                    },
                }
            }
        },
    }),
    withoutNavigation: true,
    parseProps: () => ({
        params: useParams()
    }),
    loadData: ({ params: { id } }) => IntegrationsApi.getAppIntegration({ id }),
    async onSubmit({ object }, { props: { params: { id } } }) {
        await IntegrationsApi.updateAppIntegration({ id, ...object });
    },
    submitButtons: FORM_UPDATE_SUBMIT_BUTTON,
    renderFormSubmitted: () => (
        <Redirect to="../system-settings/integrations" />
    )
});

export default function() {
    return <InternalLayout><EditIntegrationForm /></InternalLayout>;
};