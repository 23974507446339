import RJSFForm from "@rjsf/core/lib/components/Form";
import { deepEquals } from "@rjsf/core/lib/utils";


export default class Form extends RJSFForm {
    UNSAFE_componentWillReceiveProps(nextProps) {
        const nextState = this.getStateFromProps(nextProps, nextProps.formData);

        const nonFdStateChanged = !deepEquals({...nextState, formData:null}, {...this.state, formData:null});
        const propsFdChanged = !deepEquals(this.props.formData, nextProps.formData);

        if (!nonFdStateChanged && !propsFdChanged) return;

        const nextFdChanged = !deepEquals(nextState.formData, nextProps.formData);
        const stateFdChanged = !deepEquals(nextState.formData, this.state.formData);

        if (
            nextFdChanged &&
            stateFdChanged &&
            this.props.onChange
        ) {
            this.props.onChange(nextState);
        }

        this.setState(nextState);
    }
};