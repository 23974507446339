import React, { useState } from 'react';
import { Alert } from 'reactstrap';


function Notification({ children, error, danger, ...props }) {
    const [show, setShow] = useState(true);

    if (error && !children) {
        props.color = error.alert || 'danger';
        children = error.message || `${error}`;
    }

    return (
        <Alert show={`${show}`} {...props} onClick={() => setShow()}>
            {children}
        </Alert>
    );
}


export default Notification;