// import { Trans } from '@lingui/react';
import React from 'react';
import { Redirect, useParams, useHistory } from 'react-router';
import UserApi from "../../api/UserApi";
import FormComponent from '../../components/FormComponent';
import InternalLayout from '../../components/layouts/InternalLayout';
import { FORM_UPDATE_SUBMIT_BUTTON } from '../../constants';
import OrganizationalUnitApi from '../../api/OrganizationalUnitApi';


export const makeUserSchema = ({
    title = "Usuario",
    showJediId = true,
    showPeopleSoftId = true,
    onlyEditUserData = false,
    hidePassword = false,
    hideEntityHead = false
} = {}) => ({
    ":classNames": "smallTwoRows",
    title,
    "ui:field": "HideObjectField",
    type: "object",
    properties: {
        firstName: { title: "Nombre", type: "string" },
        lastName: { title: "Apellido", type: "string" },
        ...(showJediId ? {
            jediId: {
                "ui:showIf": "$formContext.auth.user.permissions.ManageIntegrations",
                title: "Id en JEDI", type: "number",
                "ui:readonly": onlyEditUserData,
            }
        } : {}),
        ...(showPeopleSoftId ? {
            peopleSoftId: {
                "ui:showIf": "$formContext.auth.user.permissions.ManageIntegrations",
                title: "Id en PeopleSoft", type: ["string", "null"],
                "ui:readonly": onlyEditUserData,
            }
        } : {}),
        organizationalUnitId: {
            title: "Organización",
            type: "number",
            "ui:field": "LookupFormField",
            "ui:readonly": onlyEditUserData,
            ":classNames": "from-col-1-size-1",
            lookup: {
                api: 'organizationalunits?workable=1',
                resource: "Organizaciones",
                "parentId": "parentId",
                "collapsable": true,
                "collapseLevel": 1,
                id: 'id',
                label: 'name'
            },
        },
        position: { title: "Puesto", type: ["string", "null"] },
        accessProfileId: {
            title: "Tipo de Usuario",
            "ui:readonlyIf": "$isFalsy($formContext.auth.user.permissions.ManageUsersInUnit)",
            type: "number",
            lookup: {
                api: 'profiles',
                resource: "Tipos de Usuarios",
                id: 'id',
                label: 'displayName'
            },
            "ui:field": "LookupFormField",
            "ui:readonly": onlyEditUserData,
        },
        email: {
            title: "Email", type: "string",
            "ui:readonly": onlyEditUserData
        },
        phone: { title: "Teléfono", type: ["string", "null"] },
        extension: { title: "Extensión", type: ["string", "null"] },
        ...(hidePassword ? {} : {
            password: {
                title: "Password", type: "string",
                "ui:showIf": "$formContext.auth.user.permissions.ManageUsersInUnit",
                ":classNames": "from-col-1",
                "ui:widget": "password"
            },
            passwordConfirm: {
                title: "Confirm Password", type: "string",
                "ui:showIf": "$formContext.auth.user.permissions.ManageUsersInUnit",
                "ui:widget": "password",
                "akc:validate": "$isTruthy(password) and password != passwordConfirm ? 'es diferente.' : null"
            },
        }),
        signature: {
            title: "Firma", type: ["string", "null"],
            "ui:readonlyIf": !onlyEditUserData ? "$isFalsy($formContext.auth.user.permissions.ManageUsersInUnit)" : "false",
            "ui:field": "SignatureField"
        },
        isEntityHead: hideEntityHead ? {} : {
            "type": "boolean",
            "title": "¿Es Jefe de Entidad o su Representante Autorizado?"
        },
        addresses: {
            title: " ",
            description: "Direcciones de entrega",
            ":classNames": "from-col-1 to-col-3",
            type: "array",
            items: {
                type: "object",
                "ui:addable": true,
                "ui:orderable": true,
                "ui:removable": true,
                properties: {
                    line1: {
                        title: "Dirección Línea 1",
                        type: "string"
                    },
                    line2: {
                        "title": "Dirección Línea 2",
                        "ui:cellClassName": "text-center align-middle",
                        type: "string"
                    },
                    city: {
                        "title": "Ciudad",
                        "ui:cellClassName": "text-center align-middle",
                        type: "string"
                    },
                    state: {
                        "title": "Estado",
                        "ui:cellClassName": "text-center align-middle",
                        type: "string"
                    },
                    zipcode: {
                        "title": "Código Postal",
                        "ui:cellClassName": "text-center align-middle",
                        type: "string"
                    }
                }
            }
        },
        roles: {
            title: " ",
            description: "Roles",
            ":classNames": "from-col-1 to-col-3",
            type: "array",
            "ui:readonly": onlyEditUserData,
            items: {
                type: "object",
                "ui:addable": !onlyEditUserData,
                "ui:orderable": !onlyEditUserData,
                "ui:removable": !onlyEditUserData,
                properties: {
                    roleId: {
                        title: "Rol",
                        lookup: {
                            api: 'roles',
                            resource: "Roles",
                            id: 'id',
                            label: 'name'
                        },
                        items: { type: "number" },
                        "ui:field": "LookupFormField",
                        "ui:readonly": onlyEditUserData,
                        "ui:onSelect": {
                            "expr": [
                                "(",
                                "$path:= ($fieldPath ~>$pathJoin('..role')) & '.name';",
                                "$set({$path: $object.name});",
                                ")"
                            ],
                        }
                    },
                    canWorkAs: {
                        "title": "Puede Trabajar",
                        "ui:cellClassName": "text-center align-middle",
                        "type": "boolean"
                    },
                    canBeAssigned: {
                        "title": "Puede ser asignado",
                        "ui:cellClassName": "text-center align-middle",
                        "type": "boolean"
                    },
                    canAssign: {
                        "title": "Puede asignar",
                        "ui:cellClassName": "text-center align-middle",
                        "type": "boolean"
                    }
                }
            }
        },
        isOnLeave: {
            title: "Fuera de Oficina",
            "ui:field": "BoolYesNo",
            type: "boolean",
            ":classNames": "from-col-1",
            ...(!onlyEditUserData ? {} : {
                "ui:addOrganizationalUnitsToContext": true,
                "ui:showIfAsync": {
                    "scope": ".",
                    "expr": ["(",
                        "$orgId:=organizationalUnitId;",
                        "$paths:=$organizationalUnits[id=$orgId].path;",
                        "$search:= $searchOrganization($organizationalUnits,$paths, ['Departamento de Corrección y Rehabilitación']);",
                        "$isFalsy($search and $count(roles[role[name='Delegado Comprador']]) > 0)",
                        ")"]
                },
            }),
        },
        ...(onlyEditUserData ? {} : {
            isActive: {
                title: "Activo",
                type: "boolean",
                ":classNames": "from-col-2",
                "ui:readonlyIf": "$isFalsy($formContext.auth.user.permissions.ManageUsersInUnit)",
            }
        })
    },
    required: [
        "firstName",
        "lastName",
        "email",
        "organizationalUnitId",
        "accessProfileId"
    ],
    dependencies: {
        password: ["passwordConfirm"]
    }
});

export const EditUserForm = FormComponent({
    displayName: 'EditUser',
    withoutNavigation: true,
    schema: makeUserSchema(),
    uiSchema: {
        isActive: { "ui:field": "BoolYesNoField" },
        isEntityHead: {
            "ui:field": "BoolYesNo",
            "ui:addOrganizationalUnitsToContext": true,
            "ui:showIfAsync": {
                "expr": ["(",
                    "$orgId:=organizationalUnitId;",
                    "$result := $orgId ? $organizationalUnits[id=$orgId].worksWithEmergencies : false;",
                    ")"]
            }
        },
    },
    parseProps: () => ({
        params: useParams(),
        history: useHistory()
    }),
    loadData: ({ params: { id } }) => UserApi.getUser({ id }),
    async onSubmit({ formData }, { props: { params: { id } } }) {
        await UserApi.editUser({ id, ...formData });
    },
    submitButtons: {
        ...FORM_UPDATE_SUBMIT_BUTTON,
        impersonate: {
            text: "Impersonar", className: "btn-secondary", "onBeforeSubmit": async (submitEvent) => {
                submitEvent.preventDefault();

                const {
                    formContext: { auth },
                    formData: { id: userId },
                    scope: { props: { history } }
                } = submitEvent;

                await auth.impersonate({ userId });
                history.push("/");
            },
            "ui:showIf": "$formContext.auth.user.permissions.ImpersonatePeersOrSubordinates",
            ignoreValidation: true
        },
    },
    renderFormSubmitted: () => (
        <Redirect to="../system-settings/users" />
    )
});

export default function () {
    return <InternalLayout><EditUserForm /></InternalLayout>;
};
