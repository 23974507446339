import { useState } from "react";
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap";

const PARAMS = ["formData"];

function ToCompleteModal({
    formData,
    formContext,
    onConfirm: onClose,
    isOpen,
}) {
    const {
        setFormDataValues,
    } = formContext;

    const [toCompleteReason, setToCompleteReason] = useState("");
    const [error, setError] = useState("");

    function onChange({ target: { value } }) {
        setError("");
        setToCompleteReason(value);
    }

    function onAccept(e) {
        e.stopPropagation();

        if (!toCompleteReason) {
            setError("Debe ingresar un motivo para completar");
        } else {
            formData.toCompleteReason = toCompleteReason;
            onClose(true);
        }
    }

    return (
        <Modal isOpen={isOpen}
            toggle={() => onClose()}
            className="modal-primary"
            size='xl'
            backdrop
        >
            <ModalHeader
                toggle={() => onClose()}
            >
                Completar Requisición con Comprobante
            </ModalHeader>
            <ModalBody>
                <>
                    <div className="row">
                        <div className="col-lg-12">
                            <span className="text-danger">{error}</span>
                            <div className="form-group">
                                <label className="title">Motivo*</label>
                                <textarea rows={4} value={toCompleteReason || ""} className="form-control" name="description" onChange={e => onChange(e)} />
                            </div>
                        </div>
                    </div>
                </>
            </ModalBody >
            <ModalFooter>
                <Button onClick={onAccept} color="primary">Confirmar</Button>
                <Button onClick={() => onClose()} color="secondary">Cerrar</Button>
            </ModalFooter>
        </Modal >
    );
}


ToCompleteModal.PARAMS = PARAMS;


export default ToCompleteModal;