import React, { useState, useCallback, useMemo, useRef, useEffect } from "react";
import { Button, Table } from "reactstrap";
import { concatenatePaths, getObject } from "../../../util/mapObject";
import { useSideChannelSubscription } from "../../../util/useSideChannel";
import Notification from "../../Notification";
import getPathFromId from "../../../util/getPathFromId";
import { HEADER_COMPONENTS } from "../headers";
import { useJnx } from "../../../util/jnx";

import MakeArrayItem from "./MakeArrayItem";
import ArrayFieldDescription from "./ArrayFieldDescription";
import ArrayFieldTitle from "./ArrayFieldTitle";
import useCompiledPerRowConditionals from "./useCompiledPerRowConditionals";


function ArrayFieldTemplate(props) {
    const {
        $id,
        layout,
        filterRowJnx,
        formContext,
        hasAddBtn,
        headerOnEmpty,
        headers,
        itemsAreObjectType,
        itemsUiSchema,
        jnxOnAddAction,
        objectItemsSchema,
        onEmptyMessage,
        panels,
        perRowClassNamesIf,
        perRowShowColumnsIf,
        readonlyRowIfJnx,
        rootFormData,
        rowClassNames,
        overflowX,
        contentClass,
        hideTitle,
        expandable, expanded, toggleExpand,
        topButtonOnClick,
        topButton,
        showIf
    } = useArrayFieldTemplateHooks(props);


    return (showIf ?
        <fieldset className={props.className} id={props.idSchema.$id}>

            {!hideTitle ?
                <ArrayFieldTitle
                    key={`array-field-title-${props.idSchema.$id}`}
                    expandable={expandable}
                    expanded={expanded} toggleExpand={toggleExpand}
                    idSchema={props.idSchema}
                    title={props.uiSchema["ui:title"] || props.title}
                    required={props.required}
                /> : null}

            {expanded && topButton && Object.keys(topButton).length > 0 ? layout.wrapAddButton(
                <Button
                    className={`${topButton["ui:topButtonClass"] || ''}`}
                    color={`${topButton["ui:buttonColor"] || 'secondary'}`}
                    onClick={topButtonOnClick}
                >
                    {topButton["ui:topButtonText"] ? ` ${topButton["ui:topButtonText"]}` : null}
                </Button>
            ) : null}

            {(props.uiSchema["ui:description"] || props.schema.description) && (
                <ArrayFieldDescription
                    key={`array-field-description-${props.idSchema.$id}`}
                    DescriptionField={props.DescriptionField}
                    idSchema={props.idSchema}
                    description={
                        props.uiSchema["ui:description"] || props.schema.description
                    }
                />
            )}
            {expanded ? (<div className={contentClass || ''} style={{ overflowX: overflowX || "auto" }} >
                {layout.wrapArray(<>
                    {((props.items && props.items.length) || headerOnEmpty) && headers.length && layout.makeHeaders ? layout.makeHeaders() : null}
                    {layout.wrapBody(<>
                        {props?.items?.length ? props.items.map((p, rowIdx) => <MakeArrayItem
                            key={rowIdx}
                            $id={$id}
                            data-cy={$id}
                            props={p}
                            onAddClick={jnxOnAddAction || props.onAddClick}
                            layout={layout}
                            rowIdx={rowIdx}
                            rowCount={props.items.length}
                            formContext={formContext}
                            rootFormData={rootFormData}
                            filterRowJnx={filterRowJnx}
                            readonlyRowIfJnx={readonlyRowIfJnx}
                            itemsAreObjectType={itemsAreObjectType}
                            objectItemsSchema={objectItemsSchema}
                            panels={panels}
                            itemsUiSchema={itemsUiSchema}
                            showColumnsIf={perRowShowColumnsIf}
                            rowClassNames={rowClassNames}
                            rowClassNamesIf={perRowClassNamesIf}
                        />) : (onEmptyMessage ? layout.makeEmptyMessage() : null)}
                        {hasAddBtn ? layout.wrapAddButton(
                            <Button
                                className={`array-item-add ${itemsUiSchema["ui:buttonClass"] || ''}`}
                                color={`${itemsUiSchema["ui:buttonColor"] || 'primary'}`}
                                onClick={jnxOnAddAction || props.onAddClick}
                                disabled={props.disabled || props.readonly}
                                data-cy={`array-item-add-${$id}`}
                            >
                                <i className="fa fa-plus" />
                                {itemsUiSchema["ui:addButtonText"] ? ` ${itemsUiSchema["ui:addButtonText"]}` : null}
                            </Button>
                        ) : null}
                    </>)}
                </>)}
            </div>) : null}
        </fieldset> : null);
}

function useArrayFieldTemplateHooks(props) {
    const {
        formContext,
        schema,
        idSchema: { $id },
        uiSchema: {
            "ui:showheaderOnEmpty": headerOnEmpty,
            "ui:addAction": addAction,
            'ui:expandable': expandable,
            'ui:expandedDefault': expandedDefault = true,
            "ui:onEmptyMessage": onEmptyMessage,
            "ui:arrayType": arrayType = "table",
            "ui:onDataChanged": onDataChanged,
            "ui:hideTitle": hideTitle,
            "ui:showIf": showIfExpr = true,
            "ui:overflowX": overflowX,
            "ui:contentClass": contentClass,
            "ui:topButton": topButton = {},
            items: itemsUiSchema = {}
        } = {},
        formData,
    } = props;

    const [expanded, setExpanded] = useState(expandedDefault);
    function toggleExpand(e) {
        e.preventDefault();
        e.stopPropagation();
        setExpanded(!expanded);
    }


    const { sideChannel, openModal } = formContext;
    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}];
    const { setFormDataValues } = formContext;
    const {
        "ui:filterRow": filterRow,
        "ui:readonlyRowIf": readonlyRowIf,
    } = itemsUiSchema;
    const {
        items: {
            required: requiredColumns = []
        }
    } = schema;

    const showIfJnx = useJnx(showIfExpr);
    const showIf = useMemo(() => (
        showIfJnx && showIfJnx.eval(rootFormData || {}, '', {
            root: rootFormData,
            formContext,
            formObject
        })
    ), [formData, rootFormData, formContext, formObject]);

    const arrayRoute = useMemo(() => concatenatePaths(getPathFromId($id), ".."), [$id]);

    const addItems = useCallback((items) => {
        const path = getPathFromId($id);
        setFormDataValues({
            [path]: [...(formData || []), ...(items || [])]
        });
    }, [$id, formData]);

    const functionBinds = useMemo(() => ({
        set: setFormDataValues,
    }), [setFormDataValues]);

    const path = useMemo(() => getPathFromId($id), [$id]);

    const jnxOnAddAction = useJnx(addAction, {
        fn: ([event], addActionJnx) => {
            event.stopPropagation();
            addActionJnx.evalAsync(rootFormData, path, {
                openModal,
                formContext,
            }).then(result => {
                if (Array.isArray(result) && result.length > 0) {
                    addItems(result);
                }
            });
        }, functionBinds
    }, [openModal, addAction, formData, rootFormData, path]);


    const { topButtonOnClickExpr } = topButton;
    const topButtonOnClick = useJnx(topButtonOnClickExpr, {
        fn: ([event], topButtonOnClickJnx) => {
            event.stopPropagation();
            topButtonOnClickJnx.evalAsync(rootFormData, path, {
                openModal,
                formContext,
            }).then(result => {
                console.log(result)
            });
        }, functionBinds
    }, [openModal, addAction, formData, rootFormData, path]);

    const onDataChangedJnx = useJnx(onDataChanged, { functionBinds }, [functionBinds]);
    const onDataChangedfnRef = useRef();

    const formData2 = useMemo(() => getObject(rootFormData, path), [rootFormData, path]);

    onDataChangedfnRef.current = useCallback(() => {
        onDataChangedJnx.evalAsync(rootFormData, path, {
            openModal,
            formContext,
        });
    }, [$id, rootFormData, openModal, formContext]);

    useEffect(() => {
        if (onDataChangedJnx) {
            onDataChangedfnRef.current();
        }
    }, [formData2]);

    const filterRowJnx = useJnx(filterRow);
    const readonlyRowIfJnx = useJnx(readonlyRowIf);

    const [
        showColumnsIf,
        perRowShowColumnsIf,
    ] = useCompiledPerRowConditionals(itemsUiSchema["ui:showColumnsIf"]);

    const [
        classNamesIf,
        perRowClassNamesIf,
    ] = useCompiledPerRowConditionals(itemsUiSchema["ui:classNamesIf"]);


    const itemsAreObjectType = schema.type === "array" && schema.items.type === "object";
    const rowClassNames = useMemo(() => (classNamesIf ? Object.entries(classNamesIf).map(
        ([className, conditional]) => conditional.eval(rootFormData, arrayRoute) ? className : null
    ).filter(x => !!x).join(' ') : ''), [classNamesIf, rootFormData]);

    const rowProperties = useMemo(() => Object.entries(schema?.items?.properties || {}).reduce((_, [k, v]) => {
        const showIf = showColumnsIf[k];
        if (showIf && !showIf.eval(rootFormData, arrayRoute)) {
            return _;
        }
        if (itemsUiSchema[k] && itemsUiSchema[k]["ui:newRow"]) {
            _.panels.push({ field: k, schema: v });
        } else {
            _.primary[k] = v;
        }
        return _;
    }, {
        primary: {},
        panels: []
    }), [schema?.items?.properties, rootFormData]);

    const headers = useMemo(() => Object.entries(rowProperties.primary || {}).map(([k, v]) => {
        const {
            "ui:noColumnTitle": noColumnTitle,
            "ui:columnHeader": columnHeader,
        } = itemsUiSchema[k] || {}

        if (columnHeader) {
            const Component = HEADER_COMPONENTS[columnHeader];
            if (Component) return { field: k, Component };
        }
        if (noColumnTitle) return { field: k, text: "" };

        const required = requiredColumns.indexOf(k) >= 0;

        return { field: k, text: `${v?.title || k}${required ? '*' : ''}` };
    }), [rowProperties.primary])

    const objectItemsSchema = useMemo(() => (!itemsAreObjectType ? {} : {
        ...(schema.items || {}),
        properties: Object.entries(rowProperties.primary).reduce((_, [k, v]) => {
            v = { ...v };
            if (!(itemsUiSchema[k] || {})["ui:noColumnTitle"] && arrayType === 'table') {
                v.title = " ";
            }
            _[k] = v;
            return _;
        }, {})
    }), [itemsAreObjectType, rowProperties.primary, arrayType]);

    const panels = rowProperties.panels;

    const hasAddBtn = props.canAdd && (itemsUiSchema['ui:addable'] !== false);
    const hasAddBtnInRow = props.canAdd && (itemsUiSchema['ui:addableFromRow'] === true);
    const {
        "ui:orderable": orderable,
        "ui:removable": removable,
        "ui:buttons-bottoms-classNames": buttonBottomsClassNames
    } = itemsUiSchema || {};
    const hasMoveBtns = (orderable !== false) && (props.hasMoveUp || props.hasMoveDown);
    const hasRemoveBtn = removable !== false && props.hasRemove;
    const hasToolbar = (props.items[0] || {}).hasToolbar && (hasMoveBtns || hasRemoveBtn)

    const layout = useMemo(() => {
        if (arrayType === 'cards') {
            return {
                isTable: false,
                wrapArray: (children) => (<div className="array-cards">{children}</div>),
                wrapBody: (children) => (<div className="array-item-list" key={`array-item-list-${$id}`}>
                    {children}
                </div>),
                wrapItem: (id, props, onAddClick, rowIdx, rowCount, children, buttons, rowClassNames) => (<div id={id} className={`${props.className} ${itemsAreObjectType ? 'for-object-row' : ''} ${rowClassNames}`} key={rowIdx}>
                    {children}
                    {buttons ? (<div className={buttonBottomsClassNames || "array-item-buttons col-xs-3 array-item-toolbox"} style={buttonBottomsClassNames ? null : { width: '120px' }}><div
                        className="btn-group"
                        style={buttonBottomsClassNames ? null : { display: "flex", justifyContent: "center" }}
                    >
                        {buttons}
                    </div></div>) : null}
                </div>),
                wrapItemField: ({
                    name, uiSchema
                }, children) => (<div
                    key={name}
                    className={(uiSchema[name] || {})['ui:cellClassName']}
                >
                    {children}
                </div>),
                wrapItemPanelRow: ({
                    columnCount
                }, children) => (<div className={`array-item panel-row ${rowClassNames}`}>
                    {children}
                </div>),
                wrapItemErrorList: (children) => (<div>
                    {children}
                </div>),
                makeEmptyItemField: () => (<td />),
                makeSimpleItem: (props) => (<div className={hasToolbar ? "col-xs-9" : "col-xs-12"}>{props.children}</div>),
                wrapAddButton: (children) => (<div>
                    {children}
                </div>),
                makeEmptyMessage: () => (
                    <div><Notification color="warning">{onEmptyMessage}</Notification></div>
                ),
                makeHeaders: () => (null)
            };
        } else {
            return {
                isTable: true,
                wrapArray: (children) => (<Table>{children}</Table>),
                wrapBody: (children) => (<tbody className="array-item-list" key={`array-item-list-${$id}`}>
                    {children}
                </tbody>),
                wrapItem: (id, props, onAddClick, rowIdx, rowCount, children, buttons, rowClassNames) => (<tr id={id} className={`${props.className} ${itemsAreObjectType ? 'for-object-row' : ''} ${rowClassNames}`} key={rowIdx} >
                    {children}
                    {buttons ? (<td className="col-xs-3 array-item-toolbox" style={{ width: '35px' }}><div
                        className="btn-group"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        {buttons}
                    </div></td>) : null}
                </tr>),
                wrapItemField: ({
                    name, uiSchema
                }, children) => (<td
                    key={name}
                    className={(uiSchema[name] || {})['ui:cellClassName']}
                    colSpan={(uiSchema[name] || {})['ui:colSpan']}
                >
                    {children}
                </td>),
                wrapItemPanelRow: ({
                    columnCount,
                    rowClassNames
                }, children) => (<tr className={`array-item panel-row ${rowClassNames}`}><td className='no-bt no-pb' colSpan={columnCount}>
                    {children}
                </td></tr>),
                wrapItemErrorList: (children) => (<tr><td colSpan={headers?.length || 1}>
                    {children}
                </td></tr>),
                makeEmptyItemField: () => (<td />),
                makeSimpleItem: (props) => (<td className={hasToolbar ? "col-xs-9" : "col-xs-12"}>{props.children}</td>),
                wrapAddButton: (children) => (<tr><td colSpan={headers?.length || 1}>
                    {children}
                </td></tr>),
                makeEmptyMessage: () => (
                    <tr><td colSpan={headers?.length || 1}><Notification color="warning">{onEmptyMessage}</Notification></td></tr>
                ),
                makeHeaders: () => {
                    return (<thead>
                        <tr className={`${itemsAreObjectType ? 'for-object-row' : ''}`}>
                            {hasAddBtnInRow ? <th /> : null}
                            {headers.map(({ field, Component, text }, idx) => (
                                <th
                                    key={idx}
                                    className={(itemsUiSchema[field] || {})['ui:cellClassName']}
                                >
                                    {Component ? (
                                        <Component field={field} {...props} />
                                    ) : (text)}</th>
                            ))}
                            {hasToolbar ? (<th />) : null}
                        </tr>
                    </thead>)
                }
            };
        }
    }, [
        arrayType,
        $id,
        itemsUiSchema,
        hasToolbar,
        itemsAreObjectType,
        headers, onEmptyMessage
    ]);

    return {
        $id,
        layout,
        filterRowJnx,
        formContext,
        hasAddBtn,
        hasAddBtnInRow,
        hasToolbar,
        headerOnEmpty,
        headers,
        itemsAreObjectType,
        itemsUiSchema,
        jnxOnAddAction,
        objectItemsSchema,
        onEmptyMessage,
        panels,
        perRowClassNamesIf,
        perRowShowColumnsIf,
        readonlyRowIfJnx,
        rootFormData,
        rowClassNames,
        overflowX,
        contentClass,
        hideTitle,
        topButton,
        topButtonOnClick,
        expandable, expanded, toggleExpand,
        showIf
    };

}


export default ArrayFieldTemplate;