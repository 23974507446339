import React, { useMemo, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { useJnx } from "../../util/jnx";
import LookupApi from "../../api/LookupApi";

function BoolYesNoField(props) {
    const {
        formData,
        disabled,
        formContext,
        formContext: {
            setFormDataValues,
            sideChannel
        },
        idSchema: { $id },
        schema: { title },
        uiSchema: {
            'ui:readonly': uiReadonly,
            'akc:requiredIfVisible': requiredIfVisible,
            'ui:hideAttr': uiHideAttr,
            'ui:hideOnEmpty': hideOnEmpty,
            'ui:readonlyIf': uiReadonlyIfExpr,
            'ui:showIfAsync': showIfAsyncExpr,
            'ui:addOrganizationalUnitsToContext': addOrganizationalUnitsToContext = false
        },
        readonly: propReadonly,
        required,
        onChange: propOnChange
    } = props;


    const rootFormData = useSideChannelSubscription(sideChannel, 0);
    const readonlyIfJnx = useJnx(uiReadonlyIfExpr);
    const readOnlyIf = useMemo(() => (
        readonlyIfJnx && readonlyIfJnx.eval(rootFormData || {}, '', {
            root: rootFormData,
            formContext,
        })
    ), [formData, rootFormData, formContext]);

    const requiredField = !!required || !!requiredIfVisible
    const readonly = uiReadonly || propReadonly || readOnlyIf;

    const map = { true: 1, false: 0 };

    function onChange({ target: { value } }) {
        propOnChange(value === "1");
    }

    if (uiHideAttr && readonly) {
        return null;
    }

    const organizationalUnits = useMemo(async () => {

        if (!addOrganizationalUnitsToContext) return [];

        const resp = await LookupApi.getOrganizationalUnits("", true);
        return resp;

    }, [addOrganizationalUnitsToContext]);

    const showIfAsyncJnx = useJnx(showIfAsyncExpr);

    const [showIfAsyncResult, setShowIfAsyncResult] = useState(true);

    const showIfAsync = useMemo(async () => {

        if (!showIfAsyncJnx) return false;

        const result = await showIfAsyncJnx.evalAsync(rootFormData || {}, '', {
            root: rootFormData,
            formContext,
            organizationalUnits
        });

        setShowIfAsyncResult(result);

    }, [showIfAsyncJnx, rootFormData, formContext, organizationalUnits]);

    return (
        showIfAsyncResult ?
            <>
                <FormGroup disabled={readonly || disabled}>
                    {title.trim() ? (<label className="control-label" htmlFor={$id}>
                        {title}{requiredField ? <span className="required">*</span> : null}
                    </label>) : null}
                    <Input
                        id={$id}
                        data-cy={$id}
                        type="select"
                        disabled={readonly || disabled}
                        required={requiredField}
                        value={formData in map ? map[formData] : 2}
                        onChange={onChange}
                    >
                        <option value="2" disabled>---</option>
                        <option value="1">Sí</option>
                        <option value="0">No</option>
                    </Input>
                </FormGroup>
            </> : null
    );
}


export default BoolYesNoField;