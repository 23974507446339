import React, { useEffect, useRef, useState } from "react";
import Shape from "../../../components/SVGDrawer/shapes/Shape";

const STEP_TYPE_SHAPE_MAP = {
    form: 'rect-form',
    calculation: 'rect-calc',
    branch: 'diamond',
    notification: 'rect-message',
    documentGeneration: 'document',
    externalProcess: 'rect-bars',
    end: 'oval'
}

function WorkflowStep({
    stepdef,
    onSetSize,
    onClick,
    onDrag,
    selected,
}) {
    const {
        title,
        x, y, name, size, type
    } = stepdef;

    
    return (<Shape
        className="step"
        x={x} y={y}
        shape={STEP_TYPE_SHAPE_MAP[type] || 'rect-form'}
        width={size?.width} height={size?.height}
        selected={selected}
        onClick={onClick}
        onDrag={onDrag}
        onSetSize={onSetSize}
        content={title}
    />);
}


export default WorkflowStep;