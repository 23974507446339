import { property } from 'lodash';
import React, { useMemo } from 'react';
import {
    Button
} from "reactstrap";
import { splitPath } from '../../util/mapObject';

import { matchesPattern } from '../../util/patternMatching';
// import { ensureMatches, matchesPattern } from '../../util/patternMatching';
import { PROPERTIES } from './Constants';
import ObjectEditorField from './ObjectEditorField';
import StringField from './ObjectEditorField/StringField';

// // from radio -> object
// const s1 = {type: "string", "enum": [], "ui:widget": "radio"};
// // after clean
// const s1 = {type: "string"};
// // after ensure matches
// const s1 = {type: "object"};


const FIELD_MAP = [];
const FIELD_MAP_ATTRS = Object.keys(FIELD_MAP.reduce((_, entry) => {
    Object.keys(entry.pattern || {}).forEach(k => { _[k] = 1 });
    return _;
}, {}));

// function cleanupState(state, previousPattern, targetPattern){
//     Object.keys(previousPattern).filter(key => !(key in targetPattern)).forEach(key => {
//         delete state[key];
//     });
// }


function ObjectEditor({
    path, setPath,
    object,
    onChange: propOnChange,
    setArray,
    scope,
    fieldMap,
    fieldMapAttrs
}) {
    const [parentPath, name] = useMemo(() => splitPath(path), [path]);
    const editObject = useMemo(() => ({ ...object, __name__: name }), [object, name]);

    if(!object?.type){
        object.type = "string";
    }

    const structure_mapping = useMemo(() => {
        const mapping = fieldMap.filter(x => matchesPattern(object, x.pattern))[0];
        console.log("mapping", mapping);
        return mapping;
    }, fieldMapAttrs.map(attr => object[attr]));
    object.structure = structure_mapping.structure;
    const structureProps = useMemo(() => structure_mapping.properties.sort((a, b) => (a.order | 0) - (b.order | 0)), [structure_mapping.properties]);

    // object.structure = structure_mapping.structure;

    function onChange(prop, value) {
        const name = prop.property;

        if (name === "structure") {
            if (value === "Dropdown" || value === "Text" || value === "Radio" || value === "Button" || value === "QrRead") {
                object.type = "string";
            } else if (value === "Object") {
                object.type = "object";
            } else if (value === "Boolean") {
                object.type = "boolean";
            } else if (value === "Array") {
                object.type = "array";
            } else if (value === "Number" || value === "File") {
                object.type = "number";
            } else if (value === "Signature") {
                object.type = ["string", "null"];
            }
        } else if (name === "ui:field" && value === "LookupFormField" && !object?.lookup) {
            object.lookup = {
                "resource": "",
                "api": "",
                "id": "id",
                "label": "name",
                "params": {}
            };
        }

        const toChild = structureProps.filter(x => x.property === name && x.toChild)?.[0]?.toChild;
        const newObject = { ...object };

        if (toChild) {
            if (!newObject[toChild]) newObject[toChild] = setArray();
            newObject[toChild][name] = value;
        } else {
            newObject[name] = value;
            // newObject["ui:field"] = value === "boolean" && !checked ? null : name === "ui:field" ? value : object["ui:field"];
        }

        if (newObject.structure !== object.structure) {
            const newStruct = fieldMap.filter(x => x.structure === newObject.structure)[0];
            const prevStruct = fieldMap.filter(x => x.structure === object.structure)[0];
            // cleanupState(newObject, prevStruct.pattern, structure_mapping.pattern);
            // ensureMatches()
            if (prevStruct) prevStruct.cleanObject(newObject);
            if (newStruct) newStruct.initObject(newObject);
        }

        propOnChange(newObject);
    }

    return (<div className="container"><div className='row _formEditor'>
        <div className="col-lg-12">
            <StringField label="Name" property="name" value={name} onChange={value => setPath(parentPath ? `${parentPath}.${value}` : value)} />
        </div>
        {structureProps.map((prop, idx) => {
            return !prop.hidden && (<ObjectEditorField key={idx} id={idx}
                fieldProps={prop}
                scope={scope}
                object={object}
                value={object ? (
                    prop.toChild ? object[prop.toChild]?.[prop.property] : object[prop.property]
                ) : null}
                onChange={value => onChange(prop, value)}
            />)
        })}
    </div></div>);
}


ObjectEditor.HOC = function ObjectEditorHOC({
    name,
    fieldMap,
    buildScope,
}) {
    const fieldMapAttrs = Object.keys(fieldMap.reduce((_, entry) => {
        Object.keys(entry.pattern || {}).forEach(k => { _[k] = 1 });
        return _;
    }, {}));

    const useScopeBuilder = buildScope ? props => buildScope(props) : () => { };

    function Component(props) {
        const scope = useScopeBuilder(props);
        return <ObjectEditor
            {...props}
            scope={scope}
            fieldMap={fieldMap}
            fieldMapAttrs={fieldMapAttrs}
        />;
    }

    Component.displayName = name;

    return Component;
}

export default ObjectEditor;