import { Trans } from '@lingui/react';
import _ from "lodash";
import React, { useContext, useState, useCallback, useMemo } from 'react';
import {
    Table,
    Button,
    Col,
    Row,
    Card,
    CardHeader,
    CardFooter
} from 'reactstrap';

import { useHistory } from 'react-router';
import Tree from 'react-d3-tree';
import { AuthContext } from '../../../context/AuthContext';
import useResourceLoader from '../../../util/useResourceLoader';
import Loader from '../../../components/Loader';
import Notification from '../../../components/Notification';
import useTitle from '../../../util/useTitle';
import OrganizationalUnitApi from "../../../api/OrganizationalUnitApi";
import SortButton from "../../../components/SortButton";
import useSearchParams from '../../../util/useSearchParams';
import leftPad from '../../../util/leftPad';
import Pagination from '../../../components/Pagination';
import SearchBar from '../../../components/Search/SearchBar';
import { EditOrganizationalUnitForm } from './EditOrganizationalUnit';
import LookupApi from '../../../api/LookupApi';

const searchFormDefinition = (({ schema, uiSchema }) => ({
    schema: {
        type: "object",
        properties: {
            id: { title: "Id", type: "string" },
            name: { title: "Nombre", type: "string" },
        },
    },
    uiSchema: {
        "classNames": "smallThreeRows",
        "id": { "ui:field": "LookupFormField" },
        "name": { "ui:field": "LookupFormField" },
    },
}))(EditOrganizationalUnitForm.formDefinition);

const OU_TYPES = {
    "Agency": "Agencia",
    "Bureau": "Entidad Peticionaria",
    "WorkDivision": "División de Trabajo"
};


export function OrganizationalUnitsTable() {
    const history = useHistory()
    useTitle("Agencias");

    const { user: { permissions: { ManageOrganizationalUnits } = {} } = {} } = useContext(AuthContext) || {};

    const [showFilter, setShowFilter] = useState();

    const [
        [offset, setOffset],
        [size, setSize],
        [sortBy, setSortBy],
    ] = useSearchParams({
        offset: 0,
        size: 100,
        sortBy: 'id',
    });

    const [filters, setFilters] = useState({});

    const [paging, loadingOrganizationalUnits, error] = useResourceLoader(async () => {
        const organizationalunits = await OrganizationalUnitApi.searchOrganizationUnit({ offset, size, sortBy, ...filters });
        return Array.isArray(organizationalunits) ? {
            from: 0,
            to: organizationalunits.length,
            count: organizationalunits.length,
            items: organizationalunits
        } : organizationalunits;
    }, [size, offset, sortBy, filters]);

    const {
        from = 0,
        to = 0,
        count = 0,
        items: organizationalunits = [],
    } = paging || {};

    function onSearch(update) {
        setFilters({ ...filters, ...update });
        setShowFilter();
    }

    function onClearSearch() { setFilters({}); }

    function select(organizationalunit) {
        if (organizationalunit) history.push(`../organizationalunits/${organizationalunit.id}`);
    }




    const renderForeignObjectNode = ({
        nodeDatum: {
            data: { id, type, name },
            children,
            __rd3t,
        },
        toggleNode,
        foreignObjectProps
    }) => (
        <g className="organizational-unit-tree-node">
            <foreignObject {...foreignObjectProps}>
                <div>
                    <div className="type">{OU_TYPES[type]}</div>
                    <div className="body">
                        <h3>{name}</h3>
                    </div>
                    <div className="buttons">
                        {children && (
                            <button className="btn btn-secondary" onClick={toggleNode}>
                                {__rd3t.collapsed ? <i className="fa fa-caret-right" /> : <i className="fa fa-caret-down" />}
                            </button>
                        )}
                        <button className="btn btn-primary" onClick={() => history.push(`../organizationalunits/${id}`)}>
                            Editar
                        </button>
                    </div>
                </div>
            </foreignObject>
        </g>
    );

    const [containerRef] = useCenteredTree();
    const nodeSize = { x: 200, y: 200 };
    const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -100 };

    const [tab, setTab] = useState("table");

    const containerStyles = {
        width: "90vw",
        height: "100vh"
    };


    const [agencies, loadingTree, errorLoadingOptions] = useResourceLoader(() => {

        if (tab === "tree") {
            return LookupApi.getOrganizationalUnits({ "workable": "1" });
        }

        return [];
    }, [tab]);

    const tree = useMemo(() => {

        const rootNodes = [];
        const nodeMap = agencies?.reduce((_, data) => {
            _[data.id] = {
                name: data.id,
                data
            };
            return _;
        }, {}) || {};

        Object.values(nodeMap).forEach(node => {
            const parent = nodeMap[node.data.parentId];
            if (parent) {
                if (!parent.children) parent.children = []
                parent.children.push(node);
            } else {
                rootNodes.push(node);
            }
        });

        return rootNodes;
    }, [agencies]);

    return (<>
        {loadingOrganizationalUnits ? <Loader fullscreen /> : null}
        {error ? <Notification color="danger">{JSON.stringify(error)}</Notification> : null}
        <Card className={`inbox-card primary ${showFilter ? '' : 'no-bottom-gap'}`}>
            <CardHeader>
                <Row>
                    <Col xs="8">
                        <div className="title">Agencias</div>
                    </Col>
                    <Col sm="2"><div className="float-right">
                        {ManageOrganizationalUnits ? (
                            <Button color="primary" onClick={() => history.push("/organizationalunits/create")} >Registrar Agencia</Button>
                        ) : null}
                    </div></Col>
                    <Col sm="2"><div className="float-right">
                        {tab !== "tree" ? <Button color="secondary" onClick={() => setTab("tree")} ><i className="fa fa-network-wired" /> Ver Diagrama</Button> : <Button color="secondary" onClick={() => setTab("table")} ><i className="fa fa-table" /> Ver Tabla</Button>}
                    </div></Col>
                </Row>
            </CardHeader>
        </Card>
        <Card className={`inbox-card ${showFilter ? '' : 'square-top'}`}>
            {tab === "tree" ?
                loadingTree ? <Loader fullscreen /> :
                    tree.length ?
                        <div className="organizational-unit-tree" style={containerStyles} ref={containerRef}>
                            <Tree data={tree}
                                translate={{ x: 900, y: 37 }}
                                nodeSize={nodeSize}
                                separation={{ siblings: 1.5, nonSiblings: 2 }}
                                zoom={1}
                                renderCustomNodeElement={(rd3tProps) =>
                                    renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                                }
                                orientation="vertical"
                            />
                        </div> : null
                :
                <>
                    <Table>
                        <thead><tr>
                            <SortButton tag="th" sortKey="code" sortBy={sortBy} setSortBy={setSortBy}>Código</SortButton>
                            <SortButton tag="th" sortKey="name" sortBy={sortBy} setSortBy={setSortBy}>Nombre</SortButton>
                            <SortButton tag="th" sortKey="city" sortBy={sortBy} setSortBy={setSortBy}>Ciudad</SortButton>
                            <SortButton tag="th" sortKey="postalcode" sortBy={sortBy} setSortBy={setSortBy}>CodigoPostal</SortButton>
                            <SortButton tag="th" sortKey="state" sortBy={sortBy} setSortBy={setSortBy}>Estado</SortButton>
                            <td />
                        </tr></thead>
                        <tbody>{
                            (organizationalunits || []).map((organizationalunit, idx) => (
                                <OrganizationalUnitEntry key={idx} organizationalunit={organizationalunit} onSelect={select} />
                            ))
                        }</tbody>
                    </Table>
                    <CardFooter>
                        <div className="float-right">
                            <Pagination offset={from} count={count} size={size} setSize={setSize} setOffset={setOffset} />
                        </div>
                    </CardFooter>
                </>
            }
        </Card>
    </>);
}

export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
    const [translate, setTranslate] = useState(defaultTranslate);
    const [dimensions, setDimensions] = useState();
    const containerRef = useCallback((containerElem) => {
        if (containerElem !== null) {
            const { width, height } = containerElem.getBoundingClientRect();
            setDimensions({ width, height });
            setTranslate({ x: width / 2, y: height / 2 });
        }
    }, []);
    return [dimensions, translate, containerRef];
};


function OrganizationalUnitEntry({ organizationalunit, onSelect }) {
    const { id, name, numericCode, dependencyFigure, code, city, postalCode, state } = organizationalunit;
    return (<tr className="clickable">
        <td>{leftPad(numericCode, 4)}-{leftPad(dependencyFigure, 4)}</td>
        <td>{name}{code ? ` (${code})` : ''}</td>
        <td>{city}</td>
        <td>{postalCode}</td>
        <td>{state}</td>
        <td>
            <Button color="primary" onClick={() => onSelect(organizationalunit)}>Editar</Button>
        </td>
    </tr>)
}

export default OrganizationalUnitsTable;