import { useMemo, useEffect } from "react";
import useResourceLoader from "../util/useResourceLoader";
import fetchApi from "../util/fetchApi";
import checkErrors from "../api/checkErrors";
import { useSideChannelSubscription } from "../util/useSideChannel";
import { getObject, interpolate } from "../util/mapObject";
import Jnx, { useJnx } from "../util/jnx";

function useOptionsLookup({
    lookup,
    rootFormData,
    path
}) {
    const {
        api: propApi,
        apiExpr: propApiExpr,
        entity,
        jnx: apiResponseJnxExpr,
        params,
        options: fetchOptions,
        sortBy,
        method = 'get'
    } = lookup || {};

    const apiResponseJnx = useJnx(apiResponseJnxExpr);

    const paramsValues = useMemo(() => computeParamValues(rootFormData, params, path), [rootFormData, params, path]);
    const paramsString = useMemo(() => computeParamString(rootFormData, paramsValues), [rootFormData, paramsValues]);

    const [options, loadingOptions, errorLoadingOptions] = useResourceLoader(async () => loadOptionsData(
        propApi, apiResponseJnx, paramsValues, paramsString, fetchOptions, entity, method, sortBy
    ), [propApi, apiResponseJnx, paramsValues, paramsString, fetchOptions, entity, method, sortBy]);

    return {
        options,
        loadingOptions,
        errorLoadingOptions
    }
}

export function lookupOptions(lookup, rootFormData, path) {
    const {
        api: propApi,
        apiExpr: propApiExpr,
        entity,
        jnx: apiResponseJnxExpr,
        params,
        options: fetchOptions,
        sortBy,
        method = 'get'
    } = lookup || {};

    const apiResponseJnx = apiResponseJnxExpr ? new Jnx(apiResponseJnxExpr) : null;
    const apiJnx = propApiExpr ? new Jnx(propApiExpr) : null;

    const paramsValues = computeParamValues(rootFormData, params, path);
    const paramsString = computeParamString(rootFormData, paramsValues);

    let calcApi = null;
    if (propApiExpr && paramsValues) {
        const apiResp = apiJnx.eval(paramsValues)
        calcApi = apiResp;
    }

    return loadOptionsData(calcApi ?? propApi, apiResponseJnx, paramsValues, paramsString, fetchOptions, entity, method, sortBy);
}

function computeApiUrl(api, paramsValues, paramsString) {
    let module = null;
    let listFn = null;

    let m = /^(api|entity):(.+)$/.exec(api);
    if (m) {
        if (m[1] === 'entity') {
            listFn = (x) => x.items;
        }
        module = "api";
        api = m[1] === 'entity' ? `uwe-entities/${m[2]}/list` : m[2];
    } else {
        m = /^https?:\/\/(.+)$/.exec(api);
        if (m) {
            module = 'external';
        } else {
            module = 'lookup';
        }
    }

    const pathValues = [];
    api = interpolate(api, (name) => {
        pathValues.push(name);
        return getObject(paramsValues, name);
    });
    pathValues.forEach(name => {
        paramsString = paramsString.replace(new RegExp(`${name}=([^&]*&|[^&]*$)`, "g"), "");
    });
    const delimiter = api.indexOf('?') > -1 ? '&' : '?';
    const url = `${api}${paramsString ? `${delimiter}${paramsString}` : ''}`;

    return [module, url, listFn];
}

function computeParamString(rootFormData, params) {
    return rootFormData && params ? Object.entries(params).map(([k, v]) => {
        if (v && v.path) {
            return null;
        }
        return v ? `${k}=${encodeURIComponent(v)}` : null;
    }).filter(s => !!s).join("&") : ""
}

function computeParamValues(rootFormData, params, path) {
    return rootFormData && params ? Object.entries(params).reduce((_, [k, v]) => {
        if (v && v.field) {
            v = getObject(rootFormData, v.field);
        } else if (v && v.expr) {
            v = (new Jnx(v)).eval(rootFormData, path);
        }
        _[k] = v;
        return _;
    }, {}) : {};
}

async function loadOptionsData(propApi, apiResponseJnx, paramsValues, paramsString, fetchOptions, entity, method, sortBy) {
    const api = entity ? `entity:${entity}` : propApi;
    if (!api) return null;
    const [module, url, listFn] = computeApiUrl(api, paramsValues, paramsString);
    let data = await checkErrors(await fetchApi[module][method](url, fetchOptions));
    if (listFn) {
        data = listFn(data);
    }
    if (apiResponseJnx) {
        data = apiResponseJnx.eval(data, '', { data });
    }
    data = data ? (Array.isArray(data) ? data : [data]) : null;
    if (sortBy && data) {
        data.sort((a, b) => {
            let ak = a && a[sortBy];
            if (typeof ak === 'string') ak = ak.toLowerCase();
            let bk = b && b[sortBy];
            if (typeof bk === 'string') bk = bk.toLowerCase();
            return ak > bk ? 1 : (ak < bk ? -1 : 0);
        })
    }

    return data;
}

export default useOptionsLookup;