import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';

export const RoleApi = {
    async list({ offset, size, sortBy }) {
        return checkErrors(await fetchApi.api.get('manage/roles', { query: { offset, size, sortBy } }));
    },

    async get({ id }) {
        return (await checkErrors(await fetchApi.api.get(`manage/roles/${id}`))).data;
    },

    async getRoles() {
        return checkErrors(await fetchApi.api.get('manage/roles/all'));
    },

    async create(role) {
        return checkErrors(await fetchApi.api.post(`manage/roles`, { data: role }));
    },

    async update({ id, role }) {
        return checkErrors(await fetchApi.api.put(`manage/roles/${id}`, { data: role }));
    },
}

export default RoleApi;