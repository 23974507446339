import React, { useMemo, useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import AppContext from '../../context/AppContext';
import useTitle from '../../util/useTitle';
import InternalLayout from '../layouts/InternalLayout';
import DASHBOARD_COMPONENTS from './fields';


function Dashboard({ schema, scope }) {
    const {
        title, elements
    } = schema || {};

    useTitle(title);
    const history = useHistory();
    const grid_size = 4;

    const [dashboardData, _setDashboardData] = useState({});
    const setDashboardData = useCallback((name, value) => { _setDashboardData(dd => ({ ...dd, [name]: value })) }, [_setDashboardData])
    if (typeof scope !== "undefined") {
        scope.setdashboardData = setDashboardData;
        scope.dashboardData = dashboardData;
    }


    return elements?.length ? (<div className='dashboard'>{elements.reduce((_, element, idx) => {
        let { col = _.lastColEnd, colSpan = grid_size } = element;
        colSpan = Math.min(colSpan, grid_size);
        let colEnd = col + colSpan;
        if (colEnd > grid_size + 1) {
            col = 1;
            colEnd = col + colSpan;
        }

        _.lastColEnd = ((colEnd - 1) % grid_size) + 1;

        const Component = DASHBOARD_COMPONENTS[element.type];

        _.list.push(<div key={idx} className={`dashboard-widget from-col-${col} to-col-${colEnd}`}>{Component ? (
            <Component element={element} history={history} scope={scope} />
        ) : null}</div>);

        return _;
    }, { lastColEnd: 1, list: [] }).list}</div>) : null;
}

const WRAPPERS = {
    internal: (dashboard, dashboardDef) => (<InternalLayout>
        {dashboardDef.tab ? (
            <AppContext.SetSidebarTab value={dashboardDef.tab} />
        ) : null}
        {dashboard}
    </InternalLayout>)
};


Dashboard.HOC = function (dashboardDef) {
    const {
        name = "Dashboard",
        wrapperType,
        wrapper,
        schema
    } = dashboardDef;
    const schemaFn = ((typeof schema) === "function") ? schema : (() => schema);
    const wrapperFn = wrapper || WRAPPERS[wrapperType] || (children => children);

    const scope = {
        dashboard: dashboardDef
    };

    function HOC() {
        const schema = useMemo(schemaFn, [schemaFn]);
        return wrapperFn(
            (<Dashboard schema={schema} scope={scope} />),
            dashboardDef
        );
    }

    HOC.DisplayName = name || 'DashboardHOC';

    return HOC;
}

export default Dashboard;
