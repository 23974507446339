
class IdentityFormatter{
    static tryParse(format) {
        return new IdentityFormatter();
    }

    apply(value) {
        return value;
    }

    parse(value) {
        return value;
    }
}


export default IdentityFormatter;