import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router';
import LookupApi from '../../../api/LookupApi';
import OrganizationalUnitApi from "../../../api/OrganizationalUnitApi";
import FormComponent from '../../../components/FormComponent';
import InternalLayout from '../../../components/layouts/InternalLayout';
import parseUISchemaFromSchema from '../../../util/parseUISchemaFromSchema';

export const CreateOrganizationalUnitForm = FormComponent({
    beforeRenderHook({ setFormDefinition, initialFormObject }) {

        useEffect(() => {

            if (initialFormObject) {
                initialFormObject.name = "";

                const schema = {
                    ":classNames": "smallTwoRows",
                    title: "Crear Unidad Organizacional",
                    type: "object",
                    properties: {
                        name: { title: "Nombre", type: "string" },
                        type: {
                            "type": "string",
                            "title": "Tipo",
                            "enum": ["Agency", "Bureau", "WorkDivision"],
                            "enumNames": ["Agencia", "Entidad Peticionaria", "División de Trabajo"]
                        },
                        code: { title: "Código de Negociado", type: "string" },
                        address1: { title: "Dirección Linea 1", type: "string" },
                        address2: { title: "Dirección Linea 2", type: "string" },
                        postalCode: { title: "Código Postal", type: "string" },
                        parentId: {
                            "title": "Unidad Organizacional Padre",
                            "type": "number",
                            ":classNames": "grid-3-columns",
                            "ui:field": "LookupFormField",
                            "lookup": {
                                "resource": "Unidades Organizacionales",
                                "api": "organizationalunits",
                                "params": {
                                    "workable": 1
                                },
                                "parentId": "parentId",
                                "collapsable": true,
                                "collapseLevel": 2,
                                "id": "id",
                                "label": "name",
                                "sortBy": "name"
                            }
                        },
                        city: { title: "Ciudad", type: "string" },
                        state: { title: "Estado", type: "string" },
                        numericCode: { title: "Código de Agencia", type: "number" },
                        dependencyFigure: { title: "Cifra de Dependencia", type: "number" },
                        logoId: {
                            "type": ["number", "null"],
                            "ui:field": "ImageCrop",
                            "title": "Logo",
                            "ui:ratio": 4.42 / 1,
                            "ui:size": [775, 176]
                        },
                        previewCertificationFounds: {
                            "type": "string",
                            ":classNames": "h-margin",
                            "title": "Vista previa de Certificación de Fondos",
                            "ui:field": "Button",
                            "ui:showIf": {
                                "expr": "$isTruthy(logoId)"
                            },
                            "ui:onClick": {
                                "expr": [
                                    "(",
                                    "$logoId:= logoId;",
                                    "$openModal('PdfPreviewModal',true, 'GenerateCertificacionDeFondos', logoId)",
                                    ")"
                                ]
                            },
                        },
                        peopleSoftId: { title: "Business Unit (PeopleSoft)", type: "string", "ui:showIf": { expr: '$formContext.auth.user.user.accessProfile.name = "systemadmin" or $formContext.auth.user.user.accessProfile.name = "agencymanager"', "scope": ".." } },
                        workflows: {
                            title: "Requisition Workflows",
                            type: "array",
                            ":classNames": "from-col-1 to-col-3",
                            items: {
                                type: "object",
                                "ui:orderable": false,
                                properties: {
                                    workflowId: {
                                        title: "Workflow",
                                        type: "number",
                                        "ui:field": "LookupFormField",
                                        lookup: {
                                            api: 'workflows',
                                            resource: "Workflows",
                                            id: 'id',
                                            jnx: { "expr": "data[slug[$contains('requisitions')]]", "scope": ".." },
                                            label: 'name'
                                        }
                                    }
                                }
                            }
                        },
                        federalFundsProgram: {
                            title: "Programas de Fondos Federales",
                            type: "array",
                            ":classNames": "from-col-1 to-col-3",
                            "ui:showIf": "type = 'Agency'",
                            items: {
                                type: "object",
                                "ui:addable": true,
                                "ui:orderable": false,
                                "ui:removable": true,
                                properties: {
                                    name: {
                                        title: "Programa",
                                        type: "string"
                                    }
                                }
                            }
                        },
                        workflowParams: {
                            ":classNames": "from-col-1 to-col-3",
                            title: "Manejo de Módulos",
                            properties: initialFormObject.paramSchema
                        },
                    },
                    required: [
                        "name", "type", "code", "numericCode", "dependencyFigure",
                    ],
                };
                setFormDefinition(schema, parseUISchemaFromSchema(schema));
            }
        }, [initialFormObject]);
    },
    displayName: 'CreateOrganizationalUnit',
    withoutNavigation: true,
    schema: {},
    // uiSchema: {
    //     "ui:field": "HideObjectField",
    //     "peopleSoftId": {
    //         "classNames": "from-col-1 to-col-3",
    //         "ui:showIf": { expr: '$formContext.auth.user.user.accessProfile.name = "systemadmin" or $formContext.auth.user.user.accessProfile.name = "agencymanager"', "scope": "..", debug:"asd"},
    //     },
    // },
    validate({ name }, errors) {
        return errors;
    },
    parseProps: () => ({
        params: useParams()
    }),
    loadData: ({ params: { id } }) => LookupApi.getParamsSchemaWorkflow(-1),
    async onSubmit({ formData }, { props: { params: { id } } }) {
        await OrganizationalUnitApi.createOrganizationUnit(formData);
    },
    submitButtons: {
        submit: { text: "Crear Unidad Organizacional", className: "btn-primary" },
    },
    renderFormSubmitted: () => (
        <Redirect to="../system-settings/organizationalunits" />
    )
});

export default function () {
    return <InternalLayout><CreateOrganizationalUnitForm /></InternalLayout>;
};