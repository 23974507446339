import React, { useState, useEffect, useCallback, useRef } from "react";
import { FormGroup, Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import FileApi from "../../api/FileApi";
import Loader from "../Loader";



function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 100,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

function setCanvasImage(image, canvas, crop) {
    if (!canvas || !image || !crop) {
        return;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
    );
}


function ImageCrop(props) {
    const {
        formData,
        formContext,
        disabled,
        schema: {
            title,
            'ui:ratio': ratio,
            'ui:size': size,
        },
        idSchema: { $id },
        uiSchema: {
            'ui:readonly': readonly,
        },
        required,
        onChange: propOnChange
    } = props;

    const postArgs = ((formContext || {}).fileField || {}).postArgs;

    const [upImg, setUpImg] = useState();

    const imgRef = useRef(null);
    const fileRef = useRef(null);
    const previewCanvasRef = useRef(null);

    const [crop, setCrop] = useState({ unit: 'px', width: '100%' });

    const [completedCrop, setCompletedCrop] = useState(null);
    const [aspect, setAspect] = useState(ratio)
    const [rotate, setRotate] = useState(0)
    const [scale, setScale] = useState(1)
    const [uploadingFile, setUploadingFile] = useState();
    const [originalImage, setOriginalImage] = useState("");
    const [loadingOriginalImage, setLoadingOriginalImage] = useState(false);
    const [modal, setModal] = useState(false);

    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined)
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setUpImg(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
            setModal(true);
        }
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    useEffect(() => {
        if (!imgRef.current) return;
        setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop || crop, scale, rotate);
    }, [upImg, imgRef.current, completedCrop, crop, scale, rotate]);


    const cancelFile = () => {
        fileRef.current.value = '';
        setModal(false);
    };


    async function getCanvasBlob(canvas, crop) {

        if (!crop || !canvas) {
            return;
        }

        const [newWidth, newHeight] = size;

        const tmpCanvas = document.createElement('canvas');
        tmpCanvas.width = newWidth;
        tmpCanvas.height = newHeight;

        const ctx = tmpCanvas.getContext('2d');
        ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth * 0.90, newHeight * 0.90);

        const file1 = new Promise((resolve, reject) => {
            tmpCanvas.toBlob((blob) => {
                const fileName = 'logo.png';
                const file = new File([blob], fileName, { type: 'image/png' });
                resolve(file);
            })
        })

        const file = await file1;

        if (file) {
            const extension = file.name.indexOf('.') >= 0 ? `.${file.name.split('.').pop()}` : '';
            setUploadingFile(true);
            const result = await FileApi.uploadFile({ ...(postArgs || {}), file, filename: `${file.name}${extension}` });
            propOnChange(result.id);
        }

        setUploadingFile(false);
        setModal(false);
    }

    const clearImage = () => {
        setOriginalImage(null);
        propOnChange(null);
        if (fileRef?.current?.value) fileRef.current.value = '';
    }

    useEffect(() => {

        async function fetchData() {
            if (formData) {
                setLoadingOriginalImage(true);
                const file = await FileApi.getFile({ fileId: formData });
                if (file) {
                    setOriginalImage(file.url);
                    setLoadingOriginalImage(false);
                }
            }
        }

        fetchData();
    }, [formData])

    return (
        <>
            <Modal isOpen={modal}>
                <ModalBody>
                    <div>
                        {upImg ?
                            <ReactCrop
                                crop={crop}
                                onChange={(c) => setCrop(c)}
                                aspect={aspect}
                                onComplete={(c) => setCompletedCrop(c)}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={upImg}
                                    style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                            : null}
                    </div>
                    <label className="control-label mb-0">
                        Preview
                    </label>
                    <div>
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                width: Math.round(completedCrop?.width ?? 0),
                                height: Math.round(completedCrop?.height ?? 0),
                            }}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    {uploadingFile ? <Loader /> :
                        <>
                            <Button
                                type='button'
                                color="secondary"
                                onClick={() => {
                                    cancelFile();
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                type='button'
                                color="primary"
                                disabled={!completedCrop?.width || !completedCrop?.height}
                                onClick={() =>
                                    getCanvasBlob(previewCanvasRef.current, completedCrop)
                                }
                            >
                                Confirmar
                            </Button>
                        </>
                    }
                </ModalFooter>
            </Modal>
            <FormGroup disabled={readonly || disabled}>
                <label className="control-label" htmlFor="root_preferredBranchId">
                    {title}{required ? <span className="required">*</span> : null}
                </label>
                {loadingOriginalImage ? <Loader /> :
                    <>
                        <div>
                            {!formData ?
                                <Button disabled={disabled} className="file-input-label" tag="label" tabIndex="0">
                                    <input ref={fileRef} type='file' hidden accept='image/*' onChange={onSelectFile} />
                                    <i className="fa fa-upload" />
                                </Button> :
                                null}
                        </div>

                        <div style={{ position: 'relative', maxWidth: '20rem' }}>
                            {formData && originalImage ? <>
                                <Button style={{right:0}} className="btn-sm position-absolute" onClick={() => clearImage()} disabled={disabled} title="Clear">
                                    <i className="fa fa-trash fs-12" />
                                </Button>
                                <img className="img-fluid" style={{ border: '1px solid #ced4da' }} src={originalImage} alt={formData} />
                            </>
                                : null}
                        </div>
                    </>
                }
            </FormGroup>
        </>
    );
}


export default ImageCrop;