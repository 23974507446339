import { t } from "@lingui/macro";
import FormComponent from '../../../components/FormComponent';
import parseFormDefinition from '../../../util/parseFormDefinition';


export const RoleDetailsEditor = FormComponent({
    ...parseFormDefinition({
        displayName: 'RoleDetailsEditor',
        title: t`Role Details`,
        schemaProps: {
            "title": [t`Role`, " ${name}"].join(''),
            "ui:newSection": true,
            ":classNames": "grid-2-columns",
        },
        schema: {
            // type: "object",
            "name": {
                "title": t`Name`,
                "map:field": "name",
                "type": "string",
            },
            "displayName": {
                "title": t`Display Name`,
                "map:field": "displayName",
                "type": "string",
            },
            "searchUpwards": {
                "title": t`SearchUpwards`,
                "map:field": "searchUpwards",
                "type": "bool",
                "ui:field": "BoolYesNo"
            },
        },
    }),
    withoutNavigation: true,
    parseProps: ({ role, onChange }) => ({
        role,
        onRoleChange: onChange
    }),
    loadData: ({ role }) => role,
    hideDataLoader: true,
    onChange({
        formObject,
        parsedProps: {
            onRoleChange
        }
    }) {
        if (!formObject) return;
        onRoleChange(formObject);
    },
    onSubmit: ({ formData }) => Promise.resolve(),
    submitButtons: {}
});

export default RoleDetailsEditor;
