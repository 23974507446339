import React from "react";


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: undefined };
    }
  
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { error };
    }
  
    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }
  
    render() {
        const { error } = this.state;
        const { fallback, children } = this.props;

        if (error) {
            // You can render any custom fallback UI
            return fallback ? fallback(error) : (<span>Something went wrong.</span>);
        }
        return children; 
    }
}


export default ErrorBoundary;