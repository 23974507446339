import React, { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator } from '@fortawesome/free-solid-svg-icons'
import { faFileLines, faEnvelope } from '@fortawesome/free-regular-svg-icons'
import SelectionRectangle from "./SelectionRectangle";
// import SelectionCircle from "./SelectionCircle";
import SelectionDiamond from "./SelectionDiamond";
// import SelectionMessage from "./SelectionMessage";
import SelectionDocument from "./SelectionDocument";
// import SelectionHexagon from "./SelectionHexagon";
import SelectionRectangle2 from "./SelectionRectangle2";
import { wrapTextContent } from "../../../util/svgUtil";


function Shape({
    className,
    content,
    x, y,
    shape,
    selected,
    draggable=true,
    onDrag: propOnDrag,
    onDrop: propOnDrop,
    width = 10,
    height = 10,
    pad = 10,
    autoCalcSize = undefined,
    onSetSize = undefined,
    onClick = undefined
}) {
    const ref = useRef();
    draggable = draggable & selected;

    const [lines, setLines] = useState();
    const [_isDragging, setIsDragging] = useState();
    const [onDrag, onDrop] = useMemo(() => [
        (...args) => {
            if (!_isDragging) setIsDragging(true);
            return propOnDrag ? propOnDrag(...args) : null;
        },
        (...args) => {
            if (_isDragging) setIsDragging(false);
            return propOnDrop ? propOnDrop(...args) : null;
        }
    ], [_isDragging, propOnDrag, propOnDrop]);
    const isDragging = _isDragging && draggable;

    const [dx, dy] = useMemo(() => isDragging ? [-5, -5] : [0, 0], [isDragging]);

    useEffect(() => {
        if (!ref.current) return;
        const newLines = wrapTextContent(content, ref.current.style, {
            maxWidth: 200
        });
        if (!lines || lines.length !== newLines.length || lines.some((x, idx) => x !== newLines[idx])) {
            setLines(newLines);
        }
    }, [ref.current, content]);

    useEffect(() => {
        if (autoCalcSize === false || !onSetSize || !lines) return;
        const bbox = ref?.current?.getBBox();
        const {width, height} = bbox || {};
        const newWidth = (width | 0) + 2 * pad;
        const newHeight = (height | 0) + 2 * pad;
        if(bbox && ((width === undefined) || (height === undefined) || (newWidth !== width) || (newHeight !== height))) {
            onSetSize(newWidth, newHeight);
        }
    }, [autoCalcSize, ref.current, lines, width, height]);

    return (<>
        <g className={className}
            onClick={onClick}
            transform={`translate(${(x + dx) | 0}, ${(y + dy) | 0})`}
            filter={isDragging ? 'url(#dropShadow)' : undefined}
        >
            {(width && height) ? ShapeType({width, height, shape, pad}): null}
            {content ? (
                <g transform={`translate(${AdjustContent(shape, height, width, pad)})`}>
                    <text ref={ref} y=".9em" alignmentBaseline="top" >{
                        (lines || []).map((line, idx) => <tspan key={idx} x="0" dy={idx ? "1.2em" : ""}>{line}</tspan>)
                    }</text>
                </g>
            ) : null}
        </g>
        {selected ? SelectedShape({x: x + dx, y: y + dy, width, height, shape, draggable, onDrag, onDrop}): null}
    </>);
}
function AdjustContent(shape, height, width, pad){
    switch(shape){
        case "rect-form":
        case "rect-calc":
        case "rect-message":
        case "document":
            return String(pad).concat(", ").concat(pad)
        case "rect-bars":
            return String(pad).concat(", ").concat(pad)
        case "oval":
            return String(pad).concat(", ").concat(pad * .8)
        case "diamond":
            return String(pad*2).concat(", ").concat(pad)
        default:
            return String(pad).concat(", ").concat(pad)
    }
}
function ShapeType({width, height, shape, pad}){
    switch(shape){
        case "rect-form":
            return (<><rect className="shape" width={width} height={height} /><FontAwesomeIcon icon={faFileLines} width="30" height="30" x="-20" y="-20"/></>)
        case "rect-calc":
            return (<><rect className="shape" width={width} height={height} /><FontAwesomeIcon icon={faCalculator} width="30" height="30" x="-20" y="-20"/></>)
        case "rect-message":
            return (<><rect className="shape" width={width} height={height} /><FontAwesomeIcon icon={faEnvelope} width="30" height="30" x="-20" y="-20"/></>)
        case "diamond":
            return (<path d={`M 0 ${height/2}, L ${width/2 + pad} -${height}, L ${width + pad*2} ${height/2}, L ${width/2 + pad} ${height*2} Z`}  className="shape" />)
        case "rect-bars":
            return (<><rect className="squaredCorners" width={width} height={height} />
                    <rect className="squaredCorners" width={width/8} height={height} x={`-${width/8}`} />
                    <rect className="squaredCorners" width={width/8} height={height} x={`${width}`} /></>)
        case "document":
            return (<path d={`M 0 0 L ${width} 0 L ${width} ${height} Q ${width * 0.75} ${height * 0.6} ${width/2} ${height} Q ${width/4} ${height * 1.4} 0 ${height} Z`} className="shape"/>)
        case "oval":
            width *= .95;
            height *= 2;
            return (<><path d={`M ${width*.26},0 
                                C ${width*.11},0 0,${height*.11} 0,${height*.26} 
                                c 0,${height*.15} ${width*.11},${height*.26} ${width*.26},${height*.26} 
                                l ${width*.53},0 
                                c ${width*.15},0 ${width*.26},-${height*.11} ${width*.26},-${height*.26} 
                                C ${width*1.05},${height*.11} ${width*0.95},0 ${width*.8},0 
                                L ${width*.26},0 z`} className="shape" /></>)
        default:
            return (<><rect className="shape" width={width} height={height} /></>)
        
        // Shapes that could be useful in the future:
        // case "circle":
        //     return (<circle className="shape" cx={width/2} cy={height/2} r={width/2}/>)
        // case: "message":
        //     return (<path d={`M 0 0 L ${width} 0 L ${width} ${height} L ${width - width*0.4} ${height} L ${width - width*0.6} ${height + height*0.4} L ${width - width*0.6} ${height} L 0 ${height} Z`} className="shape"/>)
        // case "page":
        //     return (<path d={`M 10 0 L ${width+width*0.05} 0 L ${width+width*0.05} ${height} L ${width*0.9 - width} ${height} L ${width*0.9 - width} ${height - height*0.6} Z`} className="shape"/>)
        // case "hexagon":
            // return (<path d={`M 0 0 L ${width+width*0.05} 0 L ${width+width*0.25} ${height/2} L ${width+width*0.05} ${height} L 0 ${height} L ${width*0.75-width} ${height/2} Z`} className="shape"/>)

    }
}

function SelectedShape({x, y, width, height, shape, draggable, onDrag, onDrop}){
    switch(shape){
        case "rect-form":
        case "rect-calc":
            return (<SelectionRectangle
                x={x} y={y}
                width={width} height={height}
                draggable={draggable} onDrag={onDrag} onDrop={onDrop}
            />)
        case "rect-bars":
            return (<SelectionRectangle2
                x={x} y={y}
                width={width} height={height}
                draggable={draggable} onDrag={onDrag} onDrop={onDrop}
            />)
        case "diamond":
            return (<SelectionDiamond
                x={x} y={y}
                width={width} height={height}
                draggable={draggable} onDrag={onDrag} onDrop={onDrop}
            />)
        case "document":
            return (<SelectionDocument
                x={x} y={y}
                width={width} height={height}
                draggable={draggable} onDrag={onDrag} onDrop={onDrop}
            />)
        default:
            return (<SelectionRectangle
                x={x} y={y}
                width={width} height={height}
                draggable={draggable} onDrag={onDrag} onDrop={onDrop}
            />)
    }
}

export default Shape;