import React, { useMemo } from 'react';
import { useSideChannelSubscription } from '../util/useSideChannel';

function JointRequisitionSubmitButton({
    onClick: submitOnClick,
    className,
    sideChannel,
    cfSideChannel,
    children
}) {
    const nextClient = "Avanzar a Próximo Cliente Secundario";
    const formObject = useSideChannelSubscription(sideChannel, 1) || {};
    const {
        clientIdx: scClientIdx,
        setClientIdx,
        addNewClient,
        validateCurrentClient,
        validateAllClients,
        saveCurrentFormObject,
        canAddClients,
        currentFormData,
        navRef,
        onSubmitRef,
    } = useSideChannelSubscription(cfSideChannel) || {};
    const clientIdx = scClientIdx | 0;
    const clients = formObject.clients || [];
    const { isJointly } = formObject;
    const count = Math.max(clients.length || 0, isJointly ? 2 : 1);
    const isLastClient = clientIdx >= count - 1;

    const text = isLastClient ? children : nextClient;

    function onClick(e) {

        e.preventDefault();

        saveCurrentFormObject().then(() => {
            if (!isJointly || isLastClient) {
                console.log("JointRequisitionSubmitButton on click last client.");
                if (validateAllClients()) {
                    onSubmitRef.current({ formData: currentFormData.current, clientIdx });
                }
            } else {
                console.log("JointRequisitionSubmitButton on click NOT last client. go to ", clientIdx + 1);
                if (validateCurrentClient()) {
                    const nav = (navRef.current?.navigation?.list || [])[0];
                    if (nav && nav.elem) { nav.elem.scrollIntoView({ block: nav.block || 'start', behavior: 'smooth' }); }

                    if (clientIdx + 1 >= clients.length) {
                        addNewClient();
                    } else {
                        setClientIdx(clientIdx + 1);
                    }
                }
            }
        });
    }

    function onClickAddNewClient(e) {
        console.log("JointRequisitionSubmitButton on click NOT last client. go to ", clientIdx + 1);
        saveCurrentFormObject().then(() => {
            if (validateCurrentClient()) {
                const nav = (navRef.current?.navigation?.list || [])[0];
                if (nav && nav.elem) { nav.elem.scrollIntoView({ block: nav.block || 'start', behavior: 'smooth' }); }
                addNewClient();
            }
        });
        e.preventDefault();
    }

    return (<>
        <button
            type="submit"
            onClick={onClick}
            className={className}
        >{text}</button>
        {isJointly && isLastClient && canAddClients ? (
            <button
                type="submit"
                onClick={onClickAddNewClient}
                className={className}
            >Añadir Cliente Secundario</button>
        ) : null}
    </>);
}


export default JointRequisitionSubmitButton
