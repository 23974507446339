import React, { useMemo } from "react";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { useJnx } from "../../util/jnx";
import getPathFromId from "../../util/getPathFromId";
import SendEmail from "./SendEmail";
import FileField from "./FileField";
import Button from "./Button";
import RequisitionZipDownloadField from "./RequisitionZipDownloadField";
import BoolYesNoField from "./BoolYesNoField";
import IconCheckField from "./IconCheckField";
import CheckboxToggleButtonField from "./CheckboxToggleButtonField";
import HideObjectField from "./HideObjectField";
import RiskMatrixField from "./RiskMatrixField";
import RiskMatrixFieldBusiness from "./RiskMatrixFieldBusiness";
import SignatureField from "./SignatureField";
import CompositeInputField from "./CompositeInputField";
import ComputedField from "./ComputedField";
import LookupFormField from "./LookupFormField";
import LookupFormStaticField from "./LookupFormStaticField";
import LookupMultiSelectCheckboxesFormField from "./LookupMultiSelectCheckboxesFormField";
import TextInput from "./TextInput";
import PhoneInput from "./PhoneInput";
import TimerField from "./TimerField";
import MoneyInput from "./MoneyInput";
import CedulaInput from "./CedulaInput";
import StaticField from "./StaticField";
import ArrayField from "./ArrayField";
import Autocomplete from "./AutocompleteField";
import StaticTextBlurb from "./StaticTextBlurb";
import HtmlEditor from "./HtmlEditor";
import Notes from "./Notes";
import ImageCrop from "./ImageCrop";

const COMPONENTS = {
    SendEmail,
    FileField,
    Button,
    RequisitionZipDownloadField,
    RiskMatrixField,
    RiskMatrixFieldBusiness,
    ComputedField,
    SignatureField,
    CompositeInputField,
    HideObjectField,
    BoolYesNoField,
    BoolYesNo: BoolYesNoField,
    IconCheckField,
    CheckboxToggleButtonField,
    LookupFormField,
    LookupFormStaticField,
    LookupMultiSelectCheckboxesFormField,
    TextInput,
    PhoneInput,
    TimerField,
    MoneyInput,
    CedulaInput,
    StaticField,
    ArrayField,
    Autocomplete,
    StaticTextBlurb,
    HtmlEditor,
    Notes,
    ImageCrop
};

function DynamicUIComponent(props) {
    const {
        formContext,
        idSchema: { $id } = {},
        formContext: {
            bindings: fcBindings,
            setFormDataValues,
            sideChannel
        },
        uiSchema: {
            'ui:component': uiComponentExpr,
        },
    } = props;

    const path = useMemo(() => getPathFromId($id), [$id]);
    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}];

    const bindings = useMemo(() => ({
        ...fcBindings,
        rootFormData,
        formObject,
        formContext,
    }), [rootFormData, formObject, fcBindings, formContext]);

    const functionBinds = useMemo(() => ({ set: setFormDataValues }), [setFormDataValues]);
    const uiComponentJnx = useJnx(uiComponentExpr, { functionBinds });

    const Component = useMemo(() => {


        if (!uiComponentJnx) return;
        const value = uiComponentJnx.eval(rootFormData, path, bindings);

        return COMPONENTS[value];

    }, [rootFormData, $id, uiComponentExpr, uiComponentJnx]);

    if (!Component) return;

    return (
        <Component {...props} />
    );
}

export default DynamicUIComponent;