import React from 'react';
import Select from "react-select";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { TYPES } from "./util";



function JsonTypeSelector({ type: selectedType, onChangeType }) {
    return (<UncontrolledDropdown className='json-type-select'>
        <DropdownToggle caret>
            <i className={`icon icon-json-${selectedType || 'unknown'}`} />
        </DropdownToggle>
        <DropdownMenu>{TYPES.map(type => (
            <DropdownItem key={type} onClick={() => onChangeType(type)} className={`${type === selectedType ? 'active': ''}`}>
                <i className={`icon icon-json-${type}`}/> {type}
            </DropdownItem>
        ))}</DropdownMenu>
    </UncontrolledDropdown>);
}

function JsonTypeOption(type){
    return (<div>{type}</div>);
}


export default JsonTypeSelector;