
export function isOfType(value, type){
    switch(type){
        case 'array': return !!value && Array.isArray(value);
        case 'object': return !!value && !Array.isArray(value) && typeof(value) === 'object';
        /* eslint valid-typeof: 0 */
        default: return typeof(value) === type;
    }
}


/** Returns wether the given object matches the given pattern.
 * For an object to match the given pattern, it must match each of
 * the properties specified in pattern:
 *  - If the property is an array, then it must be of the same length
 *    and all its items must be equal.
 *  - If it is an object, the matching is recursive.
 *  - If the object is of the form {$type: 'some-type'}, then
 *    then item must be of the specified type (@see isOfType).
 *  - If the object is of the form {$value: 'some-type'}, then
 *    the pattern is taken as a literal an not interpreted in any way.
 * 
 * @param object obj - the object.
 * @param object pattern - the pattern.
 * 
 */
export function matchesPattern(obj, pattern) {
    if (!pattern) return true;
    return Object.entries(pattern).every(([prop, patVal]) => {
        const objVal = obj[prop];
        if (patVal) {
            if (patVal.$value) {
                patVal = patVal.$value;
            } else if (patVal.$type) {
                return isOfType(objVal, patVal.$type);
            } else if (patVal.$isEmpty !== undefined) {
                return !objVal === patVal.$isEmpty;
            } else if (patVal.$notEmpty !== undefined) {
                return !!objVal === patVal.$notEmpty;
            }
            
            if (Array.isArray(patVal)) {
                return Array.isArray(objVal) && objVal.length === patVal.length && objVal.every((x, idx) => x === patVal[idx]);
            } else if (typeof(patVal) === "object") {
                return matchesPattern(objVal, patVal);
            }
        }

        return objVal === patVal;
    })
}

// export function ensureMatches(obj, pattern) {
//     matchesPattern(obj, pattern) === false || matchesPattern(obj, pattern) === true;
//     matchesPattern(ensureMatches(obj, pattern), pattern) === true;
// }