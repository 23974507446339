

function Defs(){
    return (<defs>
        <marker id="arrowhead" markerWidth="10" markerHeight="7" 
        refX="8" refY="3.5" orient="auto">
          <polygon points="0 0, 10 3.5, 0 7" />
        </marker>
        <filter id="dropShadow" x="0" y="0" width="200%" height="200%">
          <feOffset result="offOut" in="SourceAlpha" dx="10" dy="10" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
    </defs>);
}


export default Defs;