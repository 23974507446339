import React, { useEffect, useRef, useState } from "react";


const GAP_SIZE = 30;

const GAP_PAD = 10;

function SwimmingLane({
    x, y, width, height,
    title,
    gap=GAP_SIZE,
}) {
    return (<g
        transform={`translate(${x | 0}, ${y | 0})`}
    >
        <rect width={`${gap}px`} height={`${height}px`}
            stroke="#000" fillOpacity={0}
        />
        <rect width={`${width - gap}px`} height={`${height}px`}
            stroke="#000" fillOpacity={0}
        />
        <g transform={`translate(${GAP_PAD}, ${(height / 2) | 0})`}>
            <text y=".9em" alignmentBaseline="top" textAnchor="middle" transform="rotate(-90)">{title}</text>
        </g>
    </g>);
}


export default SwimmingLane;