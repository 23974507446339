import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { FormGroup, Input, InputGroup, InputGroupText } from "reactstrap";
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";
import { useFormatter } from '../../util/applyFormatting';
import { useJnx } from '../../util/jnx';
import getPathFromId from '../../util/getPathFromId';
import { useSideChannelSubscription } from '../../util/useSideChannel';
import { PromiseDebouncer } from '../../util/PromiseDebouncer';

export default function TextInput(props) {
    const {
        id, required, disabled, readonly, autofocus,
        formData,
        formContext,
        formContext: {
            sideChannel
        },
        uiSchema: { 'ui:minLength': uiMinLength, 'ui:maxLength': uiMaxLength, 'ui:readonlyIf': uiReadonlyIfExpr, 'ui:staticText': staticText, 'ui:classNames': classNames, 'ui:prefix': prefix, 'ui:sufix': sufix, 'ui:placeHolder': placeholder, 'ui:formGroupClassName': formGroupClassName },
        schema: { onUpdate, minLength, maxLength }
    } = props;

    const { text, onChange, handleBlur } = useTextInputHooks(props);

    const rootFormData = useSideChannelSubscription(sideChannel, 0);
    const readonlyIfJnx = useJnx(uiReadonlyIfExpr);
    const readOnlyIf = useMemo(() => (
        readonlyIfJnx && readonlyIfJnx.eval(rootFormData || {}, '', {
            root: rootFormData,
            formContext,
        })
    ), [formData, rootFormData, formContext]);

    const uiReadonly = readOnlyIf || readonly;

    return (<FormGroup className={formGroupClassName || ''}>{staticText ? (
        <div className={classNames || ''}>{text}</div>
    ) : wrapInput(
        prefix,
        <Input
            disabled={disabled}
            type="text"
            placeholder={`${placeholder || ''}`}
            className={`custom ${classNames || ''}`}
            value={text}
            minLength={minLength || uiMinLength || ''}
            maxLength={maxLength || uiMaxLength || ''}
            required={required}
            onChange={onChange}
            onBlur={handleBlur}
            readOnly={uiReadonly}
            autoFocus={autofocus}
            id={id}
            data-cy={id}
        />,
        sufix
    )}</FormGroup>);
}

function wrapInput(prefix, input, sufix) {

    if (!prefix && !sufix)
        return input;

    return (
        <InputGroup>
            {prefix ? <InputGroupText>{prefix}</InputGroupText> : null}
            {input}
            {sufix ? <InputGroupText>{sufix}</InputGroupText> : null}
        </InputGroup>);
}


function useTextInputHooks({
    schema: { format, onBlurUpdate },
    value = '',
    onChange: onChangeForm,
    formContext,
    id: $id,
    idSchema: { $_id } = {},
}) {

    const {
        sideChannel,
        bindings: fcBindings,
        callSequencer,
        setFormDataValues,
    } = formContext;

    const id = $id || $_id || "";

    const path = useMemo(() => getPathFromId(id), [id]);
    const rfdRef = useRef();
    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}, {}];
    rfdRef.current = rootFormData;

    const [current, setCurrent] = useState();
    const formatter = useFormatter(format);

    const bindings = useMemo(() => ({
        ...fcBindings,
        formObject,
        formContext,
        id: $id
    }), [formObject, fcBindings, formContext]);

    const functionBinds = useMemo(() => ({
        set: setFormDataValues,
        log: (...args) => console.log("jsonata console log\n", ...args)
    }), [setFormDataValues]);
    const onBlurUpdateJnx = useJnx(onBlurUpdate, { functionBinds });

    const onChangeFormRef = useRef();
    onChangeFormRef.current = onChangeForm;

    const debouncedFormOnChange = useMemo(() => {
        const debouncedFOC = new PromiseDebouncer((value) => {
            onChangeFormRef.current(value)
        }, 450);

        return (value) => {
            const gotArmed = debouncedFOC.tickWith(value);
            return gotArmed ? callSequencer.push(() => debouncedFOC.promise) : null;
        };
    }, [onChangeFormRef, callSequencer]);

    const onChange = useCallback(({ target: { value: text } }) => {
        const value = formatter.parse(text);
        setCurrent({ value, text });
        debouncedFormOnChange(value)
    }, [formatter, debouncedFormOnChange, setCurrent]);

    useEffect(() => {
        const text = formatter.apply(value || "");
        setCurrent({ value, text });
    }, [value]);

    const handleBlur = useCallback(() => {
        if (onBlurUpdateJnx && current.value) {
            callSequencer.push(() => {
                return onBlurUpdateJnx.eval(rfdRef.current, path, { ...bindings, value: (current.value || value) })
            });
        }
    }, [onBlurUpdateJnx, current, rfdRef]);

    const response = { text: current?.text || "", onChange, handleBlur };
    return response;
}
