import React, { useState } from 'react';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';

const CollapsibleCard = ({ title, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Card className="collapsible-card">
      <CardHeader className="collapsible-card-header" onClick={toggleCollapse}>
        <h2>{title}</h2>
        <Button style={{ float: "right" }}>{isCollapsed ? '▼' : '▲'}</Button>
      </CardHeader>
      {!isCollapsed && <CardBody>
        <div className="collapsible-card-content">{children}</div>
      </CardBody>}
    </Card>
  );
};

export default CollapsibleCard;