import React, { useEffect, useMemo } from "react";
import { Button } from "reactstrap";
import getPathFromId from "../../../util/getPathFromId";

import ArrayObjectRow from "./ArrayObjectRow";
import ArrayPanelRow from "./ArrayPanelRow";


function MakeArrayItem({
    $id,
    props,
    onAddClick,
    layout,
    rowIdx,
    rowCount,
    rootFormData,
    formContext,
    filterRowJnx,
    readonlyRowIfJnx,
    itemsAreObjectType,
    objectItemsSchema,
    panels,
    itemsUiSchema,
    showColumnsIf,
    rowClassNames,
    rowClassNamesIf
}) {
    const btnStyle = { flex: '0 0 32px', paddingLeft: 6, paddingRight: 6, fontWeight: "bold" };
    const objectProps = { ...props?.children?.props };
    const { formData } = objectProps;
    const {
        "ui:orderable": orderable,
        "ui:removable": removable,
        "ui:removable-label": removableLabel,
        "ui:btn-removable-classNames": btnRemovableClassNames = '',
        "classNames": uiClassNames,
        "ui:btnStyle": uiBtnStyle = null
    } = itemsUiSchema || {};

    const showRow = useMemo(() => (
        !filterRowJnx ||
        filterRowJnx.eval(formData || {}, '', {
            root: rootFormData,
            formContext
        })
    ), [formData, rootFormData, formContext]);
    const isReadOnly = useMemo(() => (
        readonlyRowIfJnx && readonlyRowIfJnx.eval(formData || {}, '', {
            root: rootFormData,
            formContext,
        })
    ), [formData, rootFormData, formContext]);
    const errors = props?.children?.props?.errorSchema?.__errors;

    const hasMoveBtns = (orderable !== false) && (props.hasMoveUp || props.hasMoveDown) && !isReadOnly;
    const hasRemoveBtn = removable !== false && props.hasRemove && !isReadOnly;
    const hasToolbar = props.hasToolbar && (hasMoveBtns || hasRemoveBtn);

    let columnCount = Object.keys(objectItemsSchema.properties || {}).length;
    if (hasRemoveBtn) {
        columnCount += 1;
    }

    const perRowClassNames = useMemo(() => (rowClassNamesIf ? Object.entries(rowClassNamesIf).filter(
        ([_, conditional]) => conditional.eval(formData, '', {
            rootFormData,
            formContext,
        })
    ).map(
        ([className]) => className
    ).join(' ') : ''), [rowClassNamesIf, formData, rootFormData, formContext]);
    rowClassNames = `${rowClassNames || ''} ${perRowClassNames || ''} ${uiClassNames || ''}`;

    const rowId = useMemo(() => `${$id}_${rowIdx}`, [$id]);

    useEffect(() => {
        const visibility = formContext?.formFields?.current;
        const path = getPathFromId(rowId);

        if (visibility && path) {
            visibility[path] = true;

            return () => {
                visibility[path] = false;
            };
        }
    }, [rowId, formContext, rowIdx]);

    if (!showRow) {
        return null;
    }

    const mainRow = layout.wrapItem(
        rowId,
        props,
        onAddClick,
        rowIdx,
        rowCount,
        itemsAreObjectType ? (
            <ArrayObjectRow
                {...objectProps}
                isReadOnly={isReadOnly}
                schema={objectItemsSchema}
                rowIdx={rowIdx}
                key={rowIdx}
                showColumnsIf={showColumnsIf}
                layout={layout}
            />
        ) : (
            layout.makeSimpleItem(props)
        ),
        hasToolbar && (<>
            {hasMoveBtns && (<Button
                aria-label="Move up"
                className="array-item-move-up"
                data-cy={`array-item-move-up-${rowId}`}
                tabIndex="-1"
                style={btnStyle}
                disabled={props.disabled || props.readonly || !props.hasMoveUp}
                onClick={props.onReorderClick(props.index, props.index - 1)}
            ><i className="fa fa-arrow-up" /></Button>)}
            {hasMoveBtns && (<Button
                className="array-item-move-down"
                data-cy={`array-item-move-down-${rowId}`}
                aria-label="Move down"
                tabIndex="-1"
                style={btnStyle}
                disabled={
                    props.disabled || props.readonly || !props.hasMoveDown
                }
                onClick={props.onReorderClick(props.index, props.index + 1)}
            ><i className="fa fa-arrow-down" /></Button>)}
            {hasRemoveBtn && (<Button
                color="primary2"
                aria-label="Remove"
                className={`array-item-remove ${btnRemovableClassNames}`}
                data-cy={`array-item-remove-${rowId}`}
                tabIndex="-1"
                style={uiBtnStyle || btnStyle}
                disabled={props.disabled || props.readonly}
                onClick={props.onDropIndexClick(props.index)}
            >{removableLabel || 'X'}</Button>)}
        </>),
        rowClassNames
    );

    const panelRows = itemsAreObjectType ? panels.map(({ field, schema }, idx) => (<ArrayPanelRow
        key={idx}
        {...objectProps}
        layout={layout}
        isReadOnly={isReadOnly}
        rowClassNames={rowClassNames}
        field={field}
        schema={schema}
        columnCount={columnCount}
        showColumnsIf={showColumnsIf}
        rowIdx={rowIdx}
    />)) : [];

    // console.log('objectItemsSchema', objectItemsSchema);
    // console.log('panelRows', panelRows);

    return (<>
        {mainRow}
        {panelRows.length ? panelRows : null}
        {errors ? layout.wrapItemErrorList(<ErrorList errors={errors} />) : null}
    </>);
}


function ErrorList({ errors }) {
    return (<ul className="error-detail bs-callout bs-callout-info">{(errors || []).map((err, idx) => (
        <li key={idx} className="text-danger">{err.replace(/^:/, '')}</li>
    ))}</ul>);
}

export default MakeArrayItem;