import React, { useMemo } from "react";
import { t } from "@lingui/macro";
import Dashboard from "../../../components/Dashboard/index";

function ListWorkflows() {
    const schema = useMemo(() => ({
        "title": t`Workflows`,
        "type": "dashboard",
        "elements": [{
            "title": t`Workflows`,
            "type": "worktray",
            ":className": "col-lg-12",
            "resource": "manage/workflows",
            "tableActions": [{
                "label": t`Create Workflow`,
                "route": "?workflow=new",
            }],
            "filters": [
                { "title": t`Name`, "key": "name", "type": "string" },
                { "title": t`Slug`, "key": "slug", "type": "string" },
            ],
            "columns": [
                { "title": t`Name`, "sortkey": "name", "map:field": "name" },
                { "title": t`Slug`, "sortkey": "slug", "map:field": "slug" },
                { "title": t`Version`, "sortkey": "version", "map:field": "version" },
                { "title": t`Last Updated`, "sortkey": "lastUpdated", "map:field": "lastUpdated", "format": "date" },
                {
                    "title": t`Actions`, "actions": [{
                        "label": t`Edit`,
                        "route": "?workflow=$id",
                    },
                    {
                        "label": t`Duplicate`,
                        "type": "ConfirmButton",
                        "confirm": {
                            "text": "Está seguro que desea duplicar el workflow?",
                            "api": "manage/workflows/$id/duplicate",
                            "method": "get"
                        }
                    },
                    {
                        "label": t`Delete`,
                        "type": "ConfirmButton",
                        "confirm": {
                            "text": "Está seguro que desea eliminar el workflow?",
                            "api": "manage/workflows/$id",
                            "method": "delete",
                            "payload": {
                                "test1": 1,
                                "test2": "$id"
                            }
                        }
                    }]
                },
            ]
        }]
    }), []);

    return <Dashboard schema={schema} />;
}

export default ListWorkflows;