import React, { useState, useEffect } from 'react';
import AppContext from '../../context/AppContext';
import InternalLayout from '../../components/layouts/InternalLayout';
import useTitle from '../../util/useTitle';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export function CatalogPublic() {

    useTitle("Catálogo de Contratos");

    const { height } = useWindowDimensions();

    const SOURCE = "./catalog/public/public-catalog.html?cbResetParam=1";

    return <InternalLayout>
        <AppContext.Prop prop="sidebarTab" value="catalog" />
        <iframe
            style={{
                position: 'relative',
                overflow: 'hidden',
                height: `${height - 135}px`,
                width: '100%',
                border: 'none'
            }}
            title="catalog"
            // ref={ref}
            src={SOURCE}
            tabIndex='-1'
        />
    </InternalLayout>;
}

export default CatalogPublic;