import { Trans } from "@lingui/macro";
import React, { useState } from "react";
import { Button, Card, CardHeader } from "reactstrap";
import FileInput from "../../../components/FileInput";
import TenantApi from "../../../api/TenantApi";
import useLoader from "../../../util/useLoader";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";


function Export() {
    async function onExport() {
        const url = await TenantApi.getExportLink();
        window.open(url, "_blank");
    }

    const [importFile, setImportFile] = useState();
    const [importing, errorImporting , importFn] = useLoader();
    const [importNotice, setImportNotice] = useState();
    function onImport() {
        if (importFile) {
            importFn(async () => {
                setImportNotice();
                await TenantApi.importObjects({file: importFile});
                setImportFile();
                setImportNotice("Import sucessful.")
            });
        }
    }

    return <>
        <Card className="inbox-card primary">
            <CardHeader><Trans>Export</Trans></CardHeader>
            <div style={{padding:'12px'}}>
                <Button onClick={onExport} color="primary"><Trans>Export UWE Objects</Trans></Button>
            </div>
        </Card>
        <Card className="inbox-card primary">
            <CardHeader><Trans>Import</Trans></CardHeader>
            <div style={{padding:'12px'}}>
                <div>
                    File:
                    <FileInput file={importFile} onFileSelected={setImportFile}/>
                </div>
                <div>
                    {importing ? <Loader /> : (
                        <Button onClick={onImport} color="primary"><Trans>Import UWE Objects</Trans></Button>
                    )}
                    {importNotice ? <Notification color="success">{importNotice}</Notification> : null}
                    {errorImporting ? <Notification error={errorImporting} /> : null}
                </div>
            </div>
        </Card>
    </>;
}


export default Export;