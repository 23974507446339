import React from "react";
import useSearchParams from "../../../util/useSearchParams";
import EditForm from "./EditForm";
import ListForms from "./ListForms";


function Forms(){
    const [
        [form, setForm]
    ] = useSearchParams({
        form: undefined
    });

    return form ? (
        <EditForm formId={form} setForm={setForm} />
    ) : (
        <ListForms setForm={setForm} />
    );
}

export default Forms;