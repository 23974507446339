import IntegrationsApi from "../api/IntegrationsApi";
import RequisitionApi from "../api/RequisitionsApi";
import FormComponent from '../components/FormComponent';
import { FORM_UPDATE_SUBMIT_BUTTON } from '../constants';
import parseFormDefinition from '../util/parseFormDefinition';


const RequisitionManagement = FormComponent({
    ...parseFormDefinition({
        page: 'RequisitionManagement',
        title: "Manejo de Requisicion",
        schema: {
            "changestep": {
                "title": " ",
                "type": "object",
                "properties": {
                    "title": {
                        "title": "Paso de la Requisicion",
                        "type": "null",
                    },
                    "editform": {
                        "type": "object",
                        "title": " ",
                        ":classNames": "flex-h",
                        "properties": {
                            "step" : {
                                "title": " ",
                                "type": "string",
                                "ui:field": "LookupFormField",
                                "map:field": "status.0.step",
                                "lookup": {
                                    "resource": "Pasos",
                                    "api": "workflow-states",
                                    "jnx": "data[$isTruthy(id)]",
                                    "allowNoValue": false,
                                    "id": "name",
                                    "label": "title"
                                }
                            },
                            "btn": {
                                "title": "Cambiar",
                                "type": "string",
                                "ui:field": "Button",
                                "ui:loaderOnClick": true,
                                "ui:onClick": {"expr": ["(",
                                    "$request := {'requisitionId': $formObject.id, 'step': step};",
                                    "$result := $api.setRequisitionStep($request);",
                                    "$formContext.rrc.reload();",
                                ")"], "scope": "..", "debug": "btn onclick"}
                            }
                        }
                    }
                }
            },
        },
        options: {
            submitButtons: {}
        }
    }),
    withoutNavigation: true,
    parseProps: ({ requisition, auth }) => ({ requisition, auth }),
    loadData: ({ requisition }) => requisition,
    buildFormContext: () => ({
        bindings: {
            api: RequisitionApi,
        },
    }),
    onSubmit() { return Promise.resolve() },
});


export default RequisitionManagement;