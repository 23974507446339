/**
 * Represents a utility class for sequencing function calls asynchronously.
 */
class CallSequencer {
    /**
     * Creates an instance of CallSequencer.
     * @constructor
     */
    constructor() {
        /**
         * Represents the promise to be resolved.
         * @type {Promise}
         */
        this.promise = Promise.resolve();
    }

    /**
     * Pushes a function to be executed sequentially after the previously pushed functions.
     * @param {Function} thenFn - The function to be executed.
     */
    push(thenFn) {
        this.promise = this.promise.then(() => {
            return thenFn();
        });
    }

    /**
     * Wraps a function to be pushed into the sequence.
     * @param {Function} thenFn - The function to be wrapped and pushed.
     * @returns {Function} - A wrapped function that, when called, pushes the function call into the sequencer.
     */
    wrapFunction(thenFn) {
        return (...args) => this.push(() => thenFn(...args));
    }
}

export default CallSequencer;