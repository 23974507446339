import { useCallback, useRef } from "react";
import AppContext from "../../context/AppContext";

function NewReqPanel(){
    const ref = useRef();
    const getter = useCallback(() => ref.current, []);

    return (<>
        <AppContext.SetNewReqDomElement value={getter} />
        <div ref={ref} />
    </>);
}


export default NewReqPanel;