import React, { useContext, useEffect } from 'react';
import { useHistory } from "react-router";
import { AuthContext } from "../context/AuthContext";
import RequestReloadContext from '../context/RequestReloadContext';

const HandleRequisitionUpdate = ({
    formSubmitResult
}) => {
    const history = useHistory();
    // const auth = useContext(AuthContext);
    // const rrc = useContext(RequestReloadContext);
    // const { requisition: curRequisition, status } = formSubmitResult;
    // const statusParsed = JSON.parse(status);
    // const role = auth.user.user.role;
    // const roleRegEx = new RegExp(role.status);

    useEffect(() => {
        // if (roleRegEx.test(role.name) || role.name === 'administrator') {
        //     // Can continue the flow of the requisition
        //     if (rrc) {
        //         rrc.reload();
        //     } else {
        //         window.location.reload();
        //     }
        // } else {
        history.replace('/')
        // }
    }, []);

    return null;
}

export default HandleRequisitionUpdate;