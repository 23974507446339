import leftPad from "../leftPad";

class CurrencyFormatter{
    static tryParse(format) {
        const m = /^currency(-([^-]+)(-(\d+))?)?(-0)?$/.exec(format);
        return m ? new CurrencyFormatter(
            m[2],
            m[4] ? (m[4] | 0) : 6,
            !(m[5])
        ) : null;
    }

    constructor(symbol, decimals, trimRightZeros) {
        this.symbol = symbol;
        this.decimals = decimals;
        this.thousandSep = ',';
        this.decimalSep = '.';
        this.trimRightZeros = !!trimRightZeros;
    }

    apply(value) {
        value = +value;

        if (!Number.isFinite(value)) return value;

        const symbol = this.symbol;
        const decimals = this.decimals;

        const valInt = value | 0;
        const valFrac = (((value - valInt) * (10 ** decimals)) + 0.5) | 0;

        const dollars = reverseSplitInChunks(`${valInt}`, 3).join(this.thousandSep);

        let cents = leftPad(`${valFrac}`, decimals, '0').substring(0, decimals);
        if (this.trimRightZeros) cents = cents.replace(/0*$/, '');

        return `${symbol} ${dollars}${cents.length ? this.decimalSep : ''}${cents}`;
    }

    parse(value) {
        const m = /-?\d+(\.\d+)?/.exec(
            `${value}`.replaceAll(this.thousandSep, '').split(this.decimalSep).join('.')
        );

        return m ? +m[0]: 0;
    }

}


function reverseSplitInChunks(text, chunkLen){
    const N = text.length;
    const list = [];
    let to = N;
    let from = to - chunkLen;

    while(from > 0) {
        list.unshift(text.substring(from, to));
        to = from;
        from = to - chunkLen;
    }

    list.unshift(text.substring(0, to));

    return list
}


export default CurrencyFormatter;