import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';

import TabPanel from '../TabPanel';
import TABS from './tabs';
import AppContext from '../../context/AppContext';



function Sidebar() {
    const {
        sidebarTab,
        sidebarExpanded,
        setProp
    } = useContext(AppContext);
    const history = useHistory();

    const setSelected = useCallback((newSelected) => {
        const obj = TABS[newSelected];

        console.log("setSelected", newSelected, sidebarTab, newSelected === sidebarTab);

        if(obj && obj.path) {
            history.push(obj.path);
        }
    }, [sidebarTab, sidebarExpanded]);

    return (<div className="sidebar">
            <TabPanel
                selected={sidebarTab} setSelected={setSelected}
                tabs={TABS}
                showContent={false}
            />
        </div>
    );
}


export default Sidebar;