import InternalLayout from '../../components/layouts/InternalLayout';
import useTitle from "../../util/useTitle";
import useResourceLoader from "../../util/useResourceLoader";


export default function TicketPage(props) {
    useTitle("Ayuda");
    const [htmlPage, loading] = useResourceLoader(
      () => fetch("/zohoForm.html").then(r => r.text()),
      []
    );
    return (
        <InternalLayout>
            <div dangerouslySetInnerHTML={{ __html: htmlPage }} />
        </InternalLayout>
    );
}