import React, { useMemo } from "react";
import { FormGroup, Input } from "reactstrap";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { useJnx } from "../../util/jnx";

function CheckboxToggleButtonField(props) {
    const {
        formData,
        disabled,
        formContext,
        formContext: {
            sideChannel
        },
        idSchema: { $id },
        schema: {
            title,
            color, checkedColor
        },
        uiSchema,
        uiSchema: {
            'ui:readonly': uiReadonly,
            'ui:checkboxHidden': checkboxHidden,
            'ui:showIf': showIfExpr = true,
            'akc:requiredIfVisible': requiredIfVisible = false,
        },
        required,
        readonly: propReadOnly,
        onChange: propOnChange
    } = props;

    const readonly = uiReadonly || propReadOnly;

    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}];

    const showIfJnx = useJnx(showIfExpr);
    const showIf  = useMemo(() => (
        showIfJnx && showIfJnx.eval(rootFormData || {}, '', {
            root: rootFormData,
            formContext,
            formObject
        })
    ), [formData, rootFormData, formContext, formObject]);

    const currentColor = ((formData && checkedColor) ? checkedColor : color) || 'secondary';

    function onChange({ target: { value, checked } }) {
        console.log("onChange", value, checked);
        propOnChange(checked);
    }

    return (
        showIf ? 
        <FormGroup
            disabled={readonly || disabled}
            check
            className="checkbox-toggle-button-field"
        >
            <label
                className={`control-label checkbox btn btn-${currentColor} ${checkboxHidden ? 'checkbox-hidden' : ''} ${formData ? 'checked' : ''}`}
                htmlFor={$id}
                data-cy={$id}
            >
                <Input
                    id={$id}
                    type="checkbox"
                    disabled={readonly || disabled}
                    required={required}
                    checked={!!formData}
                    onChange={onChange}
                />
                {title} {required || requiredIfVisible ? '*' : ''}
            </label>
        </FormGroup> : null
    );
}

export default CheckboxToggleButtonField;