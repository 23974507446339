import { useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalFooter, Button, ModalHeader, FormGroup, Label, Input } from "reactstrap";
import { concatenatePaths, getObject } from "../../util/mapObject";
import { useSideChannelSubscription } from "../../util/useSideChannel";


const PARAMS = ["rejectReasonFieldPath", "contextPath"];


function RejectionReason({
    contextPath,
    rejectReasonFieldPath,
    formData: rootFormData,
    formContext,
    onConfirm: onClose,
    isOpen
}) {
    const {
        setFormDataValues,
    } = formContext;

    const fieldPath = useMemo(() => concatenatePaths(contextPath, rejectReasonFieldPath), [contextPath, rejectReasonFieldPath]);
    const description = useMemo(() => getObject(rootFormData, concatenatePaths(contextPath, "description")), [rootFormData, contextPath]);
    const defRejectReason = useMemo(() => getObject(rootFormData, fieldPath), [rootFormData, fieldPath]);

    const [rejectReason, setRejectReason] = useState(defRejectReason);

    function onChange({target: {value}}){
        setRejectReason(value);
    }

    function onAccept(e){
        e.stopPropagation();
        setFormDataValues({ [fieldPath]: rejectReason });
        onClose();
    }

    return (
        <Modal isOpen={isOpen}
            toggle={onClose}
            className="modal-primary"
            size='xl'
            backdrop
        >
            <ModalHeader
                toggle={onClose}
            >
                Razón de rechazo
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="modal_dd">Descripción</Label>
                    <Input type="static" name="text" id="modal_dd" value={description} readOnly />
                </FormGroup>
                <FormGroup>
                    <Label for="modal_rr">Razón de rechazo</Label>
                    <Input type="textarea" name="text" id="modal_rr" value={rejectReason || ""} onChange={onChange} />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={onAccept} color="primary">Aceptar</Button>
                <Button onClick={onClose} color="secondary">Cerrar</Button>
            </ModalFooter>
        </Modal>
    );
}


RejectionReason.PARAMS = PARAMS;


export default RejectionReason;