/**
 * Creates a deferred promise with separate resolve and reject functions.
 * @returns {Object} An object containing a promise and functions to resolve and reject it.
 */
function makeDefferedPromise(){
    const obj = {
        promise: null,
        resolve: null,
        reject: null,
    }

    obj.promise = new Promise((resolve, reject) => {
        obj.resolve = resolve;
        obj.reject = reject;
    });

    return obj;
}


export default makeDefferedPromise;