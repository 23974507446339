import React, { useState, useRef } from "react";
import {
    InputGroup,
    InputGroupText,
    Button
} from "reactstrap";
import { Document, Page } from 'react-pdf';
import useUID from "../util/useUID";


const ICONS = {
    pdf: "fa fa-file-pdf",
    doc: "fa fa-file-word",
    txt: "fa fa-file-lines",
    png: "fa fa-file-image",
    jpg: "fa fa-file-image",
    jpeg: "fa fa-file-image",
    default: "fa fa-file",
}


function FileInput({
    file,
    disabled,
    viewable = true,
    viewButtonText,
    uploadButtonText,
    onFileSelected,
    onDelete,
    resource = "File",
    accept = "*",
    readonly = false,
    required = false,
}) {
    const ref = useRef();
    const inputGroupRef = useRef(null);

    const fieldId = useUID('fileinput-');

    const [clearCt, setClearCt] = useState(0);
    const [dragTarget, setDragTarget] = useState();

    async function onChange({ target: { files: [selectedFile] } }) {
        if (disabled) return;
        if (selectedFile) {
            await onFileSelected(selectedFile);
            setClearCt(clearCt + 1);
        }
    }

    function onKeyPress(e) {
        if (disabled) return;
        if (e.which === 32 || e.which === 13) {
            e.stopPropagation();
            e.preventDefault();
            if (ref.current) ref.current.click();
        }
    }

    function onDrop(event) {
        if (disabled) return;
        setDragTarget(false);
        event.preventDefault();
        const { dataTransfer: { files: dtFiles, items: dtItems } } = event;
        const file = (dtItems ? Array.prototype.filter.call(dtItems, i => i.kind === 'file').map(i => i.getAsFile()) : dtFiles)[0];
        if (file) {
            onChange({ target: { files: [file] } });
        }
    }

    function onDragOver(event) {
        if (disabled) return;
        event.preventDefault();
        const { dataTransfer: { files: dtFiles, items: dtItems } } = event;
        const file = (dtItems ? Array.prototype.filter.call(dtItems, i => i.kind === 'file') : dtFiles)[0];
        setDragTarget(!!file);
    }

    function onDragLeave() {
        if (disabled) return;
        setDragTarget(false);
    }

    const extension = ((file && file.name) || '').split('.').pop();

    const [preview, setPreview] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [position, setPosition] = useState(null);

    const handleMouseOver = () => {
        if (file && file.url && file.name.endsWith('.pdf')) {
            const originRect = inputGroupRef.current.getBoundingClientRect();
            setPosition({ top: originRect.top + 8, left: originRect.left + 270 });
            setPreview(file.url);
        }
    };

    const handleMouseOut = () => {
        setPreview(null);
    };

    const onDocumentLoadSuccess = () => {
        const originRect = inputGroupRef.current.getBoundingClientRect();
        const availableSpace = window.innerHeight - originRect.bottom;
        const showBelow = availableSpace >= 400;
        setPosition({ top: (!showBelow ? originRect.top - 470 : originRect.top), left: originRect.left + 270 });
    };

    return (
        <InputGroup
            className={`file-input ${dragTarget ? 'border-success' : ''}`}
            onDrop={!readonly ? onDrop : null}
            onDragOver={!readonly ? onDragOver : null}
            onDragLeave={!readonly ? onDragLeave : null}
        >
            {file && file.name ? (
                <>
                    <div ref={inputGroupRef}>
                        <InputGroupText
                            style={{ cursor: 'pointer', minWidth: 150 }}
                            title={file.name}
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                        >
                            <span>
                                <i className={ICONS[extension] || ICONS.default} />
                                {' '}
                                {file.name}
                            </span>
                        </InputGroupText>
                    </div>
                    {preview && (
                        <div id="preview"
                            style={{
                                position: 'fixed',
                                background: 'white',
                                top: position?.top,
                                left: position?.left,
                                zIndex: 99999,
                                border: '1px solid'
                            }}>
                            <Document file={preview} onLoadSuccess={onDocumentLoadSuccess}>
                                <Page pageNumber={1} height={500} />
                            </Document>
                        </div>
                    )}
                </>
            ) : null}
            {viewable && file && file.url ? (
                <Button tag="a" href={file.url} target="_blank">{viewButtonText || (<i className="fa fa-search" />)}</Button>
            ) : null}
            {!readonly ? (
                dragTarget ? (
                    <Button color="success" className="file-input-label">Drop File</Button>
                ) : (
                    <Button disabled={disabled} className="file-input-label" tag="label" htmlFor={fieldId} tabIndex="0" onKeyPress={onKeyPress}>
                        <input key={clearCt}
                            id={fieldId}
                            data-cy={fieldId}
                            ref={ref}
                            type="file"
                            accept={accept} required={required && !(file && file.url)}
                            onChange={onChange}
                        />
                        {uploadButtonText || (<i className="fa fa-upload" />)}
                    </Button>
                )
            ) : null}
            {!readonly && file && file.url ?
                <Button disabled={disabled} className="file-input-label" tag="label" tabIndex="0" onClick={onDelete}>
                    {uploadButtonText || (<i className="fa fa-trash" />)}
                </Button>
                : null}
        </InputGroup>
    );
}


export default FileInput;