import React from "react";
import useSearchParams from "../../../util/useSearchParams";
import EditObjectSchema from "./EditObjectSchema";
import ListObjectSchema from "./ListObjectSchema";


function ObjectSchemas() {
    const [
        [objectSchema, setObjectSchema]
    ] = useSearchParams({
        objectSchema: undefined
    });

    return objectSchema ? (
        <EditObjectSchema objectSchemaId={objectSchema} setObjectSchema={setObjectSchema} />
    ) : (
        <ListObjectSchema setObjectSchema={setObjectSchema} />
    );
}

export default ObjectSchemas;