import React, { useState, useEffect, useContext, useMemo } from 'react';
import { FormGroup, Label, Row, Col, Input, Button, Card, CardHeader, CardBody, Form, Table } from "reactstrap";
import { t, Trans } from '@lingui/macro';

import Loader from "../Loader";

import LookupApi from "../../api/LookupApi";
import RequisitionsApi from "../../api/RequisitionsApi";
import { AuthContext } from "../../context/AuthContext";
import useResourceLoader from "../../util/useResourceLoader";
import useActionReducer from '../../util/useActionReducer';
import useLoader from '../../util/useLoader';
import CollapsableTreeSelect from '../CollapsableTreeSelect';


const adquisitionMethods = [
    "Contra Contrato",
    "Regular"
];

const RequisitionSearchBar = ({
    filters,
    onSearch,
    onClearSearch,
    statusState = 'active',
    excludeStage,
    excludeVisibility,
    onlyStage,
    showOptionsFilters
}) => {
    const [current, setCurrent] = useState(filters);
    const [resetCt, setResetCt] = useState(0);

    const auth = useContext(AuthContext);

    const worksWithEmergencies = auth?.user?.user?.organizationalUnit?.worksWithEmergencies;
    const accessProfile = auth?.user?.user?.accessProfile?.name;

    useEffect(() => setCurrent(filters), [filters]);

    const [[
        adquisitionMethods,
        agencies,
    ], loadingOptions, errorLoadingOptions] = useResourceLoader(() => Promise.all([
        LookupApi.getMetadataAttributeEnum("purchaseMethod"),
        LookupApi.getOrganizationalUnits({ "workable": "1" }),
    ]), [], [[], [], []]);

    const [statuses, loadingStatuses, errorLoadingStatuses] = useResourceLoader(async () => {
        const statuses = await LookupApi.getMetadataAttributeEnum("status");
        return statuses.filter(st => st.type === 'Fijo' && st.state === statusState);
    }, [statusState], []);

    const loading = loadingOptions || loadingStatuses;
    const error = errorLoadingOptions || errorLoadingStatuses;

    const handleChange = (e) => {
        const returnedValue = e.target.value
        const update = { [e.target.name]: returnedValue };
        setCurrent(Object.assign({}, current, update));
    }

    const handleCompleted = (e) => {
        const update = { 'completed': !current.completed };
        setCurrent(Object.assign({}, current, update));
    }

    const handleClosed = (e) => {
        const update = { 'closed': !current.closed };
        setCurrent(Object.assign({}, current, update));
    }

    const search = (e) => {
        e.preventDefault();
        console.log("current", current);
        onSearch(current);
    }

    function clearSearch(e) {
        e.preventDefault();
        setCurrent({});
        setResetCt(resetCt + 1);
        onClearSearch();
    }

    return (<Form onSubmit={search}>
        <Card className="search-bar square-top">
            <CardBody>
                <Row>
                    {showOptionsFilters?.number &&
                        <Col md={4}>
                            <FormGroup>
                                <Label for="requisition">Número de Requisición</Label>
                                <Input key={resetCt} value={current.requisition} onChange={handleChange} id="requisition" name="requisition" />
                            </FormGroup>
                        </Col>
                    }
                    {showOptionsFilters?.visibility &&
                        <Col md={4}>
                            <FormGroup>
                                <Label for="all">Visibilidad</Label>
                                <Input key={resetCt} value={current.all} onChange={handleChange} id="all" name="all" type="select">
                                    {!excludeVisibility?.pendingWork ? <option value="">Pendientes de trabajar</option> : null}
                                    {!excludeVisibility?.myRequisition ? <option value="1">Mis requisiciones</option> : null}
                                    {!excludeVisibility?.allRequisition ? <option value="2">Todas las requisiciones</option> : null}
                                </Input>
                            </FormGroup>
                        </Col>
                    }
                    <Col md={4}>
                        {(agencies || []).length > 1 &&
                            <FormGroup>
                                <Label for="agency">Agencia Peticionaria</Label>
                                <CollapsableTreeSelect
                                    name="agencies"
                                    emptyText="Todas"
                                    options={agencies}
                                    defaultCollapseLevel={1}
                                    value={current.agencies}
                                    parentId="parentId" id="id" label="name"
                                    multiple={Boolean(true)}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        }
                    </Col>
                    {showOptionsFilters?.adquisitionMethods &&
                        <Col md={4}>
                            <FormGroup>
                                <Label for="clienType">Método de Adquisición</Label>
                                <Input key={resetCt} value={current.method} onChange={handleChange} id="method" name="method" type="select">
                                    <option value="">Todos</option>
                                    {(adquisitionMethods || []).map((am, idx) => <option value={idx} key={idx}>{am}</option>)}
                                </Input>
                            </FormGroup>
                        </Col>
                    }
                    {showOptionsFilters?.after &&
                        <Col md={4}>
                            <FormGroup>
                                <Label for="after">Desde</Label>
                                <Input key={resetCt} value={current.after} onChange={handleChange} id="after" name="after" type="date" />
                            </FormGroup>
                        </Col>
                    }
                    {showOptionsFilters?.before &&
                        <Col md={4}>
                            <FormGroup>
                                <Label for="before">Hasta</Label>
                                <Input key={resetCt} value={current.before} onChange={handleChange} id="before" name="before" type="date" />
                            </FormGroup>
                        </Col>
                    }
                    {showOptionsFilters?.stage &&
                        <Col md={4}>
                            <FormGroup>
                                <Label for="stage">Estatus</Label>
                                {loadingStatuses ? <Loader /> : (
                                    <Input key={resetCt} value={current.stage} onChange={handleChange} id="status" name="status" type="select">
                                        <option value="">Todos</option>
                                        {(statuses || []).map((elem, idx) => <option value={elem.name} key={idx}>{elem.name}</option>)}
                                    </Input>
                                )}
                            </FormGroup>
                        </Col>
                    }
                    {showOptionsFilters?.recurringPurchase &&
                        <Col md={4}>
                            <FormGroup>
                                <Label for="recurringPurchase">Mostrar solo compras recurrentes</Label>
                                {loadingStatuses ? <Loader /> : (
                                    <Input key={resetCt} value={current.stage} onChange={handleChange} id="recurringPurchase" name="recurringPurchase" type="select">
                                        <option value="">No</option>
                                        <option value="True">Sí</option>
                                    </Input>
                                )}
                            </FormGroup>
                        </Col>
                    }
                    {showOptionsFilters?.priority && (worksWithEmergencies || accessProfile === "administrator") &&
                        <Col md={4}>
                            <FormGroup>
                                <Label for="priority">Nivel de Prioridad</Label>
                                {loadingStatuses ? <Loader /> : (
                                    <Input key={resetCt} value={current.stage} onChange={handleChange} id="priority" name="priority" type="select">
                                        <option value="">Todas</option>
                                        <option value="Normal">Normal</option>
                                        <option value="Emergencia">Emergencia</option>
                                    </Input>
                                )}
                            </FormGroup>
                        </Col>
                    }
                    {showOptionsFilters?.purchaseOrderNumbers &&
                        <Col md={4}>
                            <FormGroup>
                                <Label for="purchaseOrderNumbers">Número de Orden de Compra</Label>
                                <Input key={resetCt} value={current.purchaseOrderNumbers} onChange={handleChange} id="purchaseOrderNumbers" name="purchaseOrderNumbers" />
                            </FormGroup>
                        </Col>
                    }
                    {showOptionsFilters?.keyword &&
                        <Col md={4}>
                            <FormGroup>
                                <Label for="keyword">Palabra Clave</Label>
                                <Input key={resetCt} value={current.keyword} onChange={handleChange} id="keyword" name="keyword" />
                            </FormGroup>
                        </Col>
                    }
                </Row>
                <RoleFilterInput key={resetCt} value={current.roles} onChange={handleChange} name="roles" />
                <Row>
                    <Col>
                        <Button color="primary" type="submit">Filtrar</Button>
                        {" "}
                        <Button color="secondary" onClick={clearSearch}>Limpiar Filtro</Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </Form>);
}


const listFmt = {
    del1: '_', del2: '-',
    parse(text) {
        return text ? text.split(listFmt.del1).map(item => item.split(listFmt.del2).map(x => x | 0)) : [];
    },
    stringify(list) {
        return (list || []).map(x => x.join(listFmt.del2)).join(listFmt.del1);
    }
};


function RoleFilterInput({
    name, value, onChange
}) {
    const items = useMemo(() => listFmt.parse(value), [value]);

    const [roles, loadingRoles, errorLoadingRoles] = useResourceLoader(() => LookupApi.getRoles().then(
        roles => roles.reduce((_, r) => { _[r.id] = r; return _; }, {})
    ), []);
    const [loadingUsers, errorLoadingUsers, userLoaderFn] = useLoader();
    const [roleUsers, setRoleUsers] = useState({});

    function removeItemAt(idx) {
        const newItems = items.slice();
        newItems.splice(idx, 1);
        onChange({ target: { value: listFmt.stringify(newItems), name } });
    }

    function addNewItem() {
        onChange({ target: { value: listFmt.stringify([...items, [0, 0, 0]]), name } });
    }

    function setItemAt(idx, newItem) {
        const newItems = items.slice();
        newItems[idx] = newItem;
        onChange({ target: { value: listFmt.stringify(newItems), name } });
    }

    return (<>
        <Trans>Filter By User And Role</Trans>
        <Table size="sm">
            <tbody>{loadingRoles ? <Loader /> : (<>
                {items.map((item, idx) => (<RoleFilterInputRow
                    key={idx}
                    roles={roles}
                    item={item}
                    onChange={({ newItem }) => setItemAt(idx, newItem)}
                    onRemove={() => removeItemAt(idx)}
                />))}
                <tr><td colSpan="3"><Button onClick={() => addNewItem()} color="secondary">
                    <i className="fa fa-plus" title={t`Add role and user to filter.`} />
                </Button></td></tr>
            </>)}</tbody>
        </Table>
    </>);
}

function RoleFilterInputRow({
    item,
    roles,
    onChange,
    onRemove
}) {
    const [role, user] = item;
    const [users, loadingUsers] = useResourceLoader(async () => {
        if (!role) return {};
        return (await LookupApi.getRoleUsers({ roleId: role })).reduce((_, u) => { _[u?.userId ?? u.id] = u; return _; }, {});
    }, [role])

    return (<tr>
        <td width="45%"><Input type="select" value={role || ""} onChange={({ target: { value } }) => onChange({ newItem: [value | 0, 0] })}>
            <option value="">---{t`Role`}---</option>
            {Object.values(roles || {}).map(({ name, id }, idx) =>
                <option value={id} key={idx}>{name}</option>
            )}
        </Input></td>
        <td width="45%">{loadingUsers ? <Loader /> : (
            <Input type="select" value={user || ""} onChange={({ target: { value } }) => onChange({ newItem: [role, value | 0, (Object.values(users || {})?.find(x => x.userId === parseInt(value,10))?.isJedi ? 1 : 0 )]  })}>
                <option value="">---{t`User`}---</option>
                {Object.values(users || {}).map(({ name, id, isJedi, userId }, idx) =>
                    <option value={isJedi ? userId : id} key={idx}>{name}</option>
                )}
            </Input>
        )}</td>
        <td><Button onClick={onRemove} title={t`Remove role and user from filter.`} >X</Button></td>
    </tr>);
}


export default RequisitionSearchBar;