import React, { useCallback, useMemo, useState } from "react";
import { Button } from "reactstrap";
import JsonTreeEditorNode from "./JsonTreeEditorNode";


function JsonArrayEditor({indent, path, node, setObject}) {
    const prefix = useMemo(() => path ? `${path}.` : '', [path]);

    const onRemove = useCallback(({currentTarget: {attributes: {idx}}}) => {
        idx = idx.value | 0;
        setObject(path, node.filter((_, i) => i !== idx))
    }, [
        setObject, path, node
    ]);

    const onSwap = useCallback(({currentTarget: {attributes: {idx, idx2}}}) => {
        idx = idx.value | 0;
        idx2 = idx2.value | 0;
        const newNode = node.slice();
        newNode[idx] = node[idx2];
        newNode[idx2] = node[idx];
        setObject(path, newNode);
    }, [
        setObject, path, node
    ]);

    const onAddItem = useCallback(() => {
        setObject(path, [...node, null])
    }, [setObject, path, node]);

    return <div className="json-tree-editor-node-array">
        {node.map((subnode, idx) => (<React.Fragment key={idx}>
            <div className="index"><div className="number">{idx + 1}</div></div>
            <JsonTreeEditorNode
                indent={indent + 1}
                path={`${prefix}${idx}`}
                node={subnode}
                setObject={setObject}
                buttons={<div className="buttons">
                    <Button color="danger" idx={idx} onClick={onRemove}><i className="fa fa-minus" /></Button>
                    {idx > 0 ? (<Button idx={idx} idx2={idx - 1} onClick={onSwap}><i className="fa fa-chevron-up" /></Button>) : null}
                    {idx < node.length - 1 ? (<Button idx={idx} idx2={idx + 1} onClick={onSwap}><i className="fa fa-chevron-down" /></Button>): null}
                </div>}
            />
        </React.Fragment>))}
        <div className="lower-buttons">
            <Button color="primary" onClick={onAddItem}>+ Add</Button>
        </div>
    </div>;
}

JsonArrayEditor.isContainer = true;


export default JsonArrayEditor;