import React, { useCallback, useState } from "react";
import { Input } from "reactstrap";


function WorkflowJson({
    state,
    dispatchAction,
}) {
    const { steps } = state;

    const [inputValue, setInputValue] = useState(JSON.stringify(steps, null, 4));

    const onChange = useCallback(({ target: { value } }) => {

        setInputValue(value);

        try {
            const definition = JSON.parse(value);
            dispatchAction.setWorkflow({ ...state, definition });
        } catch (e) {
            // // pass
        }
    }, [dispatchAction]);
    return (
        <Input type="textarea" value={inputValue} onChange={onChange} rows={19} />
    );
}


export default WorkflowJson;