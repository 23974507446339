import _ from "lodash";
import dfsSearch from "./dfsSearch";
import { getObject } from "./mapObject";


function transformJsonSchema(schema) {
    const newschema = JSON.parse(JSON.stringify(schema));

    dfsSearch([['', newschema, null]], ([prop, current, parent]) => {
        if (current.$$include) {
            const includePath = current.$$include.split('#/')[1].replace(/\//g, '.');
            const ref = getObject(newschema, includePath);
            delete current.$$include;
            const clone = JSON.parse(JSON.stringify(current));
            _.merge(current, ref, clone);
        }

        const {
            required,
        } = current;

        if (required && parent && prop) {
            if (!parent.required) parent.required = [];
            parent.required.push(prop);
        }

        switch (current.type){
            case "object": 
                return Object.entries(current.properties).map(
                    ([prop, propSchema]) => [prop, propSchema, current]
                );
            case "array":
                return [['', current.items, current]];
        }
    });

    return newschema;
}

export default transformJsonSchema;