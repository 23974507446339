import React, {useMemo} from "react";
import {
    FormGroup,
    Table
} from "reactstrap";
import LookupApi from "../../api/LookupApi";
// import { clientTypeId } from "../../form_schemas/commons";
import { mapObject } from "../../util/mapObject";
import useResourceLoader from "../../util/useResourceLoader";
import { useSideChannelSubscription } from "../../util/useSideChannel";

function RiskMatrixField(props) {
    return null;
    // const {
    //     formContext: { sideChannel },
    //     idSchema: { $id },
    //     schema: { title },
    // } = props;

    // const [products] = useResourceLoader(async () => {
    //     const inst = await LookupApi.getInstitutions();
    //     const instprods = await Promise.all(inst.map(i => LookupApi.getProducts({institution: i.id})));
    //     return instprods.reduce((_, prods) => {
    //         prods.forEach(p => {_[p.id] = p;});
    //         return _;
    //     }, {});
    // }, [], {});

    // const formData = useSideChannelSubscription(sideChannel);

    // const mapped = useMemo(() => mapObject(formData || {}, {
    //     'tipoCliente': 'tipoCliente',
    //     'zonaGeograficaNacional': 'clientSchema.zonaGeograficaNacional',
    //     'pep': 'personaPoliticamenteExpuesta.personaExpuestaPoliticamente',
    //     'pepParentesco': 'personaPoliticamenteExpuesta.parentescoConPersonaExpuestaPoliticamente',
    //     'categoria': 'informacionIntencionVinculacion.resultadoPerfil.clienteProfesional',
    //     'ocupacion': 'clientSchema.ocupacion',
    //     'cargoIndependiente': 'datosLaboralesSchema.cargo',
    //     'paisOrigen': 'clientSchema.nacionalidad',
    //     'paisResidencia': 'clientSchema.pais',
    //     'productsBanco': 'informacionIntencionVinculacion.productosBanco',
    //     'productsPuesto': 'informacionIntencionVinculacion.productosPuesto',
    //     'productsSAFI': 'informacionIntencionVinculacion.productosSAFI',
    //     'duracionRelacion': 'duracionRelacion',
    //     'ingresosAnuales': 'capacidadFinanciera.ingresosAnuales',

    //     'cii_rdsAmtPuesto': 'informacionIntencionVinculacion.inversionesSchema.inversionEstimadaRD',
    //     'cii_usdAmtPuesto': 'informacionIntencionVinculacion.inversionesSchema.inversionEstimadaUSD',
    //     'cci_exRatePuesto': 'informacionIntencionVinculacion.inversionesSchema.tasaCambio',
        
    //     'cii_rdsAmtFinCert': 'informacionIntencionVinculacion.financialCertSchema.inversionEstimadaRD',
        
    //     'cii_usdAmtSAFI': 'informacionIntencionVinculacion.safiSchema.inversionEstimadaUSD',
    //     'cii_rdsAmtSAFI': 'informacionIntencionVinculacion.safiSchema.inversionEstimadaRD',
    //     'cci_exRateSAFI': 'informacionIntencionVinculacion.safiSchema.tasaCambio',
        
    //     'client_rcbi_savAccDepAmt': 'informacionIntencionVinculacion.savingsAccountSchema.depositoEstimadoRD',
    //     'cli_loanAmount': 'informacionIntencionVinculacion.prestamos.montoDeseadoPrestamo',
        
    //     'relacionCaraCara': 'resultadoPerfil.relacionCaraCara',
    // }), [formData]);

    // const tipoDeCliente = useMemo(() => (
    //     (clientTypeId.oneOf.find(ct => ct.const === mapped.tipoCliente) || {}).title
    // ), [mapped.tipoCliente]);
    // const categoria = useMemo(() => (
    //     mapped.categoria
    // ), [mapped.categoria]);
    // const actividadEconomica = useMemo(() => (
    //     mapped.ocupacion !== 'Independiente' ? mapped.ocupacion : mapped.cargoIndependiente
    // ), [mapped.ocupacion, mapped.cargoIndependiente]);
    // const pep = useMemo(() => (mapped.pep ? 'PEP': mapped.pepParentesco ? 'PEP': 'No PEP'), [mapped.pep, mapped.pepParentesco]);
    // const duracionDeLaRelacion = useMemo(() => (
    //     mapped.duracionRelacion
    // ), [mapped.duracionRelacion]);
    // const productoSolicitado = useMemo(() => {
    //     // (clientTypeId.oneOf.find(ct => ct.const === mapped.tipoCliente) || {}).title
    //     let result = []
    //     if (mapped.productsBanco){
    //         result = result.concat(mapped.productsBanco.map(pid => (products[pid] || {}).name))
    //     }
    //     if (mapped.productsPuesto){
    //         result = result.concat(mapped.productsPuesto.map(pid => (products[pid] || {}).name))
    //     }
    //     if (mapped.productsSAFI){
    //         result = result.concat(mapped.productsSAFI.map(pid => (products[pid] || {}).name))
    //     }
    //     return result.filter(p => !!p);
    // }, [mapped.productsBanco, mapped.productsPuesto, mapped.productsSAFI, products]);
    
    // const montoProyectado = useMemo(() => (
    //     (mapped.cii_usdAmtPuesto * mapped.cci_exRatePuesto)+
    //     (mapped.cii_usdAmtSAFI * mapped.cci_exRateSAFI)+ 
    //     mapped.cli_loanAmount + mapped.client_rcbi_savAccDepAmt +
    //     mapped.cii_rdsAmtPuesto + mapped.cii_rdsAmtSAFI + mapped.cii_rdsAmtFinCert
    // ), [mapped.cii_rdsAmtPuesto, mapped.cii_usdAmtPuesto, mapped.cci_exRatePuesto,
    //     mapped.cli_loanAmount, mapped.cii_rdsAmtFinCert, mapped.cii_usdAmtSAFI,
    //     mapped.cii_rdsAmtSAFI, mapped.cci_exRateSAFI, mapped.client_rcbi_savAccDepAmt]);

    // const nivelIngresos = useMemo(() => (
    //     mapped.ingresosAnuales
    // ), [mapped.ingresosAnuales]);
    // const {zonaGeograficaNacional} = mapped;
    // const paisDeOrigen = useMemo(() => (
    //     mapped.paisOrigen
    // ), [mapped.paisOrigen]);
    // const paisDeResidencia = useMemo(() => (
    //     mapped.paisResidencia
    // ), [mapped.paisResidencia]);
    // const canalDeDistribucion = useMemo(() => (
    //     mapped.relacionCaraCara ? 'Canal cara a cara':'Canal no cara a cara'
    // ), [mapped.relacionCaraCara]);

    
    // return (
    //     <FormGroup>
    //         <legend id={`${$id}__title`}>
    //             {title}
    //         </legend>
    //         <Table hover size="sm"><tbody>
    //             <tr><td>Tipo de Cliente</td><td>{tipoDeCliente}</td></tr>
    //             <tr><td>Categoria</td><td>{categoria}</td></tr>
    //             <tr><td>Actividad económica</td><td>{actividadEconomica}</td></tr>
    //             <tr><td>PEP</td><td>{pep}</td></tr>
    //             <tr><td>Duración de la relación</td><td>{duracionDeLaRelacion}</td></tr>
    //             <tr><td>Productos Solicitados</td><td>{productoSolicitado.join(", ")}</td></tr>
    //             <tr><td>Monto proyectado( inversion inicial/monto solicitado)</td><td>{Number.isFinite(montoProyectado) ? montoProyectado : 0}</td></tr>
    //             <tr><td>Nivel Ingresos</td><td>{nivelIngresos}</td></tr>
    //             <tr><td>Zona Geográfica Nacional</td><td>{zonaGeograficaNacional}</td></tr>
    //             <tr><td>País de Origen</td><td>{paisDeOrigen}</td></tr>
    //             <tr><td>País de Residencia</td><td>{paisDeResidencia}</td></tr>
    //             <tr><td>Canal de Distribución</td><td>{canalDeDistribucion}</td></tr>
    //         </tbody></Table>
    //     </FormGroup>
    // );
}


export default RiskMatrixField;