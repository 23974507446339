import React, { useContext, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import routes from '../routes';

const EXCLUDED_LOCATIONS = Object.entries(routes).filter(e => !!e[1].excludeFromProfileCheck).map(e => e[0]);

function AuthVerification() {

    const history = useHistory();
    const auth = useContext(AuthContext);
    const {pathname: location} = useLocation() || {};

    useEffect(() => {
        if (auth?.user) {
            if (!auth?.user?.user?.hasCompletedProfile) {
                if (location.pathname !== "/profile") {
                    if (EXCLUDED_LOCATIONS.some(l => location.startsWith(l))) {
                        return;
                    }
                    history.push('/profile');
                }
            }
        }
    });

    return null;
}


export default AuthVerification;