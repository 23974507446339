import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { useParams } from "react-router-dom";

import useResourceLoader from '../../util/useResourceLoader';
import RequisitionsApi, { RequisitionApi } from '../../api/RequisitionsApi';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';

import RequestReloadContext from '../../context/RequestReloadContext';

import { AuthContext } from '../../context/AuthContext';
import useSearchParams from '../../util/useSearchParams';
import FormApi from '../../api/FormApi';
import UWEFormComponent from '../../blc/UWEFormComponent';
import AppContext from '../../context/AppContext';
import InternalLayout from '../../components/layouts/InternalLayout';
import useAccessCheck from '../../util/useAccessCheck';
import useTitle from '../../util/useTitle';
import WorkflowApi from '../../api/WorkflowApi';


export function NewRequisition({
    history,
    location
}) {

    const { entityType: slug } = useParams();

    const auth = useContext(AuthContext);

    const isAsem = auth?.user?.user?.organizationalUnit?.workflowParams?.isAsem;

    const [
        [show, setShow],
        [requisitionType],
        [draftId],
        [sentFrom]
    ] = useSearchParams({
        show: 'form',
        type: 'regular',
        draft: undefined,
        from: undefined
    });

    useTitle("Requisición Nueva");

    if (slug === "requisitions") {
        useAccessCheck("Peticionario", "canWorkAs", "/");
    }

    const workflow = useMemo(() => {
        if (!location?.workflow) return null;
        return {
            id: location.workflow.workflowId,
            name: location.workflow.workflow.name,
            show: location.showWorkflow
        };
    }, [location?.workflow, slug]);

    const {
        cart,
        contractId,
        reqCopy,
        reqCopyNumber,
        category,
        subcategory,
        workflowId
    } = useMemo(() => {

        if (slug !== "requisitions") return {};

        if (!location?.state) return {};
        let subcategory = location?.state?.cart ? +JSON.parse(location.state.cart)[0].subcategoryId : null;
        if (!subcategory) {
            subcategory = location?.state?.cart ? +JSON.parse(location.state.cart)[0].subcategory1Id : null;
        }
        return {
            cart: location?.state?.cart ? JSON.parse(location.state.cart) : null,
            contractId: location.state.contractId || 0,
            reqCopy: location?.state?.reqCopy ? JSON.parse(location.state.reqCopy) : null,
            reqCopyNumber: location?.state?.reqCopyNumber,
            category: location?.state?.cart ? +JSON.parse(location.state.cart)[0].categoryId : null,
            subcategory,
            workflowId: location?.state?.workflowId,
        };
    }, [location?.state, slug]);

    const [currentRequisition, loadingCurrentRequisition, errorLoadingCurrentRequisition, setCurrentRequisition] = useResourceLoader(async () => {

        if (slug !== "requisitions") return { workflowId: workflow.id };

        if (reqCopy) {
            return ({ workflowId, data: reqCopy, reqCopyNumber });
        }

        if (draftId) {
            const objectDraft = await RequisitionApi.getRequisitionDraft({ draftId });
            return { workflowId: objectDraft?.data?.workflowId, ...objectDraft };
        }

        return {
            workflowId: workflow.id,
            type: requisitionType,
            data: {
                "checkedActiveContracts": cart?.length > 0 ? true : null,
                "items": (cart || []).map((item) => ({ ...item, panel3: { items: [{ usesOtherAccount: !isAsem }] } })),
                "category": category,
                "subcategory": subcategory,
                "isRFP": false
            },
            "contractId": contractId
        };
    },
        [slug, draftId, requisitionType, cart, contractId, reqCopy, isAsem]
    );


    const [reloadCount, setReloadCount] = useState(sentFrom === 'requisition-intake' ? 1 : 0);

    const [form, loading, errorLoading] = useResourceLoader(async () => {

        if (slug === "requisitions" && !currentRequisition) return;

        const workflowInfo = workflow?.id || ((draftId || reqCopy) && currentRequisition?.workflowId) ? await WorkflowApi.getWorkflowInfoForId({ id: workflow?.id || currentRequisition?.workflowId }) : await WorkflowApi.getWorkflowInfoForSlug({ slug });

        const initialStatus = workflowInfo.steps?.filter(
            st => !!st.page && st.isInitial
        )[0];

        const form = await FormApi.getByName({ name: initialStatus?.page });

        return form;
    }, [slug, currentRequisition]);

    useTitle((form && !loading) ? form.title : "Loading Form");

    const rrcState = RequestReloadContext.useHook(() => {
        // console.log('rrcState hook', reloadCount);
        setReloadCount(reloadCount + 1);
    }, [reloadCount]);

    const [showDebugControls, setShowDebugControls] = useState();

    return <InternalLayout>
        <AppContext.Prop prop="sidebarTab" value="newreq" />
        <AppContext.SetSidebarTab value="home" />
        {loading ? (
            <Loader fullscreen />
        ) : (errorLoading ? (
            <Notification error={errorLoading} />
        ) : (<div>
            <RequestReloadContext.Provider value={rrcState}>
                <button style={{
                    position: 'absolute',
                    top: 60,
                    right: 0,
                    opacity: '1%'
                }} onClick={() => setShowDebugControls(!showDebugControls)}>
                    {showDebugControls ? 'hide' : 'show'} debug
                </button>
                <div className="form-component requisition-form">
                    <div className="requisition-title">
                        {form?.title}
                        {currentRequisition?.reqCopyNumber ? ` (Copiada de ${currentRequisition?.reqCopyNumber})` : null}
                        {currentRequisition && workflow?.show && <span className="float-right workflow">Workflow: {workflow.name}</span>}
                    </div>
                    {showDebugControls ? (
                        <RequisitionEditComponent currentRequisition={currentRequisition} onUpdate={req => { setCurrentRequisition(req); setShowDebugControls(); }} />
                    ) : (
                        <UWEFormComponent currentRequisition={currentRequisition} form={form} slug={slug} />
                    )}
                </div>
            </RequestReloadContext.Provider>
        </div>))}
    </InternalLayout>;
}

function RequisitionEditComponent({ currentRequisition, onUpdate: propOnUpdate }) {
    const [json, setJson] = useState();
    const [error, setError] = useState();

    function stringifyReq(req) {
        return JSON.stringify(req, null, 4)
    }

    function tryParseReqJson(json) {
        try {
            return { data: JSON.parse(json)?.data || {} };
        } catch (e) {
            return null;
        }
    }

    function onChange({ target: { value } }) {
        setJson(value);

    }

    function onUpdate() {
        setError();
        const req = tryParseReqJson(json);
        if (req) {
            propOnUpdate(req);
        } else {
            setError("Invalid JSON");
        }
    }

    useEffect(() => {
        setJson(stringifyReq(currentRequisition));
    }, [currentRequisition]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            const req = tryParseReqJson(json);
            if (req) setJson(stringifyReq(req));
        }, 200);
        return () => clearTimeout(timeout);
    }, [json]);

    return (<div>
        {error ? <Notification error={error} /> : null}
        <Input type="textarea" style={{ height: '70vh' }} value={json} onChange={onChange} rows={5} />
        <Button className="btn-block" color="primary" onClick={onUpdate}>Update Requisition</Button>
    </div>);
}


export default NewRequisition;