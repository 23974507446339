import React, { useMemo } from "react";
import { FormGroup, Button } from "reactstrap";
import RequisitionsApi from "../../api/RequisitionsApi";
import fetchApi from "../../util/fetchApi";
import { interpolate } from "../../util/mapObject";
import useResourceLoader from "../../util/useResourceLoader";
import { useSideChannelSubscription } from "../../util/useSideChannel";


function RequisitionZipDownloadField(props) {
    const {
        formContext,
        schema: {
            title,
            filter
        },
        idSchema: { '$id': fieldId },
        uiSchema: {
            'ui:readonly': readonly
        },
    } = props;
    const { sideChannel } = formContext;

    const formObject = useSideChannelSubscription(sideChannel, 1) || [{}];

    const [url] = useResourceLoader(() => (formObject && formObject.id) ? RequisitionsApi.getRequisitionZipDownloadUrl({
        requisitionId: formObject.id, filter
    }) : null, [
        (formObject || {}).id, filter
    ]);

    return (
        <FormGroup className="file-field" disabled={readonly}><Button
            className="btn-block"
            href={url}
            target="_blank"
            data-cy={fieldId}
        >{title}</Button></FormGroup>
    );
}


export default RequisitionZipDownloadField;