import React, { useCallback, useContext, useMemo, useRef } from 'react';
import Context2D from '../Context';
import useDragEffect from '../useDragEffect';


function SelectionDocument({
    x=0,
    y=0,
    width=10,
    height=10,
    className='',
    onClick,
    draggable,
    onDrag: prop_onDrag,
    onDrop
}){
    const pad = 3;
    const { viewport2DataCoords } = useContext(Context2D);
    const coordinateMap = useCallback(({x, y}) => {
        const [mx, my] = viewport2DataCoords([x, y], {toInt: true});
        return {x: mx, y: my};
    }, [viewport2DataCoords]);
    const dragDelta = useRef();
    const [onDragStart, onDrag] = useMemo(() => prop_onDrag ? [
        (idx, sx, sy) => { dragDelta.current = { x: x - sx, y:  y - sy}; },
        (idx, x, y) => prop_onDrag(idx, x + (dragDelta.current?.x || 0), y + (dragDelta.current?.y || 0)),
    ] : null, [prop_onDrag]);
    const {
        dragState,
        onMouseDownDelegate,
        onClickDelegate,
    } = useDragEffect({
        onDrag,
        onDragStart,
        onDrop,
        onClick,
        coordinateMap
    });
    
    return <>
        <path
            className={`selection ${className} ${draggable ? 'draggable' : ''}  ${dragState ? 'is-dragging' : ''}`}
            onClick={onClickDelegate}
            onMouseDown={onMouseDownDelegate}
            d = {"M ".concat(x - pad).concat(" ").concat((y - pad))
                    .concat(" L ").concat(x + width + pad).concat(" ").concat(y - pad)
                    .concat(" L ").concat(x + width + pad).concat(" ").concat(y + height + pad*2)
                    .concat(" Q ").concat(x + width*0.75 + pad).concat(" ").concat(y + height*0.6 + pad).concat(" ").concat(x + width/2 + pad).concat(" ").concat(y + height + pad)
                    .concat(" Q ").concat(x + width/4 + pad).concat(" ").concat(y + height*1.4 + pad*1.5).concat(" ").concat(x - pad).concat(" ").concat(y + height + pad)
                    .concat(" Z")
                }
        />
    </>;
}
export default SelectionDocument;