import HomePanel from "./HomePanel";
import NewReqPanel from "./NewReqPanel";

const TABS = {
    home   : {icon: "fa fa-home"    , component: HomePanel, default: true, path: '/workflow/requisitions/tray' },
    drafts : {icon: "icon icon-draft", component: () => null, path: '/workflow/requisitions/draft', require: ['Peticionario', 'canWorkAs']},
    archive: {icon: "fa fa-archive" , component: () => null, path: '/workflow/requisitions/archived'},
    catalog   : {icon: "fa fa-shopping-cart"    , component: () => null, default: true, path: '/public-catalog' },
}

export const DEFAULT_TAB = Object.entries(TABS).reduce((_, [k, v]) => v.default ? k : _, null) || Object.keys(TABS)[0];

export default TABS;