import React from 'react';
import UserApi from "../../api/UserApi";
import FormComponent from '../FormComponent';
import useSearchParams from '../../util/useSearchParams';
import { bindEvent } from '../../util/useEventHandler';
import SetBodyClassName from '../SetBodyClassName';
import { setObject } from '../../util/mapObject';
import OrganizationalUnitApi from "../../api/OrganizationalUnitApi";


export const FormDetailsEditor = FormComponent({
    displayName: 'FormDetailsEditor',
    withoutNavigation: true,
    schema: {
        ":formClassName": "sidebar-panel",
        type: "object",
        properties: {
            name: {
                'map:field': 'name',
                title: "Name",
                type: "string"
            },
            objectSchemaId: {
                'map:field': 'objectSchemaId',
                "type": "number",
                "title": "Entity Type",
                "ui:field": "LookupFormField",
                "lookup": {
                    "resource": "Entity Types",
                    "api": "api:manage/objectschemas",
                    "options": { useCache: true },
                    "jnx": "items",
                    "id": "id",
                    "label": "name"
                }
            },
            title: {
                'map:field': 'title',
                type: "string",
                title: "Title"
            },
            options: {
                title: " ",
                type: "object",
                properties: {
                    alignButtons: {
                        type: 'string',
                        'map:field': 'options.alignButtons',
                        enum: [
                            'left',
                            'right'
                        ],
                        title: "Align Submit Buttons"
                    },
                    submitButtons: {
                        title: "Submit Buttons",
                        ":classNames": "fieldset",
                        type: "object",
                        'map:field': 'options.submitButtons',
                        properties: {},
                        additionalProperties: {
                            type: "object",
                            title: " ",
                            ":classNames": "separator",
                            properties: {
                                "text": { title: "Text", type: "string" },
                                "order": { title: "Order", type: "number" },
                                "className": { title: "Class Name", type: "string" },
                                "ui:showIf": { title: "showIf", type: "string" },
                                "setProps": {
                                    title: "Set Props", type: "object",
                                    "ui:asPropertiesTable": true,
                                    properties: {},
                                    additionalProperties: {
                                        type: "string",
                                        title: "value"
                                    }
                                },
                                "confirm": { type: "array", items: { type: "string" } }
                            }
                        }
                    }
                }
            },
            sidebarComponents: {
                title: "Sidebar Components",
                type: "array",
                'map:field': 'sidebarComponents',
                items: {
                    "type": "object",
                    "properties": {},
                    "ui:field": "JsonField"
                }
            },
            // sidebarComponents: {
            //     title: " ",
            //     description: "sidebarComponents",
            //     ":classNames": "from-col-1 to-col-3",
            //     type: "array",
            //     items: {
            //         type: "object",
            //         "ui:addable": false,
            //         "ui:orderable": false,
            //         "ui:removable": false,
            //         properties: {
            //             title: {
            //                 "title": "Component",
            //                 "ui:readonly": true,
            //                 "ui:cellClassName": "text-center align-middle",
            //                 "type": "string"
            //             },
            //             position: {
            //                 "title": "Position",
            //                 "ui:cellClassName": "text-center align-middle",
            //                 "type": "string",
            //                 "enum": [
            //                     "right",
            //                     "left",
            //                 ]
            //             },
            //             active: {
            //                 "title": "Active",
            //                 "ui:cellClassName": "text-center align-middle",
            //                 "type": "boolean"
            //             },
            //         }
            //     }
            // },
            // "sendBack": {},
            // "options": {
            //     "hasSaveProgressButton": true,
            //     "submitButtons": {}
            // },
            // "uiSchema": {},
            // "permissionsMap": {}
            "objectMap": {
                "type": "array",
                "title": "Object Map",
                'map:array': 'objectMap',
                "ui:field": "JsonField",
                "items": {}
            }
        },
        required: []
    },
    uiSchema: {},
    parseProps: ({ form, onChange }) => ({
        formDetails: form,
        onFormDetailsChange: onChange
    }),
    loadData: ({ formDetails }) => formDetails,
    onChange({
        formData,
        parsedProps: { onFormDetailsChange }
    }) {
        // console.log({ formData, onFormDetailsChange});
        onFormDetailsChange(formData);
    },
    async onSubmit({ formData }) {
        console.log("onSubmit :: ", formData);
    },
    submitButtons: {}
});

export default FormDetailsEditor;
