import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';

export const OrganizationalUnitApi = {
  
    async searchOrganizationUnit({ offset, size, sortBy, ...filters }) {
        return checkErrors(await fetchApi.api.get('manage/organizationalunit', {
            query: { offset, size, sortBy, ...filters }
        }));
    },

    async getOrganizationUnit({ id }) {
        return checkErrors(await fetchApi.api.get(`manage/organizationalunit/${id}`));
    },

    async getOrganizationUnitById(id) {
        return checkErrors(fetchApi.api.get(`manage/organizationalunit/${id}`));
    },

    async getOrganizationUnitStub({ id }) {
        return (await checkErrors(await fetchApi.lookup.get(`organizationalunit/${id}`))).data;
    },

    async editgetOrganizationUnit({ id, ...data }) {
        return checkErrors(await fetchApi.api.post(`manage/organizationalunit/${id}`, { data }));
    },

    async createOrganizationUnit(data) {
        return checkErrors(await fetchApi.api.post('manage/organizationalunit/', { data }));
    },

}

export default OrganizationalUnitApi;
