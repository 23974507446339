import { useMemo } from "react";
import Jnx from "../../../util/jnx";


function useCompiledPerRowConditionals(conditionalsObject){
    const [
        overallConditionals,
        perRowConditionals,
    ] = useMemo(() => Object.entries(conditionalsObject || {}).reduce((_, [k, v]) => {
        const expression = new Jnx(v);
        const {absolute, ancestor} = expression.scope;
        let isPerRow = true;
        if (absolute || ancestor > 1) {
            if (ancestor) expression.scope.ancestor -= 1;
            isPerRow = false;
        }

        _[isPerRow ? 1 : 0][k] = expression;

        return _;
    }, [{}, {}]), [conditionalsObject]);

    return [
        overallConditionals,
        perRowConditionals,
    ];
}


export default useCompiledPerRowConditionals;