import React, { useState, useMemo } from 'react';
import {
    Button,
    Card,
} from 'reactstrap';
import dayjs from 'dayjs';

import useResourceLoader from '../util/useResourceLoader';
import RequisitionsApi from '../api/RequisitionsApi';
import { DATETIME_FORMAT } from '../constants';
import Notification from '../components/Notification';
import { toDrDateTime } from "../util/dates";
import Loader from '../components/Loader';


function RequisitionNote({ requisition, readonly, totalExpanded }) {
    const requisitionId = requisition?.id;

    const [newNote, setNewNote] = useState('');
    const [notes, loadingNotes, error, setNotes] = useResourceLoader(
        () => RequisitionsApi.getRequisitionNotes({ requisitionId }),
        [requisitionId],
        []
    );

    const externalNotes = requisition?.externalData?.notes;

    const mergedNotes = useMemo(() => [
        ...(notes || []).map(({createdAt, message, user}) => ({
            date: dayjs.utc(createdAt).local(),
            createdAt, message, user
        })),
        ...(externalNotes || []).map(({
            createdDate: createdAt,
            notes: message,
            user: {fullName: name}
        }) => ({
            date: dayjs(createdAt),
            createdAt, message, user: {name}
        }))
    ].sort(
        (b, a) => a.date.diff(b.date)
    ), [notes, externalNotes]);

    async function submitNewNote() {
        if (newNote) {
            const addedNote = await RequisitionsApi.createRequisitionNote({ requisitionId, note: newNote });
            setNotes(notes => [addedNote, ...notes]);
            setNewNote('');
        }
    };

    return loadingNotes ? <Loader /> : (
        <>
            {error ? <Notification color="danger">{JSON.stringify(error)}</Notification> : null}
            {!totalExpanded ?
                <RequisitionNoteSidePanelComponent
                    readonly={readonly}
                    newNote={newNote} setNewNote={setNewNote} submitNewNote={submitNewNote}
                    notes={mergedNotes}
                />
                :
                <RequisitionNoteExpandedComponent
                    readonly={readonly}
                    newNote={newNote} setNewNote={setNewNote} submitNewNote={submitNewNote}
                    notes={mergedNotes}
                />
            }
        </>
    );
}


function RequisitionNoteSidePanelComponent({
    readonly,
    newNote, setNewNote, submitNewNote,
    notes,
}){
    return (<div className="requisition-note-sidepanel-component">
        {!readonly ? (<Card className="requisition-note-add">
            <textarea row="3" data-cy="text-note" value={newNote} onChange={({target:{value}}) => setNewNote(value)} />
            <Button color="primary" data-cy="button-add-note" onClick={submitNewNote}>Añadir Nota Nueva</Button>
        </Card>) : null}
        <div className="requisition-notes-list">
            {!notes.length ? (
                <Notification color="warning">No hay notas para esta requisición</Notification>
            ) : null}
            {notes.map(({ createdAt, message, user }, idx) => (
                <div className="requisition-note-container" key={idx}>
                    <div className="requisition-note-header">
                        <span className="requisition-note-date-time">{toDrDateTime(createdAt).format(DATETIME_FORMAT)}</span>
                        <span className="requisition-note-user">{user?.name || ''}</span>
                    </div>
                    <div className="requisition-note-text">{message}</div>
                </div>
            ))}
        </div>
    </div>)
}


function RequisitionNoteExpandedComponent({
    readonly,
    newNote, setNewNote, submitNewNote,
    notes,
}){
    return (<div className="requisition-form-full-expanded-sidepanel requisition-notes-expanded">
        {!readonly ? <>
            <div className="row requisition-form-notes">
                <div className="col-lg-10">
                    <input className="form-control" placeholder="Ingrese nota aquí" type="text" value={newNote} onChange={setNewNote} />
                </div>
                <div className="col-lg-2">
                    <Button color="primary" onClick={submitNewNote}>Añadir nota</Button>
                </div>
            </div>
        </> : null}
        {!notes.length ? (
            <Notification color="warning">No hay notas para esta requisición</Notification>
        ) : null}
        <ul className="striped-list">
            {notes.map(({ createdAt, message, user }, idx) => (
                <li key={idx}>
                    <div className='row'>
                        <div className='col-lg-2'><span className="requisition-note-date-time">{toDrDateTime(createdAt).format(DATETIME_FORMAT)}</span></div>
                        <div className='col-lg-3'><span className="requisition-note-user">{user?.name || ''}</span></div>
                        <div className='col-lg-7'><span className="requisition-note-text">{message}</span></div>
                    </div>
                </li>
            ))}
        </ul>
    </div>);
}

export default RequisitionNote;
