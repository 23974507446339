import React, { useMemo } from "react";
import parseFormDefinition from "../util/parseFormDefinition";
import RequisitionFormComponent from "./RequisitionFormComponent";


function UWEFormComponent({
    slug,
    form,
    readonly,
    currentStatus,
    currentRequisition,
    renderFormSubmitted,
    overrideOnSubmit,
    ...props
}){
    const formArgs = useMemo(() => parseFormDefinition(form), [form])
    const FormComponent = useMemo(() => formArgs && RequisitionFormComponent({
        ...formArgs,
        renderFormSubmitted,
        overrideOnSubmit
    }), [formArgs, overrideOnSubmit]);

    return FormComponent ? (
        <FormComponent currentRequisition={currentRequisition} currentStatus={currentStatus} readonly={readonly} slug={slug} {...props} />
    ) : null
}


export default UWEFormComponent;