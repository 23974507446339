

function Switch({className, round, value, title, onChange}) {
    return (<label
        className={`switch ${className || ''} ${round ? 'round' : ''} ${onChange ? '' : 'readonly'}`}
        title={title}
    >
        <input type="checkbox" checked={!!value} value={!!value} readOnly={!onChange} onChange={onChange} />
        <span className="slider" />
    </label>)
}


export default Switch;