import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { FormGroup } from "reactstrap";
import ReactQuill from 'react-quill';
import Quill from 'quill';
import 'react-quill/dist/quill.snow.css';
import ResizeModule from 'smart-quill-resize-module'
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";



export default function HtmlEditor({
    id,
    schema: {
        title,
        description
    },
    uiSchema,
    formData: value,
    onChange: onChangeForm,
}) {
    const [current, setCurrent] = useState(value);
    useDebouncedEffect(() => {
        if (current !== value) {
            onChangeForm(current)
        }
    }, [current], 450);
    useEffect(() => setCurrent(value), [value]);

    if (Quill) {
        Quill.register("modules/resize", ResizeModule);
    }

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' }
            ],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            matchVisual: false
        },
        resize: {
            locale: {
              floatLeft: "Left",
              floatRight: "Right",
              restore: "Restore",
            },
          },
    };

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
        'width',
        'height',
        'style',
        'src',
        'allowfullscreen',
        'iframe'
    ];

    return (
        <FormGroup>
            {title && <label className="control-label" htmlFor="root_preferredBranchId">{title}</label>}
            {description && <div className="field-description">{description}</div>}
            <div className="parent-scroll">
                <div id="scrolling-container">
                    <ReactQuill
                        modules={modules}
                        formats={formats}
                        value={current || ""}
                        //   style={{ minHeight: '150px', marginBottom: 60 }}
                        onChange={(e) => setCurrent(e)}
                        placeholder="Enter the text with HTML format"
                        id={id}
                        bounds="#scrolling-container"
                        scrollingContainer=".parent-scroll"
                    />
                </div>
            </div>
        </FormGroup>
    );
}