import React, { useMemo, useContext } from 'react';
import { Switch, Redirect, Route } from 'react-router';
import { AuthContext } from './context/AuthContext';
import AppContext from './context/AppContext';
import routes from './routes';
import AuthVerification from './components/AuthVerification';


function App() {
  const auth = useContext(AuthContext);

  const allowedRoutes = useMemo(() => Object.entries(routes).filter(
    entry => checkRequirements(auth, entry[1].requires)
  ), [auth]);

  const defaultRoute = useMemo(() => (
    allowedRoutes.filter(entry => entry[1].default).sort(
      (a, b) => b[1].default - a[1].default
    )[0]
  ), [allowedRoutes]);

  return (
    <AppContext.Component>
      <AuthVerification /><Switch>
        {allowedRoutes.map(([path, { requires, ...route }], idx) => (
          <Route key={idx} path={path} {...route} />
        ))}
        {defaultRoute ? (
          <Redirect to={defaultRoute[0]} />
        ) : null}
      </Switch>
    </AppContext.Component>
  );
}


function checkRequirements({ user }, requires) {
  if (requires === 'login') {
    return !!user;
  }

  return true;
}


export default App;