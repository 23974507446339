import React, { useState, useContext, useMemo, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, Button, ModalFooter, Input, Row, Nav, NavItem, NavLink, TabContent, TabPane, Col } from 'reactstrap';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import JsPDF from 'jspdf';
import NotificationApi from '../api/NotificationApi';
import { AuthContext } from '../context/AuthContext';
import useResourceLoader from '../util/useResourceLoader';
import FileApi from '../api/FileApi';
import Loader from './Loader';

function PopupNotification(props) {

    const { isPreview = false, prevNotification = null, setPreviewNotif } = props;
    const auth = useContext(AuthContext);

    const [notifications, loadingNotifications] = useResourceLoader(() => (auth?.user && !isPreview ? (
        NotificationApi.check()
    ) : null), [auth, auth?.user]);

    const [isOpen, setIsOpen] = useState(true);
    const [page, setPage] = useState(0);

    const [checked, setChecked] = useState(false);
    const [checkedError, setCheckedError] = useState(null);

    const [currentNotification, setCurrentNotification] = useState(0);

    const notification = useMemo(() => {
        if (isPreview && prevNotification) { return prevNotification }
        if (notifications) { return notifications[currentNotification] }
        return null;
    }, [notifications, currentNotification, prevNotification, isPreview]);

    const goToNextPage = () => {
        if (page < notification?.pages.length - 1) {
            setPage(page + 1);
        }
    };

    const goToPreviousPage = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    const nextNotification = () => {
        const valueForValidate = currentNotification + 1;
        if (notifications?.[valueForValidate]) {
            setCurrentNotification(prev => prev + 1);
            setPage(0);
        } else {
            setIsOpen(false);
        }
    }

    async function submitNotification(notification) {
        if (notification) {
            if (notification.requiresConfirmation && !checked) {
                setCheckedError("Debe confirmar la notificación");
            } else {
                setCheckedError(null);
                setChecked(false);
                nextNotification();
                await NotificationApi.confirm(notification.id);
            }
        }
    };

    const onClosePreview = () => {
        setPreviewNotif(null);
        setCurrentNotification(0);
        setPage(0);
        setChecked(false);
    }


    const [activeTab, setActiveTab] = useState('notification');

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const [downloading, setDownloading] = useState(false);

    return (
        ((notifications && !loadingNotifications && notification) || (isPreview && prevNotification)) &&
        <Modal isOpen={isOpen}
            size='xl'
            backdrop='static'>
            <ModalHeader className='modal-header-notification'>
                <div className="col-lg-12">
                    <div className='float-left notifications-title'>
                        {notification?.title}
                    </div>
                    <div className='float-right text-right'>
                        Pág. {page + 1}/{notification?.pages?.length}
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className='ql-viewer' style={{ minHeight: 460, maxHeight: 460, overflowX: 'auto' }}>
                {downloading ? <Loader fullscreen /> : null}
                {notification?.uploadedFiles?.length > 0 ?
                    <>
                        <Nav tabs className='notification-tabs'>
                            <NavItem>
                                <NavLink
                                    style={{ border: activeTab === 'files' ? 'none' : '', fontWeight: activeTab === 'notification' ? 'bold' : '' }}
                                    onClick={() => { toggleTab('notification'); }}
                                >
                                    Notificación
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ border: activeTab === 'notification' ? 'none' : '', fontWeight: activeTab === 'files' ? 'bold' : '' }}
                                    onClick={() => { toggleTab('files'); }}
                                >
                                    Archivos
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="notification">
                                <Row>
                                    <Col sm="12">
                                        <div dangerouslySetInnerHTML={{ __html: notification?.pages?.[page]?.content }} />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="files">
                                <Row>
                                    <Col sm="12">
                                        {notification?.uploadedFiles?.map((item, key) => {
                                            return <FileDownload item={item} index={key} />
                                        })}
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </> :
                    <Row >
                        <Col sm="12">
                            <div dangerouslySetInnerHTML={{ __html: notification?.pages?.[page]?.content }} />
                        </Col>
                    </Row>
                }
            </ModalBody>
            {notification?.requiresConfirmation && ((notification?.pages?.length > 1 && page === notification?.pages?.length - 1) || notification?.pages?.length === 1) &&
                <ModalFooter className='justify-content-between'>
                    <div className="col-lg-12 mb-0">
                        <Input
                            id={`${notification.id}`}
                            type="checkbox"
                            checked={checked}
                            onChange={({ target: { checked } }) => setChecked(checked)}
                        />
                        <span className='font-weight-bold'>{notification?.requiresConfirmationText}</span>
                        {checkedError ? <div className="text-danger">{checkedError}</div> : null}
                    </div>
                </ModalFooter>}
            {activeTab !== "files" &&
                <ModalFooter>
                    <div className="col-lg-12">
                        {page > 0 && <Button className="float-left mr-3" onClick={goToPreviousPage} variant="primary">Página Anterior</Button>}
                        {notification?.pages.length - 1 !== page && <Button className="float-left" onClick={goToNextPage} variant="primary">Página Siguiente</Button>}
                        {!isPreview && ((notification?.pages?.length > 1 && page === notification?.pages?.length - 1) || notification?.pages?.length === 1) && <Button className="float-right" onClick={() => submitNotification(notification)} color="primary">Confirmar</Button>}
                        {isPreview && <Button className="float-right" onClick={() => onClosePreview()} color="primary">Cerrar</Button>}
                        <Button className="float-right mr-2" onClick={() => handleDownloadZip({ files: notification?.uploadedFiles, notification, setDownloading })} color="secondary">Descargar</Button>
                    </div>
                </ModalFooter>}
        </Modal>
    );
}

const FileDownload = ({ item, index }) => {

    const handleDownload = (fileUrl) => {
        const a = document.createElement('a');
        a.href = fileUrl;
        a.target = '_blank';
        a.click();
    }

    return (
        <span
            key={index}
            className={`file-link ${index === 0 ? 'pt-2' : ''}`}
            tabIndex={index}
            role="link"
            onClick={async () => {
                const file = await FileApi.getFile({ fileId: item.id });
                if (file) {
                    handleDownload(file.url, item.name);
                }
            }}
        >
            {item.name}
        </span>
    );
};

const generatePDFDocument = async (notification) => {

    let content = `<h4 style="margin-bottom:20px">${notification?.title}</h4>`;

    let response = null;

    let i = 0
    for (i; i < notification?.pages?.length; i += 1) {
        content += notification?.pages?.[i]?.content;
    }

    if (!content) return null;

    const tempDiv = document.createElement('div');
    tempDiv.style.paddingRight = '20mm';
    tempDiv.style.paddingBottom = '20mm';
    tempDiv.style.width = '210mm';
    tempDiv.innerHTML = content;

    const images = tempDiv.getElementsByTagName('img');

    const iframes = tempDiv.getElementsByTagName('iframe');

    const maxWidth = '190mm';

    for (let i = 0; i < images.length; i += 1) {
        const img = images[i];
        const naturalWidth = img.naturalWidth;
        const naturalHeight = img.naturalHeight;

        const aspectRatio = naturalWidth / naturalHeight;

        const newWidth = Math.min(naturalWidth, parseFloat(maxWidth));
        const newHeight = newWidth / aspectRatio;

        img.style.maxWidth = `${newWidth}mm`;
        img.style.maxHeight = `${newHeight}mm`;
    }

    Array.from(images)?.forEach(img => {
      const originalSrc = img.src;

      if (originalSrc.startsWith("data:image")) return;
      
      const proxiedSrc = `https://corsproxy.io/?${originalSrc}`;
      
      img.src = proxiedSrc;
      img.setAttribute('crossorigin', 'anonymous');
    });

    tempDiv.innerHTML = tempDiv.innerHTML;

    for (let i = 0; i < iframes.length; i += 1) {
        const iframe = iframes[i];
        const src = iframe.getAttribute('src');

        if (src) {
            const link = document.createElement('a');
            link.setAttribute('href', src);
            link.textContent = `Video (${src})`;
            iframe.parentNode.replaceChild(link, iframe);
        }
    }

    document.body.appendChild(tempDiv);

    const canvas = await html2canvas(tempDiv, {
        scale: 1,
        useCORS: true
    });

    if (canvas) {

        tempDiv.remove();

        const contentWidth = canvas.width;
        const contentHeight = canvas.height;

        const pdfPageSize = {
            width: 595,
            height: 1050
        };

        const totalPages = Math.ceil(contentHeight / pdfPageSize.height);

        const pdf = new JsPDF();

        for (let page = 0; page < totalPages; page += 1) {
            const newCanvas = document.createElement('canvas');
            newCanvas.width = contentWidth;
            newCanvas.height = pdfPageSize.height;

            const ctx = newCanvas.getContext('2d');
            const startY = page * pdfPageSize.height * -1;
            ctx.drawImage(canvas, 0, startY);

            if (page > 0) {
                pdf.addPage();
            }

            pdf.addImage(newCanvas.toDataURL(), 'PNG', 10, 10);
        }

        response = {
            blob: pdf.output('blob'),
            name: `Notification-${notification?.title}.pdf`
        };
    }

    return response;
};

const handleDownloadZip = async ({ files, notification, setDownloading }) => {

    setDownloading(true);

    const makePdf = await generatePDFDocument(notification);

    if (files?.length > 0) {

        const zip = new JSZip();

        if (makePdf) {
            zip.file(makePdf.name, makePdf.blob);
        }

        const promises = files?.map(async (item, key) => {

            const file = await FileApi.getFile({ fileId: item.id });

            if (file) {
                const dFile = await fetch(file.url);
                const blob = await dFile.blob();
                if (blob) {
                    zip.file(item.name, blob);
                };
            }
        })

        await Promise.all(promises);

        const zipBlob = await zip.generateAsync({ type: 'blob' });

        const zipLink = document.createElement('a');
        zipLink.href = URL.createObjectURL(zipBlob);
        zipLink.download = 'files.zip';
        zipLink.click();
    } else {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(makePdf.blob);
        downloadLink.download = makePdf.name;
        downloadLink.click();
    }

    setDownloading(false);
};


export default PopupNotification;