import { useCallback, useEffect, useState } from "react";


function JsonNumberEditor({path, node, setObject}) {
    const [text, _setText] = useState();
    const [isValid, setValid] = useState(true);

    const setText = useCallback((text) => {
        text = text.replace(/[a-zA-Z]/g, '');
        const value = +text;
        const isValid = Number.isFinite(value);

        _setText(text);
        setValid(isValid);
        if (isValid) { setObject(path, value); }
    }, [node]);

    const reset = useCallback(() => {
        _setText(`${node}`);
        setValid(true);
    }, [node])
    
    useEffect(() => { reset(); }, []);
    useEffect(() => {
        if (!isValid) {
            const timeout = setTimeout(() => reset(), 5000);
            return () => clearTimeout(timeout);
        }
    }, [isValid, text, node]);

    return (<span className="number-editor">
        <input 
            type="text"
            value={text || ""}
            onChange={({target: {value}}) => setText(value)}
        />
        {!isValid ? (
            <span className="marker">!</span>
        ) : null}
    </span>)
}


export default JsonNumberEditor;